import { useEffect, useRef, useState } from 'react';

interface AccordionContentProps {
  children: React.ReactNode;
  open: boolean;
}

export const AccordionContent = ({ open, children }: AccordionContentProps) => {
  const accordionElement = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (accordionElement.current) {
      setContentHeight(open ? accordionElement.current.scrollHeight : 0);
    }
  }, [open, children]);
  return (
    <div
      ref={accordionElement}
      className="overflow-hidden bg-day-light-5 transition-h duration-300 ease-out px-2"
      style={{
        height: open ? `${contentHeight}px` : '0px',
      }}
    >
      {open && <div className="px-4 py-2">{children}</div>}
    </div>
  );
};
