import { z } from 'zod';
export var ServiceId;
(function (ServiceId) {
    ServiceId[ServiceId["TENDER_DOCUMENTS"] = 1] = "TENDER_DOCUMENTS";
    ServiceId[ServiceId["OFFER"] = 2] = "OFFER";
    ServiceId[ServiceId["TENANT_SPECIFICATION"] = 3] = "TENANT_SPECIFICATION";
    ServiceId[ServiceId["DUE_DILIGENCE"] = 5] = "DUE_DILIGENCE";
    ServiceId[ServiceId["MANAGEMENT_OPERATIONS_MAINTENANCE"] = 6] = "MANAGEMENT_OPERATIONS_MAINTENANCE";
    ServiceId[ServiceId["TENANT_REQUIREMENTS"] = 7] = "TENANT_REQUIREMENTS";
})(ServiceId || (ServiceId = {}));
export const serviceIdSchema = z.nativeEnum(ServiceId);
export var ServiceName;
(function (ServiceName) {
    ServiceName["TENDER_DOCUMENTS"] = "tender-documents";
    ServiceName["OFFER"] = "offer";
    ServiceName["TENANT_SPECIFICATION"] = "tenant-specification";
    ServiceName["TENANT_REQUIREMENTS"] = "tenant-requirements";
    ServiceName["DUE_DILIGENCE"] = "due-diligence";
    ServiceName["MANAGEMENT_OPERATIONS_MAINTENANCE"] = "management-operations-maintenance";
    ServiceName["PLANT_ROOM"] = "plant-room";
    ServiceName["CEILING_PLAN"] = "ceiling-plan";
    ServiceName["FLOOR_PLAN"] = "floor-plan";
    ServiceName["SPACE_ANALYSIS"] = "space-analysis";
    ServiceName["PARKING"] = "parking";
    ServiceName["REPORTS"] = "reports";
    ServiceName["STRUCTURAL"] = "structural";
})(ServiceName || (ServiceName = {}));
export var ServicePermissionName;
(function (ServicePermissionName) {
    ServicePermissionName["USE_TENDER_DOCUMENTS_SERVICE"] = "use_tender_documents_service";
    ServicePermissionName["USE_OFFER_SERVICE"] = "use_offer_service";
    ServicePermissionName["USE_TENANT_SPEC_SERVICE"] = "use_tenant_spec_service";
    ServicePermissionName["USE_TENANT_REQ_SERVICE"] = "use_tenant_req_service";
    ServicePermissionName["USE_DUE_DILIGENCE_SERVICE"] = "use_due_diligence_service";
    ServicePermissionName["USE_MANAGE_OPERATE_SERVICE"] = "use_manage_operate_service";
    ServicePermissionName["USE_PLANT_ROOM_SERVICE"] = "use_plant_room_service";
    ServicePermissionName["USE_CEILING_PLAN_SERVICE"] = "use_ceiling_plan_service";
    ServicePermissionName["USE_FLOOR_PLAN_SERVICE"] = "use_floor_plan_service";
    ServicePermissionName["USE_SPACE_ANALYSIS_SERVICE"] = "use_space_analysis_service";
    ServicePermissionName["USE_PARKING_SERVICE"] = "use_parking_service";
    ServicePermissionName["USE_REPORTS_SERVICE"] = "use_reports_service";
    ServicePermissionName["USE_STRUCTURAL_SERVICE"] = "use_structural_service";
})(ServicePermissionName || (ServicePermissionName = {}));
export var ServiceCategory;
(function (ServiceCategory) {
    ServiceCategory["INSIGHT"] = "INSIGHT";
    ServiceCategory["LAYOUT_OPTIMIZATION"] = "LAYOUT_OPTIMIZATION";
    ServiceCategory["STRUCTURAL_ENGINEERING"] = "STRUCTURAL_ENGINEERING";
})(ServiceCategory || (ServiceCategory = {}));
