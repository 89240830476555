import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { fileSchema } from './file';
import { getPaginationResponseSchema } from './pagination';
export var StructuralResultType;
(function (StructuralResultType) {
    StructuralResultType["CAST_IN_PLACE"] = "CAST_IN_PLACE";
    StructuralResultType["STEEL_FRAME_WITH_HOLLOW_CORE_SLABS"] = "STEEL_FRAME_WITH_HOLLOW_CORE_SLABS";
    StructuralResultType["CONCRETE_COLUMNS_WITH_HOLLOW_CORE_SLABS"] = "CONCRETE_COLUMNS_WITH_HOLLOW_CORE_SLABS";
})(StructuralResultType || (StructuralResultType = {}));
export const structuralEngineeringResultSchema = z
    .object({
    id: z.string().uuid(),
    structural_id: z.string().uuid(),
    file: fileSchema,
    concrete_volume: z.number().nullish(),
    steel_weight: z.number().nullish(),
    type: z.nativeEnum(StructuralResultType),
    updated_at: z.string().datetime(),
    created_at: z.string().datetime(),
})
    .strict()
    .transform(mapToCamelCase);
export const getStructuralEngineeringResultsResponseSchema = getPaginationResponseSchema(structuralEngineeringResultSchema);
export const structuralEngineeringResultsResponseSchema = z
    .array(structuralEngineeringResultSchema)
    .transform(mapToCamelCase);
export const createStructuralEngineeringResultRequestSchema = z
    .object({
    file_id: z.string().uuid(),
    steel_weight: z.number().nullish(),
    concrete_volume: z.number().nullish(),
    type: z.nativeEnum(StructuralResultType),
})
    .strict()
    .transform(mapToCamelCase);
export const getStructuralEngineeringResultsRequestSchema = z
    .object({
    project_id: z.number().int(),
    structural_id: z.string().uuid(),
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    search: z.string().nullish(),
    type: z.nativeEnum(StructuralResultType).nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getStructuralEngineeringResultsDashboardRequestSchema = z
    .object({
    project_id: z.number().int(),
    structural_id: z.string().uuid(),
})
    .strict()
    .transform(mapToCamelCase);
export const structuralEngineeringResultsDashboardSchema = z.record(z.nativeEnum(StructuralResultType), z
    .object({ concrete_volume: z.number(), steel_weight: z.number() })
    .strict()
    .transform(mapToCamelCase));
