import { UniqueIdentifier } from '@dnd-kit/core';
import { Dispatch, SetStateAction } from 'react';

import { FolderDocumentsType } from '@/util/types';

import { DraggableContainer } from './draggable-container';
import { TreeFolder } from '../util/document-list-to-tree';

interface FoldersContentProps {
  activeFolder: TreeFolder | null;
  draggingCardId: UniqueIdentifier | string;
  selectedFiles: FolderDocumentsType[] | [];
  setSelectedFiles: Dispatch<SetStateAction<FolderDocumentsType[] | []>>;
}
export const FoldersContent = ({
  activeFolder,
  draggingCardId,
  selectedFiles,
  setSelectedFiles,
}: FoldersContentProps) => {
  return (
    <DraggableContainer
      activeFolder={activeFolder}
      draggingCardId={draggingCardId}
      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}
    />
  );
};
