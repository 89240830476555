import { Button, Position } from '@consigli/facade';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowRight, FiCopy } from 'react-icons/fi';
import { HiOutlineXMark } from 'react-icons/hi2';
import Popup from 'reactjs-popup';

interface ConfirmDragAndDropPopupProps {
  onMove?: () => Promise<void>;
  onCopy?: () => Promise<void>;
  onClose: () => void;
}

export const ConfirmDragAndDropPopup: FC<ConfirmDragAndDropPopupProps> = ({
  onMove,
  onCopy,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <Popup
      overlayStyle={{ background: 'rgba(100, 100, 100, 0.35)' }}
      contentStyle={{
        backgroundColor: '#fbfcfd',
        borderRadius: '10px',
        width: '80%',
        maxWidth: '300px',
      }}
      modal
      open
      onClose={onClose}
    >
      <div className="flex flex-col flex-nowrap gap-y-4 p-4">
        <div className="flex flex-row justify-end items-start">
          {t('folders.drag-header')}
          <Button className="p-0" tertiary icon={HiOutlineXMark} onClick={onClose} iconSize={25} />
        </div>
        <div className="flex gap-4">
          <Button
            className="w-full"
            primary
            onClick={onCopy}
            icon={FiCopy}
            iconColor="white"
            iconPosition={Position.RIGHT}
          >
            {t('folders.copy')}
          </Button>
          <Button
            className="w-full"
            primary
            onClick={onMove}
            icon={FiArrowRight}
            iconColor="white"
            iconPosition={Position.RIGHT}
          >
            {t('folders.move')}
          </Button>
        </div>
      </div>
    </Popup>
  );
};
