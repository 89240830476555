import { OptionType } from '@consigli/facade';
import { ProjectRole } from '@consigli/types';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiOutlineShieldCheck } from 'react-icons/hi';
import { LiaEdit } from 'react-icons/lia';
import { RxCross2 } from 'react-icons/rx';

import { translateServiceTypes } from '@/util/translate-service-types';
import { InviteType } from '@/util/types';

interface InviteProjectListProps {
  selectedProjectList: InviteType[] | undefined;
  setValue: UseFormSetValue<FieldValues>;
  setSelectedProject: Dispatch<SetStateAction<OptionType | undefined>>;
  showProjectPopup: boolean;
  setShowProjectPopup: Dispatch<SetStateAction<boolean>>;
  setShowButtons: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InviteProjectList = (props: InviteProjectListProps) => {
  const { t } = useTranslation();
  const {
    selectedProjectList,
    setValue,
    setSelectedProject,
    setShowProjectPopup,
    setShowButtons,
    showProjectPopup,
  } = props;
  const findKeyByValue = useCallback((arrayOfObjects: OptionType[], targetValue: string) => {
    const foundObject = arrayOfObjects.find((obj: OptionType) => obj.value === targetValue);
    return foundObject ? foundObject.label : null;
  }, []);
  const editProject = useCallback(
    (project: InviteType) => {
      setValue('projectId', project.id);
      setSelectedProject({
        label: project.name!,
        value: project.id,
      });
      setValue('permissions', project.permissions);
      setValue('projectAdminSelected', project.role === ProjectRole.ADMIN ? true : false);
      setShowProjectPopup(true);
    },
    [setSelectedProject, setShowProjectPopup, setValue],
  );
  const confirmRemoveProject = useCallback(
    (project: InviteType) => {
      setShowButtons(true);
      setSelectedProject({
        label: project.name!,
        value: project.id,
      });
    },
    [setSelectedProject, setShowButtons],
  );
  return (
    <>
      {selectedProjectList?.length && !showProjectPopup ? (
        <div className="pb-4">
          {selectedProjectList.map((project: InviteType) => {
            return (
              <div
                key={project.id}
                className="p-4 bg-day-light-3 border-day-light-5 rounded-lg mb-4 relative"
              >
                <div className="text-day-neutral-dark text-base font-semibold first-letter:uppercase">
                  {project.name}
                </div>
                <span className="absolute right-0 cursor-pointer flex top-0 p-4 text-day-neutral-subtle">
                  <span className="px-2">
                    <LiaEdit fontSize={24} onClick={() => editProject(project)} />
                  </span>
                  <span className="cursor-pointer">
                    <RxCross2 fontSize={24} onClick={() => confirmRemoveProject(project)} />
                  </span>
                </span>
                <div className="py-2 text-xs">
                  {project.role === ProjectRole.ADMIN ? (
                    <div className="font-semibold flex items-center text-day-neutral-subtle">
                      <HiOutlineShieldCheck fontSize={24} />
                      <span className="pl-2">{t('invite-user.project-admin')}</span>
                    </div>
                  ) : (
                    <>
                      {project.permissions?.length > 0 && (
                        <div>
                          <span className="font-semibold text-day-neutral-subtle text-xs">
                            {t('invite-user.service-access')}
                          </span>
                          <span className="text-day-neutral-dark text-xs">
                            {project.permissions?.map(
                              (service: string, index: number, array: string[]) => (
                                <React.Fragment key={service}>
                                  {findKeyByValue(translateServiceTypes(t), service)}
                                  {index !== array.length - 1 && ', '}
                                </React.Fragment>
                              ),
                            )}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
