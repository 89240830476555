import { useParams } from 'react-router-dom';
/**
 * @returns the layoutId from the current route (url)
 */
export const useLayoutId = () => {
    const { layoutId } = useParams();
    if (layoutId == null) {
        throw new Error(`useLayoutId called with invalid :layoutId (${layoutId})`);
    }
    return layoutId;
};
