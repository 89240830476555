import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { documentPackageSchema } from './document-package';
import { languageSchema } from './language';
import { projectSchema } from './project';
export var ProcessorJobStatus;
(function (ProcessorJobStatus) {
    ProcessorJobStatus["INITIALIZED"] = "INITIALIZED";
    ProcessorJobStatus["STARTED"] = "STARTED";
    ProcessorJobStatus["FAILED"] = "FAILED";
    ProcessorJobStatus["COMPLETED"] = "COMPLETED";
    ProcessorJobStatus["STOPPED"] = "STOPPED";
})(ProcessorJobStatus || (ProcessorJobStatus = {}));
export const processorJobStatusSchema = z.nativeEnum(ProcessorJobStatus);
export const processorJobSchema = z
    .object({
    id: z.string().uuid(),
    project_id: projectSchema.innerType().shape.id,
    package_id: documentPackageSchema.innerType().shape.id,
    secondary_package_id: documentPackageSchema.innerType().shape.id.nullish(),
    pipeline_name: z.string(),
    project_phase: z.number().int(),
    created_at: z.string(),
    updated_at: z.string(),
    status: processorJobStatusSchema,
    language: languageSchema,
})
    .strict()
    .transform(mapToCamelCase);
export const getProcessorJobsSchema = z.array(processorJobSchema).transform(mapToCamelCase);
export const getProcessorJobSchema = processorJobSchema;
