import { Document } from '@consigli/types';
import { useCallback } from 'react';

import { ViewerMode, useViewerContext } from '../contexts/use-viewer-context';

export const useViewer = () => {
  const { setDocument, setMode } = useViewerContext();

  const openDocumentViewer = useCallback(
    (document: Document, mode: ViewerMode) => {
      setDocument(document);
      setMode(mode);
    },
    [setDocument, setMode],
  );

  const close = useCallback(() => {
    setDocument(null);
    setMode(ViewerMode.Closed);
  }, [setDocument, setMode]);

  return {
    openDocumentViewer,
    close,
  };
};
