import { NS3451 } from '@consigli/types';

import { TreeNode } from '@/pages/folders/util/document-list-to-tree';

export function getStructure(): Record<string, TreeNode> {
  const structure: Record<string, TreeNode> = {};

  for (const key in NS3451) {
    if (key !== NS3451.UNCATEGORIZED) {
      const enumKey = key as keyof typeof NS3451;
      const ns3451Value = NS3451[enumKey].split('_')[1];

      structure[NS3451[enumKey]] = {
        id: NS3451[enumKey],
        children: new Map(),
        ...(ns3451Value.length === 1 && { level: 1 }),
      };
    }
  }

  // manually add uncategorized at the end
  structure[NS3451[NS3451.UNCATEGORIZED]] = {
    id: NS3451.UNCATEGORIZED,
    level: 1,
    children: new Map(),
  };

  return structure;
}
