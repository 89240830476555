import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
export const projectPhaseSchema = z
    .object({
    id: z.number().int(),
    name: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const getProjectPhasesResponseSchema = z.array(projectPhaseSchema).transform(mapToCamelCase);
