import { ButtonGroup, Button } from '@consigli/facade';
import { usePackageId, useUpdateBatchFindingsMutation } from '@consigli/hooks';
import { ActionStatus } from '@consigli/types';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { SlPaperPlane } from 'react-icons/sl';
import { toast } from 'react-toastify';

import { MultipleSelectionDialog } from '@/atoms/multiple-selection-dialog';
import { CheckableFinding } from '@/util/types';

import { mapActionNameToStates } from './findings-content';
import { useFindingsContext } from './findings-context';
import { processing } from './findings-wrapper';
import { ProcessingPopup } from './processing-pop-up';

type BatchUpdateButtonProps = {
  records: CheckableFinding[];
  projectId: number;
  buttonKeys: string[];
  count: number;
  showButtons: boolean;
  setShowButtons: Dispatch<SetStateAction<boolean>>;
  itemsOnPage: number;
  onComplete: () => void;
};

export const BatchUpdateButton: FC<BatchUpdateButtonProps> = ({
  records,
  projectId,
  buttonKeys,
  count,
  showButtons,
  setShowButtons,
  itemsOnPage,
  onComplete,
}) => {
  const { t } = useTranslation();
  const packageId = usePackageId();
  const { page, setPage } = useFindingsContext();
  const [updateBatchFindings, updateBatchFindingsResult] = useUpdateBatchFindingsMutation();
  const [isForwardFindingOpen, setForwardFindingOpen] = useState(false);

  const checkStatus = (status?: ActionStatus) => {
    switch (status) {
      case ActionStatus.NOT_RELEVANT:
        return {
          icon: 'table-status-notrelevant',
          text: 'riskassessment.states.notrelevant',
          status: ActionStatus.NOT_RELEVANT,
        };
      case ActionStatus.UNADDRESSED:
        return {
          icon: 'table-status-unaddressed',
          text: 'riskassessment.states.unaddressed',
          status: ActionStatus.UNADDRESSED,
        };
      case ActionStatus.WAITING:
        return {
          icon: 'table-status-waiting',
          text: 'riskassessment.states.waiting',
          status: ActionStatus.WAITING,
        };
      case ActionStatus.PROCESSED:
        return {
          icon: 'table-status-processed',
          text: 'riskassessment.states.processed',
          status: ActionStatus.PROCESSED,
        };
      default:
        return {
          icon: 'table-status-notrelevant',
          text: 'riskassessment.states.__fallback',
          status: ActionStatus.NOT_RELEVANT,
        };
    }
  };

  useEffect(() => {
    if (updateBatchFindingsResult.status === QueryStatus.fulfilled) {
      if (updateBatchFindingsResult.originalArgs == null) {
        throw new Error('Cannot display toast when response.originalArgs is missing!');
      }
      const text = t(checkStatus(updateBatchFindingsResult.originalArgs.data[0].action).text);
      toast.info(t('toast.file-update-success', { status: text }));
    }
  }, [updateBatchFindingsResult, t]);

  useEffect(() => {
    if (updateBatchFindingsResult.isError) {
      toast.error(t('toast.file-update-failure'));
    }
  }, [updateBatchFindingsResult, t]);

  const updateFindings = useCallback(
    async (action: ActionStatus) => {
      if (itemsOnPage === count && page > 1) {
        setPage((prevPage) => prevPage - 1);
      }
      await updateBatchFindings({
        projectId,
        packageId,
        data: records
          .filter((finding: CheckableFinding) => finding.checked)
          .map((finding) => ({
            id: finding.id,
            action,
          })),
      });
      onComplete();
    },
    [
      updateBatchFindings,
      projectId,
      packageId,
      records,
      itemsOnPage,
      count,
      page,
      onComplete,
      setPage,
    ],
  );

  if (!showButtons) return <></>;

  return (
    <>
      <MultipleSelectionDialog>
        <div className="flex items-center justify-between pb-4">
          <span className="font-bold">
            {count} {t('riskassessment.findings-selected')}
          </span>
          <FaTimes className="cursor-pointer" onClick={() => setShowButtons(false)} />
        </div>
        <div className="flex">
          <ButtonGroup>
            {buttonKeys.map((key: string) => (
              <Button
                secondary
                rounded
                key={key}
                icon={mapActionNameToStates[key].icon}
                onClick={() => updateFindings(mapActionNameToStates[key].action)}
              >
                {t(`riskassessment.status.${key}`)}
              </Button>
            ))}
            {records && records[0]?.action.toString() !== processing && (
              <Button
                secondary
                rounded
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation();
                  setForwardFindingOpen(true);
                }}
                icon={SlPaperPlane}
              >
                {t('riskassessment.status.3')}
              </Button>
            )}
          </ButtonGroup>
        </div>
      </MultipleSelectionDialog>
      {isForwardFindingOpen && (
        <ProcessingPopup
          findings={records.filter((finding: CheckableFinding) => finding.checked)}
          count={count}
          itemsOnPage={itemsOnPage}
          onClose={() => setForwardFindingOpen(false)}
          onComplete={onComplete}
        />
      )}
    </>
  );
};
