import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

type AccordionContext = {
  open: null | number;
  toggle: (index: null | number) => void;
};

export const AccordionContext = createContext<AccordionContext | null>(null);

export const useAccordionContext = () => {
  const ctx = useContext(AccordionContext);

  if (!ctx) {
    throw new Error('Missing AccordionProvider in tree');
  }

  return ctx;
};

export const AccordionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState<number | null>(null);

  const toggle = (index: number | null) => {
    setOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  const value = {
    open,
    toggle,
  };

  return <AccordionContext.Provider value={value}>{children}</AccordionContext.Provider>;
};
