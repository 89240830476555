import { useMemo } from 'react';
import { useBlobs } from './use-blobs';
/**
 * Returns the blobs from the given projectId and packageId.
 *
 * @param projectId the project id that the blobs belong to.
 * @param packageId the package id within the project, that the blobs belong to.
 *
 * @returns an array with blobs and a loading state.
 */
export const useBlobsById = (projectId, packageId) => {
    const { blobs, isLoading } = useBlobs({
        projectId,
        packageId,
        page: 'all', //fetches all blobs (disregards pagination)
    });
    return useMemo(() => ({
        blobsById: blobs.reduce((acc, blob) => {
            acc[blob.id] = blob;
            return acc;
        }, {}),
        isLoading,
    }), [isLoading, blobs]);
};
