import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
// Shared pagination response schema. All Django pagination response will have this structure
export function getPaginationResponseSchema(itemSchema) {
    return z
        .object({
        count: z.number().int(),
        next: z.string().nullable(),
        previous: z.string().nullable(),
        results: z.array(itemSchema),
    })
        .strict()
        .transform(mapToCamelCase);
}
