import { useMemo } from 'react';
import { useGetProjectQuery } from '../api';
/**
 * Hook to get a single project.
 *
 * @param projectId the project id to fetch.
 *
 * @returns an object with a project, loading state, error state and error object.
 */
export const useProject = (projectId) => {
    const { data: project, isLoading, isError, error } = useGetProjectQuery({ projectId });
    return useMemo(() => ({
        project,
        isLoading,
        isError,
        error,
    }), [error, isError, isLoading, project]);
};
