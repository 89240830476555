import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { getPaginationResponseSchema } from './pagination';
export var ProcessorJobStageTaskStatus;
(function (ProcessorJobStageTaskStatus) {
    ProcessorJobStageTaskStatus["INITIALIZED"] = "INITIALIZED";
    ProcessorJobStageTaskStatus["STARTED"] = "STARTED";
    ProcessorJobStageTaskStatus["FAILED"] = "FAILED";
    ProcessorJobStageTaskStatus["COMPLETED"] = "COMPLETED";
    ProcessorJobStageTaskStatus["SKIPPED"] = "SKIPPED";
})(ProcessorJobStageTaskStatus || (ProcessorJobStageTaskStatus = {}));
export const processorJobStageTaskStatusSchema = z.nativeEnum(ProcessorJobStageTaskStatus);
export const processorJobStageTaskSchema = z
    .object({
    id: z.string().uuid(),
    processor_name: z.string().nullable(),
    task_name: z.string(),
    status: processorJobStageTaskStatusSchema,
    status_description: z.string().nullable(),
    created_at: z.string(),
    updated_at: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const getProcessorJobStageTasksSchema = getPaginationResponseSchema(processorJobStageTaskSchema);
export const getProcessorJobStageTasksRequestSchema = z
    .object({
    projectId: z.number().int(),
    jobId: z.string().uuid(),
    stageId: z.string().uuid(),
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    search: z.string().nullish(),
    status: processorJobStageTaskStatusSchema.nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getProcessorJobStageTaskSchema = processorJobStageTaskSchema;
