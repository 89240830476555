import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
export const excelExportSchema = z
    .object({
    title: z.string(),
    headers: z.array(z.string()),
    data: z.array(z.array(z.union([z.string(), z.number()]))),
})
    .strict()
    .transform(mapToCamelCase);
export const excelExportRequestSchema = z
    .object({
    project_id: z.number(),
    package_id: z.string().uuid(),
    excel_export: excelExportSchema,
})
    .strict()
    .transform(mapToCamelCase);
export const excelExportResponseSchema = z
    .object({
    base64_data: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
