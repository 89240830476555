import clsx from 'clsx';

import { PACKAGE_TYPE, useCompareContext } from './compare/use-compare-packages';

type TabType = {
  id: number;
  label: string;
  content: JSX.Element;
  type: PACKAGE_TYPE;
};

interface SecondaryTabProps {
  tabs: TabType[];
}
export const SecondaryTab = ({ tabs }: SecondaryTabProps) => {
  const { setPackageType, packageType } = useCompareContext();
  const onClick = (type: PACKAGE_TYPE) => {
    setPackageType(type);
  };
  const activeTabIndex = tabs.findIndex((tab) => tab.type === packageType);
  return (
    <div>
      <div className="flex flex-row py-2">
        {tabs?.map((tab) => (
          <div
            aria-hidden
            key={tab.id}
            className={clsx(
              'flex flex-1 items-center justify-center mt-4 pb-4 px-4 text-base font-semibold mb-2 text-day-neutral-subtle border-b-2 cursor-pointer first-letter:capitalize',
              tab.type === packageType
                ? ' font-semibold border-b-2 border-day-primary'
                : 'border-day-secondary',
              'transition-colors duration-300 ease-in-out hover:border-b-2 text-day-neutral-dark',
            )}
            onClick={() => onClick(tab.type)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div>{tabs[activeTabIndex]?.content}</div>
    </div>
  );
};
