import { CustomSelect, OptionType, isOptionType } from '@consigli/facade';
import { DocumentPackage } from '@consigli/types';
import { useCallback, useMemo } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';

import { useCompareContext } from '../compare/use-compare-packages';

export const ExistingPackage = () => {
  const { t } = useTranslation();
  const { resetField } = useFormContext<FieldValues>();

  const {
    setSecondaryData,
    selectedComparisonPackage,
    setSelectedComparisonPackage,
    setSecondaryFiles,
    comparePackages: packages,
  } = useCompareContext();
  const options: OptionType[] = useMemo(() => {
    return (
      packages?.map((spec) => ({
        label: spec.name,
        value: spec.id,
      })) || []
    );
  }, [packages]);

  const handleSelectChange = useCallback(
    (option: SingleValue<OptionType> | MultiValue<OptionType>): void => {
      if (isOptionType(option) && option != null) {
        const pkg = packages?.find((spec) => spec.id === option.value);
        setSelectedComparisonPackage(pkg as DocumentPackage);
        setSecondaryFiles([]);
        setSecondaryData({ secondaryPackageName: '' });
        resetField('secondaryPackageName');
      } else {
        setSelectedComparisonPackage(undefined);
      }
    },
    [setSelectedComparisonPackage, packages, setSecondaryFiles, setSecondaryData, resetField],
  );
  return (
    <>
      {options.length !== 0 ? (
        <>
          <div className="text-base font-semibold mt-4 mb-2 text-day-neutral-subtle">
            {t('package-creation.compare-with-existing-package')}
          </div>
          <CustomSelect
            options={options}
            onChange={handleSelectChange}
            value={
              selectedComparisonPackage
                ? {
                    label: selectedComparisonPackage.name,
                    value: selectedComparisonPackage.id,
                  }
                : { label: '', value: '' }
            }
            placeholder={t('package-creation.select-package')}
            onBlur={() => {}}
            isClearable
          />
        </>
      ) : (
        <div className="text-base font-semibold text-day-neutral-subtle">
          {t('package-creation.no-packages-found-create-new')}
        </div>
      )}
    </>
  );
};
