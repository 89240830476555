import { Button } from '@consigli/facade';
import { useBreakpoint, useProjectId, usePackageId, useUpdateBlobMutation } from '@consigli/hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa6';
import { toast } from 'react-toastify';

interface AcceptSuggestionActionProps {
  blobId: string;
  nameSuggestion: string;
}

export const AcceptSuggestionAction = ({ blobId, nameSuggestion }: AcceptSuggestionActionProps) => {
  const { t } = useTranslation();
  const smallWindow = useBreakpoint(800);
  const projectId = useProjectId();
  const packageId = usePackageId();
  const [updateBlob, { isLoading }] = useUpdateBlobMutation();
  const updateName = useCallback(
    async (blobId: string, nameSuggestion: string) => {
      try {
        await updateBlob({
          projectId,
          packageId,
          blobId,
          data: { name: nameSuggestion, nameSuggestion: null },
        }).unwrap();
        toast.success(t('document-list.success-single'));
      } catch (e) {
        toast.error(t('document-list.failed-single'));
      }
    },
    [updateBlob, projectId, packageId, t],
  );

  return (
    <Button
      secondary
      rounded
      disabled={isLoading}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        updateName(blobId, nameSuggestion);
      }}
      title={t('document-list.accept-suggestion')}
      icon={FaCheck}
      iconSize={18}
    >
      {smallWindow && t('document-list.accept-suggestion')}
    </Button>
  );
};
