import { configureStore } from '@reduxjs/toolkit';
import { useSelector as useReduxSelector } from 'react-redux';
import { reducer as apiReducer, reducerPath as apiReducerPath, middleware as apiMiddleware, } from './api';
export const reduxStore = configureStore({
    reducer: {
        [apiReducerPath]: apiReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiMiddleware),
});
export const useSelector = (callback, equalityFn) => useReduxSelector(callback, equalityFn);
