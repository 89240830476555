import { ServiceName } from '@consigli/types';
import { useParams } from 'react-router-dom';
/**
 * @returns the serviceName from the current route (url) or null if not present
 
 */
export const useServiceNameOrNull = () => {
    const { serviceName } = useParams();
    if (serviceName && !Object.values(ServiceName).includes(serviceName)) {
        throw new Error(`useServiceName called with invalid :serviceName (${serviceName})`);
    }
    return serviceName ?? null;
};
