import { CustomSelect, OptionType, isOptionType } from '@consigli/facade';
import { useProjectId, useServiceName, useLazyGetPackagesByServiceIdQuery } from '@consigli/hooks';
import { ServiceId, ServiceName } from '@consigli/types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';

import { ServiceIdIndexValue } from '@/routes';
import {
  compareWithOfferServiceTypes,
  compareWithTenantServiceTypes,
} from '@/util/translate-service-types';

import { useCompareContext } from '../compare/use-compare-packages';

export const SecondaryService = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const serviceName = useServiceName();
  const [getPackages] = useLazyGetPackagesByServiceIdQuery();
  const {
    setSelectedComparisonPackage,
    compareServiceName,
    setCompareServiceName,
    setComparePackages: setPackages,
  } = useCompareContext();

  const comparableInsightServices = useMemo(() => {
    if (serviceName === ServiceName.TENANT_REQUIREMENTS) {
      return compareWithTenantServiceTypes(t);
    } else if (serviceName === ServiceName.OFFER) {
      return compareWithOfferServiceTypes(t);
    }
    return [];
  }, [t, serviceName]);

  const handlePackages = useCallback(
    async (id: ServiceId) => {
      const response = await getPackages({
        projectId,
        serviceId: id,
      }).unwrap();
      return response;
    },
    [getPackages, projectId],
  );

  const handleServiceChange = useCallback(
    async (option: SingleValue<OptionType> | MultiValue<OptionType>): Promise<void> => {
      if (isOptionType(option) && option != null) {
        const pkge = comparableInsightServices.find((spec) => spec.value === option.value);
        setCompareServiceName(pkge);
        const serviceId = ServiceIdIndexValue[option.value as ServiceName] as ServiceId;
        const response = await handlePackages(serviceId);
        setPackages(response);
        setSelectedComparisonPackage(undefined);
      } else {
        setCompareServiceName(undefined);
        setSelectedComparisonPackage(undefined);
      }
    },
    [
      comparableInsightServices,
      handlePackages,
      setCompareServiceName,
      setPackages,
      setSelectedComparisonPackage,
    ],
  );
  return (
    <>
      <div className="text-base font-semibold mb-2 text-day-neutral-subtle">
        {t('package-creation.compare-with-service')}
      </div>
      <CustomSelect
        options={comparableInsightServices}
        onChange={handleServiceChange}
        value={
          compareServiceName
            ? { label: compareServiceName.label, value: compareServiceName.value }
            : undefined
        }
        placeholder={t('package-creation.select-service')}
        onBlur={() => {}}
        isClearable
      />
    </>
  );
};
