import { BlobClient } from '@azure/storage-blob';
import { useAuthContext } from '@consigli/oauth';
import { FileUploadStatus } from '@consigli/types';
import { getMD5Hash } from '@consigli/utils';
import { useCallback } from 'react';
import { useCreateFileMutation, useLazyGetFileUploadUrlQuery, useUpdateFileMutation } from '../api';
/**
 * Hook for uploading a single file to Azure and registering it in Core API
 *
 * @param customFilename optional custom filename to use instead of the original filename
 *
 * @returns an upload function
 */
export const useFileUpload = (customFilename) => {
    const [createFile] = useCreateFileMutation();
    const [getFileUploadUrl] = useLazyGetFileUploadUrlQuery();
    const [updateFile] = useUpdateFileMutation();
    const auth = useAuthContext();
    const token = auth.session?.idToken.token;
    if (token == null) {
        throw new Error('Cannot use file upload with missing auth token!');
    }
    const upload = useCallback(async (file, projectId, onProgress) => {
        const createdFile = await createFile({
            projectId,
            file: {
                originalFilename: customFilename ? customFilename : file.name,
                mimeType: file.type,
                sizeBytes: file.size,
            },
        }).unwrap();
        const { uploadUrl: url } = await getFileUploadUrl({
            projectId,
            fileId: createdFile.id,
        }).unwrap();
        const blobUpload = new BlobClient(url).getBlockBlobClient().uploadData(file, {
            onProgress: onProgress,
        });
        const fileDataPromise = getMD5Hash(file);
        await updateFile({
            projectId,
            fileId: createdFile.id,
            file: { uploadStatus: FileUploadStatus.UPLOADING },
        }).unwrap();
        // Await upload of file to Azure storage before finishing, await file reading before calculating MD5
        const [, md5] = await Promise.all([blobUpload, fileDataPromise]);
        const updatedFile = await updateFile({
            projectId,
            fileId: createdFile.id,
            file: { uploadStatus: FileUploadStatus.UPLOADED, md5: md5 },
        }).unwrap();
        return updatedFile;
    }, [createFile, customFilename, getFileUploadUrl, updateFile]);
    return {
        upload,
    };
};
