import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { findingSchema } from './finding';
import { userSchema } from './user';
export var FindingCommentType;
(function (FindingCommentType) {
    FindingCommentType["USER_COMMENT"] = "USER_COMMENT";
    FindingCommentType["SYSTEM_COMMENT_FORWARDED"] = "SYSTEM_COMMENT_FORWARDED";
})(FindingCommentType || (FindingCommentType = {}));
export const findingCommentSchema = z
    .object({
    id: z.number().int(),
    authorId: userSchema.innerType().shape.id.nullish(),
    authorName: z.string().nullish(),
    findingId: findingSchema.innerType().shape.id,
    text: z.string().nullish(),
    created_at: z.string().nullish(),
    type: z.nativeEnum(FindingCommentType),
})
    .strict()
    .transform(mapToCamelCase);
export const getFindingCommentsResponseSchema = z
    .array(findingCommentSchema)
    .transform(mapToCamelCase);
export const createFindingCommentResponseSchema = findingCommentSchema;
