import { Button, Checkbox, CustomSelect, OptionType, TextInput } from '@consigli/facade';
import { useProjectId, usePackageId, useUpdateBlobMutation } from '@consigli/hooks';
import { FoldersDocumentType, NS3451 } from '@consigli/types';
import { FC, useState } from 'react';
import { Controller, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiOutlineXMark } from 'react-icons/hi2';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import { FolderDocumentsType } from '@/util/types';

interface EditOperationsFilesPopupProps {
  isMultiple: boolean;
  documents: FolderDocumentsType[];
  onClose: () => void;
}

type UpdateOperationsFilesForm = {
  filename?: string;
  ns3451: NS3451[];
  documentType: FoldersDocumentType[];
  moveFile?: boolean;
};

export const EditOperationsFilesPopup: FC<EditOperationsFilesPopupProps> = ({
  isMultiple,
  documents,
  onClose,
}) => {
  const projectId = useProjectId();
  const packageId = usePackageId();
  const { t } = useTranslation();
  const [updateBlob] = useUpdateBlobMutation();

  const ns3451Options = Object.entries(NS3451).map(([key, value]) => ({
    label: t('ns3451.' + key),
    value,
  }));
  const sharedNS3451 = ns3451Options
    .filter((option) => documents.every((document) => document.ns3451.includes(option.value)))
    .map((option) => option.value);

  const documentTypeOptions = Object.entries(FoldersDocumentType).map(([key, value]) => ({
    label: t('folders.document-type.' + key),
    value,
  }));

  const sharedDocumentTypes = documentTypeOptions
    .filter((option) => documents.every((document) => document.documentType.includes(option.value)))
    .map((option) => option.value);

  const { register, handleSubmit, control, getValues } = useForm<UpdateOperationsFilesForm>();
  const {
    field: { value: selectedNS3451, onChange: ns3451OnChange, ...restNS3451 },
  } = useController({
    name: 'ns3451',
    control,
    defaultValue: sharedNS3451,
  });
  const {
    field: { value: selectedDocumentTypes, onChange: documentTypesOnChange, ...restDocumentTypes },
  } = useController({
    name: 'documentType',
    control,
    defaultValue: sharedDocumentTypes,
  });
  const [moveFile, setMoveFile] = useState<boolean>(getValues('moveFile') ?? false);

  const onSubmit = async (data: UpdateOperationsFilesForm) => {
    try {
      await Promise.all(
        documents.map((document) => {
          // Determine the ns3451 values and document types to add and remove
          // This needs to be done because patch overwrites the current list in  the db,
          // and we need to support both updating single and multiple files
          let updatedNS3451: NS3451[] = [];
          let updatedDocumentTypes: FoldersDocumentType[] = [];
          if (data.moveFile) {
            updatedNS3451 = data.ns3451.length > 0 ? data.ns3451 : [NS3451.UNCATEGORIZED];
            updatedDocumentTypes = data.documentType;
          } else {
            const ns3451ToAdd = data.ns3451.filter((value) => !document.ns3451.includes(value));
            const ns3451ToRemove = sharedNS3451.filter((value) => !data.ns3451.includes(value));
            updatedNS3451 = document.ns3451
              .filter((value) => !ns3451ToRemove.includes(value))
              .concat(ns3451ToAdd);
            updatedNS3451 = updatedNS3451.length > 0 ? updatedNS3451 : [NS3451.UNCATEGORIZED];
            const documentTypeToAdd = data.documentType.filter(
              (value) => !document.documentType.includes(value),
            );
            const documentTypeToRemove = sharedDocumentTypes.filter(
              (value) => !data.documentType.includes(value),
            );
            updatedDocumentTypes = document.documentType
              .filter((value) => !documentTypeToRemove.includes(value))
              .concat(documentTypeToAdd);
          }

          return updateBlob({
            projectId,
            packageId,
            blobId: document.id,
            data: {
              name: data.filename,
              ns3451: updatedNS3451,
              documentType: updatedDocumentTypes,
            },
          });
        }),
      );
      toast.success(t('folders.update-success'));
      onClose();
    } catch (error) {
      toast.error(t('folders.update-error'));
    }
  };

  return (
    <Popup
      overlayStyle={{ background: 'rgba(100, 100, 100, 0.35)' }}
      contentStyle={{
        backgroundColor: '#fbfcfd',
        borderRadius: '10px',
        width: '80%',
        maxWidth: '500px',
      }}
      modal
      open
      onClose={onClose}
    >
      <div className="flex flex-col flex-nowrap gap-y-4 p-4">
        <div className="flex flex-row justify-between">
          <h2 className="font-bold text-2xl text-day-neutral-dark">{t('folders.edit-metadata')}</h2>
          <Button className="p-0" tertiary icon={HiOutlineXMark} onClick={onClose} iconSize={25} />
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="p-2 bg-day-light-4">
            {!isMultiple && (
              <>
                <p>{t('folders.edit-filename')}</p>
                <TextInput
                  className="w-full mb-6"
                  {...register('filename')}
                  defaultValue={documents[0].name}
                />
              </>
            )}
            <p>{t('folders.ns3451')}</p>
            <CustomSelect
              placeholder={t('folders.select-ns3451')}
              isMulti
              isClearable={false}
              value={
                selectedNS3451 != null
                  ? ns3451Options.filter((option) => selectedNS3451.includes(option.value))
                  : []
              }
              options={ns3451Options}
              onChange={(options) =>
                ns3451OnChange(
                  options ? (options as OptionType[]).map((option) => option.value) : [],
                )
              }
              {...restNS3451}
            />
            <p className="mt-4">{t('folders.document-types')}</p>
            <CustomSelect
              placeholder={t('folders.select-document-types')}
              isMulti
              isClearable={false}
              value={
                selectedDocumentTypes != null
                  ? documentTypeOptions.filter((option) =>
                      selectedDocumentTypes.includes(option.value),
                    )
                  : []
              }
              options={documentTypeOptions}
              onChange={(options) =>
                documentTypesOnChange(
                  options ? (options as OptionType[]).map((option) => option.value) : [],
                )
              }
              {...restDocumentTypes}
            />
            <div className="mt-4">
              <Controller
                name="moveFile"
                control={control}
                render={({ field }) => (
                  <div className="flex">
                    <Checkbox
                      id="moveFile"
                      label={t('folders.move-file')}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                        setMoveFile(event.target.checked);
                      }}
                      value={moveFile?.toString()}
                      checked={moveFile}
                    ></Checkbox>
                  </div>
                )}
              />
            </div>
            <div className="flex gap-4 pt-4">
              <Button className="flex-1" primary rounded type="submit">
                {t('folders.update')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Popup>
  );
};
