import { Button, Position, Search } from '@consigli/facade';
import { useKeyListener } from '@consigli/hooks';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

import { Route } from '@/routes';

export const DashboardSearch = () => {
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onSearchTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);
  const handleSearchClick = useCallback(() => {
    if (searchText) {
      navigate(`../${Route.DOCUMENTS}/?q=${searchText}`);
    }
  }, [navigate, searchText]);

  useKeyListener('Enter', handleSearchClick);
  return (
    <div className="flex my-4 items-center justify-between">
      <div className="flex-1">
        <Search
          text={searchText}
          onChange={onSearchTextChange}
          placeholder={t('dashboard.search-documents')}
          className="w-full"
        ></Search>
      </div>
      <Button
        rounded
        primary
        disabled={!searchText}
        iconColor="#ffffff"
        className="ml-4 text-base font-semibold"
        onClick={handleSearchClick}
        icon={FaArrowRightLong}
        iconPosition={Position.RIGHT}
      >
        {t('dashboard.search')}
      </Button>
    </div>
  );
};
