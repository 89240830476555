import { useParams } from 'react-router-dom';
export const useServiceId = () => {
    const params = useParams();
    const possibleIdNames = ['packageId', 'structuralId', 'layoutId'];
    for (const idName of possibleIdNames) {
        if (params[idName]) {
            return params[idName];
        }
    }
    throw new Error(`useServiceId called with no valid ID found in the URL`);
};
