import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { fileSchema } from './file';
import { getPaginationResponseSchema } from './pagination';
export const layoutOptimizationResultSchema = z
    .object({
    id: z.string().uuid(),
    layout_id: z.string().uuid(),
    file: fileSchema,
    updated_at: z.string().datetime(),
    created_at: z.string().datetime(),
})
    .strict()
    .transform(mapToCamelCase);
export const getLayoutOptimizationResultsResponseSchema = getPaginationResponseSchema(layoutOptimizationResultSchema);
export const layoutOptimizationResultsResponseSchema = z
    .array(layoutOptimizationResultSchema)
    .transform(mapToCamelCase);
export const createLayoutOptimizationResultRequestSchema = z
    .object({
    file_id: z.string().uuid(),
})
    .strict()
    .transform(mapToCamelCase);
export const getLayoutOptimizationResultsRequestSchema = z
    .object({
    project_id: z.number().int(),
    layout_id: z.string().uuid(),
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    search: z.string().nullish(),
})
    .strict()
    .transform(mapToCamelCase);
