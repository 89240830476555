import { UseStepContext } from '@consigli/facade';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

export interface PackageFormProps {
  context: UseStepContext;
  setFormData: Dispatch<SetStateAction<FieldValues>>;
  formData: FieldValues;
  children?: JSX.Element;
}

export const PackageForm = ({ context, setFormData, formData, children }: PackageFormProps) => {
  const methods = useForm<FieldValues>({
    defaultValues: formData,
  });
  const { handleSubmit } = methods;
  const { next } = context();

  const onSubmit = useCallback(
    (data: FieldValues, e?: React.BaseSyntheticEvent) => {
      e?.preventDefault();
      setFormData(data);
      next();
    },
    [next, setFormData],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="flex flex-col" translate="no">
          {children}
        </div>
      </form>
    </FormProvider>
  );
};
