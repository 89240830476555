import { TextInput } from '@consigli/facade';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const CeilingHeight = () => {
  const { t } = useTranslation();
  const { register, formState, resetField } = useFormContext<FieldValues>();
  const { errors } = formState;
  return (
    <div className="mb-4">
      <TextInput
        label={t('package-creation.ceiling-heights')}
        className="my-1 border rounded inline sm:w-full"
        {...register('ceilingHeight', {
          pattern: {
            value: /^(\d*\.?\d*)$/,
            message: `${t('package-creation.input-number')}*`,
          },
        })}
        onReset={() => resetField('ceilingHeight')}
      />
      {errors.ceilingHeight && (
        <div className="text-red-700 text-sm">{String(errors.ceilingHeight.message)}</div>
      )}
    </div>
  );
};
