/**
 *
 * @param array An array of elements
 * @param uniqueProvider Optional function for providing a value to use for uniqueness checks. Defaults to identity function.
 */
export const uniqueBy = (array, uniqueProvider) => {
    const seen = new Set();
    return array.reduce((acc, value) => {
        const key = uniqueProvider(value);
        if (seen.has(key)) {
            return acc;
        }
        else {
            seen.add(key);
            acc.push(value);
            return acc;
        }
    }, []);
};
export const wrapIfDefined = (value) => (value == null ? [] : [value]);
export const isNonEmptyArray = (arr) => arr.length > 0;
// Inspired from lodash https://github.com/lodash/lodash/blob/ddfd9b11a0126db2302cb70ec9973b66baec0975/dist/lodash.core.js#L860
export const chunk = (array, size) => {
    let index = 0, resIndex = 0;
    const result = Array(Math.ceil(array.length / size));
    while (index < array.length) {
        result[resIndex++] = array.slice(index, index + size);
        index += size;
    }
    return result;
};
export const zip = (array1, array2) => {
    if (array1.length !== array2.length) {
        throw new Error('Cannot zip two arrays of different length!');
    }
    return array1.map((_, i) => [array1[i], array2[i]]);
};
