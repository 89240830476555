import { DocumentType } from '@consigli/types';
import { useCallback, useEffect, useState } from 'react';
import { useProjectId } from './use-project-id';
import { useLazyGetBlobReadUrlQuery, useLazyGetFileReadUrlQuery } from '../api';
/**
 * Hook to get a read url for a document.
 *
 * @param document the document to get the read url for.
 * @param isRef whether the document is a reference document.
 *
 * @returns an object with a read url and loading state.
 */
export const useReadUrl = (document, isRef = false) => {
    const projectId = useProjectId();
    const [readUrl, setReadUrl] = useState();
    const [getBlobPreviewUrl, { isLoading: isLoadingBlobPreviewUrl }] = useLazyGetBlobReadUrlQuery();
    const [getFilePreviewUrl, { isLoading: isLoadingFilePreviewUrl }] = useLazyGetFileReadUrlQuery();
    const isLoading = isLoadingBlobPreviewUrl || isLoadingFilePreviewUrl;
    const getReadUrl = useCallback(async () => {
        if (document.type === DocumentType.BLOB || document.type === DocumentType.FINDING) {
            const response = await getBlobPreviewUrl({
                projectId,
                packageId: isRef && document.refDocumentPackageId
                    ? document.refDocumentPackageId
                    : document.documentPackageId,
                blobId: isRef && document.refBlobId ? document.refBlobId : document.id,
            }).unwrap();
            setReadUrl(response.readUrl);
        }
        else if (document.type === DocumentType.FILE) {
            const response = await getFilePreviewUrl({
                projectId,
                fileId: document.id,
            }).unwrap();
            setReadUrl(response.readUrl);
        }
        else {
            throw new Error('Invalid document type');
        }
    }, [document, getBlobPreviewUrl, getFilePreviewUrl, isRef, projectId]);
    useEffect(() => {
        getReadUrl();
    }, [getReadUrl]);
    return { readUrl, isLoading };
};
