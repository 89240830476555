import { FC } from 'react';
import { IconType } from 'react-icons';

interface ServiceHeaderProps {
  icon: IconType;
  count: number;
  title: string;
  message: string;
}

export const ServiceHeader: FC<ServiceHeaderProps> = ({ icon: Icon, count, title, message }) => {
  return (
    <div className="flex items-center">
      <div className="pl-4 pr-3">
        <Icon size="3em" color="#CBD5E1" title={title} />
      </div>
      <div className="flex flex-col text-day-neutral-dark">
        <div className="text-base">
          {count} {message}
        </div>
        <div className="text-2xl font-semibold">{title}</div>
      </div>
    </div>
  );
};
