import { Button, Position, UseStepContext } from '@consigli/facade';
import { useTranslation } from 'react-i18next';

interface BackButtonProps {
  context: UseStepContext;
}

export const WizardBackButton = ({ context }: BackButtonProps) => {
  const { t } = useTranslation();
  const { previous } = context();
  return (
    <Button
      rounded
      primary
      className="w-full fc-px-3 fc-py-2 mt-2"
      onClick={previous}
      iconPosition={Position.LEFT}
      iconColor="white"
    >
      {t('createproject.back')}
    </Button>
  );
};
