import { useMemo } from 'react';
import { useGetInvitesQuery } from '../api';
/**
 * Returns an object with the parameters to fetch invites.
 * The param object contains the following properties:
 *
 * @param page the page to fetch. required.
 * @param page_size the number of invites to fetch per page.
 * @param search
 * @param invitedByEmail
 * @param recipient
 *
 * @returns an object with invites response from API, loading state, fetching state, error state and error object.
 */
export const useInvites = (params) => {
    const { data: invitesResponse, isLoading, isFetching, isError, error, } = useGetInvitesQuery(params);
    return useMemo(() => {
        const paginatedCount = invitesResponse?.count || 0;
        const invites = invitesResponse?.results || [];
        const next = invitesResponse?.next;
        const previous = invitesResponse?.previous;
        return {
            invites,
            paginatedCount,
            next,
            previous,
            isLoading,
            isFetching,
            isError,
            error,
        };
    }, [invitesResponse, isLoading, isFetching, isError, error]);
};
