import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { languageSchema } from './language';
import { projectSchema } from './project';
import { projectPhaseSchema } from './project-phase';
export var ProjectPhaseType;
(function (ProjectPhaseType) {
    ProjectPhaseType[ProjectPhaseType["KONKURRANSEGRUNNLAG"] = 1] = "KONKURRANSEGRUNNLAG";
    ProjectPhaseType[ProjectPhaseType["TILBUD"] = 2] = "TILBUD";
    ProjectPhaseType[ProjectPhaseType["LEVERINGSBESKRIVELSE"] = 3] = "LEVERINGSBESKRIVELSE";
    ProjectPhaseType[ProjectPhaseType["ROMOPTIMALISERING"] = 4] = "ROMOPTIMALISERING";
    ProjectPhaseType[ProjectPhaseType["DUEDILIGENCE"] = 5] = "DUEDILIGENCE";
    ProjectPhaseType[ProjectPhaseType["FDV"] = 6] = "FDV";
    ProjectPhaseType[ProjectPhaseType["KRAVSPESIFIKASJON"] = 7] = "KRAVSPESIFIKASJON";
})(ProjectPhaseType || (ProjectPhaseType = {}));
export const documentPackageSchema = z
    .object({
    id: z.string().uuid(),
    name: z.string(),
    project: projectSchema.innerType().shape.id,
    project_phase: projectPhaseSchema.innerType().shape.id,
    external_famac_building_id: z.string().uuid().nullable(),
    language: languageSchema.optional(),
    created_by: z.string().uuid().nullable(),
    published: z.boolean(),
    needClassification: z.boolean(),
})
    .strict()
    .transform(mapToCamelCase);
// GET /projects/:projectId/packages/
export const getPackagesResponseSchema = z.array(documentPackageSchema).transform(mapToCamelCase);
// POST /projects/:projectId/packages/
export const createPackageResponseSchema = documentPackageSchema;
