import { useServiceName } from '@consigli/hooks';
import { ServiceName } from '@consigli/types';

import { ComparisonWizard } from './comparison/comparison-wizard';
import { StructuralForm } from './components/user-input';
import { InsightWizard } from './insight/insight-wizard';
import { OptimizationWizard } from './optimization/optimization-wizard';
import { StructuralWizard } from './structural/structural-wizard';

export const WizardWrapper = () => {
  const serviceName = useServiceName();
  if (
    serviceName === ServiceName.PLANT_ROOM ||
    serviceName === ServiceName.CEILING_PLAN ||
    serviceName === ServiceName.FLOOR_PLAN ||
    serviceName === ServiceName.SPACE_ANALYSIS ||
    serviceName === ServiceName.PARKING ||
    serviceName === ServiceName.REPORTS
  ) {
    return <OptimizationWizard />;
  } else if (serviceName === ServiceName.TENANT_REQUIREMENTS || serviceName === ServiceName.OFFER) {
    return <ComparisonWizard />;
  } else if (serviceName === ServiceName.STRUCTURAL) {
    return <StructuralWizard userInput={<StructuralForm></StructuralForm>} />;
  } else {
    return <InsightWizard></InsightWizard>;
  }
};
