import { ConflictCategory } from '@consigli/types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccordionContext } from '@/contexts/use-accordion-context';
import { SingleOpenAccordion } from '@/organisms/accordion/accordion-single-open';
import { AccordionWrapper } from '@/organisms/accordion/accordion-wrapper';

import { FindingsAccordionListItem } from './findings-accordion-list-item';
import { useFindingsContext } from './findings-context';
import { ConflictTypeCountCategory } from './findings-sidebar';
import { useReset } from './use-reset';

interface FindingsHeaderProps {
  title: string;
  conflictTypeCategory: ConflictTypeCountCategory;
}

const FindingsHeader = (props: FindingsHeaderProps) => {
  const { title, conflictTypeCategory } = props;

  return (
    <>
      <span className="px-2">{title}</span>
      <span>({conflictTypeCategory.count})</span>
    </>
  );
};

interface FindingsAccordionProps {
  conflictTypeCounts: Record<string, ConflictTypeCountCategory>;
}

export const FindingsAccordion = (props: FindingsAccordionProps) => {
  const { conflictTypeCounts } = props;
  const { t } = useTranslation();
  const { open, toggle } = useAccordionContext();
  const categoriesKeys = useMemo(() => Object.keys(conflictTypeCounts), [conflictTypeCounts]);
  const {
    selectedConflictCategory,
    setSelectedConflictCategory,
    selectedConflictType,
    setSelectedConflictType,
  } = useFindingsContext();
  const { reset } = useReset();

  const preferredOrder = useMemo(() => ['MISSING_DOCUMENTATION', 'RISK', 'INSIGHT'], []);
  const customCompare = useCallback(
    (a: string, b: string) => {
      return preferredOrder.indexOf(a) - preferredOrder.indexOf(b);
    },
    [preferredOrder],
  );
  const sortedKeys = useMemo(
    () => categoriesKeys.slice().sort(customCompare),
    [categoriesKeys, customCompare],
  );

  const handleCategoryClick = useCallback(
    (index: number, category: ConflictCategory) => {
      if (index === open) {
        setSelectedConflictCategory(undefined);
      } else {
        setSelectedConflictCategory(category);
      }
      setSelectedConflictType(undefined);
    },
    [open, setSelectedConflictType, setSelectedConflictCategory],
  );

  const handleConflictTypeClick = useCallback(
    (conflictType: string | undefined) => {
      setSelectedConflictType(conflictType);
    },
    [setSelectedConflictType],
  );

  const ignore = useRef(false);
  useEffect(() => {
    if (!ignore.current) {
      ignore.current = true;
      if (selectedConflictCategory && selectedConflictType) {
        toggle(sortedKeys.indexOf(selectedConflictCategory));
        handleConflictTypeClick(selectedConflictType);
      } else if (selectedConflictCategory) {
        toggle(sortedKeys.indexOf(selectedConflictCategory));
        handleCategoryClick(0, selectedConflictCategory);
      }
    }
  }, [
    sortedKeys,
    handleCategoryClick,
    toggle,
    selectedConflictCategory,
    selectedConflictType,
    handleConflictTypeClick,
  ]);

  return (
    <>
      <AccordionWrapper>
        {sortedKeys.map((conflictCategory: string, index: number) => (
          <SingleOpenAccordion
            key={conflictCategory}
            index={index}
            onClick={() => {
              handleCategoryClick(index, conflictCategory as ConflictCategory);
              reset(1);
            }}
            header={() => (
              <FindingsHeader
                title={t(`dashboard.conflict-category.${conflictCategory}`)}
                conflictTypeCategory={conflictTypeCounts[conflictCategory]}
              />
            )}
          >
            {Object.entries(conflictTypeCounts[conflictCategory].subcounts).map(
              ([conflictType, conflictTypeCount]) => (
                <FindingsAccordionListItem
                  key={conflictType}
                  conflictType={conflictType}
                  conflictTypeCount={conflictTypeCount}
                  onItemClick={() => {
                    handleConflictTypeClick(conflictType);
                    reset(1);
                  }}
                  isActive={conflictType === selectedConflictType}
                />
              ),
            )}
          </SingleOpenAccordion>
        ))}
      </AccordionWrapper>
    </>
  );
};
