import { Document } from '@consigli/types';
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

export enum ViewerMode {
  Closed,
  CommentPreview,
  DocumentPreview,
  ReturnToDocumentPreview,
  DocumentFullscreen,
}

type ViewerContextTypes = {
  document: Document | null;
  setDocument: Dispatch<SetStateAction<Document | null>>;
  mode: ViewerMode;
  setMode: Dispatch<SetStateAction<ViewerMode>>;
};

const ViewerContext = createContext<ViewerContextTypes | null>(null);

export const useViewerContext = () => {
  const ctx = useContext(ViewerContext);
  if (ctx === null) {
    throw new Error(`Called useViewerContext without ViewerProvider in tree!`);
  }
  return ctx;
};

export const ViewerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [document, setDocument] = useState<Document | null>(null);
  const [mode, setMode] = useState<ViewerMode>(ViewerMode.Closed);

  return (
    <ViewerContext.Provider
      value={{
        document,
        setDocument,
        mode,
        setMode,
      }}
    >
      {children}
    </ViewerContext.Provider>
  );
};
