import { FileUpload } from '@consigli/facade';
import { useGetLayoutOptimizationsQuery, useProjectId, useServiceName } from '@consigli/hooks';
import { LayoutType, ServiceName } from '@consigli/types';
import { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CeilingHeight } from '../components/ceiling-height';
import { IterationName } from '../components/iteration-name';
import { RequiredOptimizationFiles } from '../components/required-file-types';
import { useFetchValidateName } from '../components/user-input';
import { WizardAnalyzeButton } from '../components/wizard-analyze-button';
import { WizardLayout } from '../components/wizard-layout';
import { useCreatePackageUtil } from '../hooks/useCreatePackage';
import { useUploadProgress } from '../hooks/useUploadProgress';

export const getLayoutTypeFromServiceName = (serviceName: ServiceName) => {
  if (serviceName === 'floor-plan') {
    return LayoutType.FLOOR_PLAN;
  } else if (serviceName === 'space-analysis') {
    return LayoutType.SPACE_ANALYSIS;
  } else if (serviceName === 'ceiling-plan') {
    return LayoutType.CEILING_PLAN;
  } else if (serviceName === 'parking') {
    return LayoutType.PARKING;
  } else if (serviceName === 'reports') {
    return LayoutType.REPORTS;
  } else {
    return LayoutType.PLANT_ROOM;
  }
};

export const OptimizationWizard = () => {
  const [formData] = useState<FieldValues>({
    iterationName: '',
    inputType: '',
    ceilingHeight: '',
  });
  const { t } = useTranslation();
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const projectId = useProjectId();
  const serviceName = useServiceName();
  const [isProcessing, setIsProcessing] = useState(false);
  const [showMessagePanel, setShowMessagePanel] = useState(true);
  const [targetCounter, setTargetCounter] = useState(0);
  const [currentCounter, setCurrentCounter] = useState(0);

  const { onAnalyze: onProcess } = useCreatePackageUtil({ setCurrentCounter, setTargetCounter });
  const { validateName } = useFetchValidateName(useGetLayoutOptimizationsQuery, {
    projectId,
  });

  const methods = useForm<FieldValues>({
    defaultValues: formData,
  });
  const { getValues, handleSubmit } = methods;

  const { isUploading, progress } = useUploadProgress(currentCounter, targetCounter);

  const onAnalyze = async () => {
    setIsProcessing(true);
    await onProcess(localFiles, getValues(), getLayoutTypeFromServiceName(serviceName));
    setIsProcessing(false);
  };
  const FormComponent = (
    <>
      <FormProvider {...methods}>
        <form className="w-full" onSubmit={handleSubmit(onAnalyze)}>
          <div className="flex flex-col" translate="no">
            <IterationName
              validateName={validateName}
              label={t('package-creation.description')}
              errorMessage={`${t('package-creation.description-required')}*`}
            />
            {serviceName === ServiceName.PLANT_ROOM && <CeilingHeight />}
          </div>
          <FileUpload
            files={localFiles}
            setFiles={setLocalFiles}
            dropAreaLabel={t('document-upload.drag-n-drop-here')}
            dropAreaClassName="mt-4 h-48 w-full"
            fileButtonLabel={t('document-upload.browse-files')}
            folderButtonLabel={t('document-upload.browse-folders')}
            heading={t('package-creation.upload-files')}
            uploadListLabel={t('document-upload.files')}
            showMessagePanel={showMessagePanel}
            setShowMessagePanel={setShowMessagePanel}
          >
            <div className="fc-w-full">
              <WizardAnalyzeButton localFiles={localFiles} type="submit"></WizardAnalyzeButton>
            </div>
          </FileUpload>
        </form>
      </FormProvider>
    </>
  );

  const messageHeader = showMessagePanel ? (
    <RequiredOptimizationFiles
      setShowMessagePanel={setShowMessagePanel}
    ></RequiredOptimizationFiles>
  ) : (
    <></>
  );

  return (
    <WizardLayout
      stepsRenderer={FormComponent}
      isProcessing={isProcessing}
      messageHeader={messageHeader}
      isUploading={isUploading}
      progress={progress}
    />
  );
};
