import { useTranslation } from 'react-i18next';

export const OrgAdmin = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-6">
        <label
          htmlFor="projectPermissions"
          className="fc-text-base fc-font-semibold fc-mb-2 text-day-neutral-subtle"
        >
          {t('invite-user.permissions')}
        </label>
        <div id="projectPermissions" className="font-semibold pt-2 text-base text-day-neutral-dark">
          {t('invite-user.admins-can-create-projects')}
        </div>
      </div>
      <div className="mb-6">
        <label
          htmlFor="projectAccess"
          className="fc-text-base fc-font-semibold fc-mb-2 text-day-neutral-subtle"
        >
          {t('invite-user.project-access')}
        </label>
        <div id={'projectAccess'} className="font-semibold pt-2 text-base text-day-neutral-dark">
          {t('invite-user.projects-available-for-admins')}
        </div>
      </div>
    </>
  );
};
