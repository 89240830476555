import { Button } from '@consigli/facade';
import clsx from 'clsx';
import { FC } from 'react';

import { DropdownElement } from '@/util/interfaces';

interface PageHeaderDropdownProps {
  header?: string;
  elements: DropdownElement[];
  className?: string;
}

export const PageHeaderDropdown: FC<PageHeaderDropdownProps> = ({
  header,
  elements,
  className,
}) => (
  <div
    className={clsx(
      'top-[100%] absolute z-[60] flex flex-col items-start bg-day-light-4 border rounded-sm select-none',
      className,
    )}
    role="menu"
  >
    {header && <div className="p-2 w-full text-left font-medium border-b">{header}</div>}
    {elements.map((element) => (
      <Button
        key={element.text}
        tertiary
        onClick={element.onClick}
        className={clsx('p-2 w-full flex text-left hover:bg-day-light-2', element.className)}
      >
        {element.text && <span className="flex-1">{element.text}</span>}
        {element.icon && element.icon}
      </Button>
    ))}
  </div>
);
