import { Spinner, Button, Position } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useProject,
  useLazyGetBlobsQuery,
  useServiceName,
  useDownloadFilesAsZip,
} from '@consigli/hooks';
import { convertBlobToDocument } from '@consigli/types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineFileDownload, MdOutlineFileUpload } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { AccordionProvider } from '@/contexts/use-accordion-context';
import { useFileTreeContext } from '@/contexts/use-file-tree-context';
import { WorkspaceSidebar } from '@/organisms/sidebar/workspace-sidebar';
import { CategoryAccordion } from '@/pages/folders/sidebar/category-accordion';
import { Route } from '@/routes';

import { ExportFamacButton } from './export-famac-button';
import { TreeEntry } from '../util/document-list-to-tree';

export const FoldersSidebar = () => {
  const projectId = useProjectId();
  const packageId = usePackageId();
  const serviceName = useServiceName();
  const navigate = useNavigate();

  const { tree } = useFileTreeContext();
  const { project } = useProject(projectId);
  const { t } = useTranslation();

  const [getBlobs, { isLoading }] = useLazyGetBlobsQuery();
  const categories = useMemo(() => [...tree?.children.entries()], [tree]);

  const { downloadFileZip, isDownloading } = useDownloadFilesAsZip(project?.name ?? 'files', true);

  const handleDownload = useCallback(async () => {
    const files = await getBlobs({ projectId, packageId, page: 'all' }).unwrap();
    const documents = files.results.map((file) => convertBlobToDocument(file));
    downloadFileZip(documents);
  }, [getBlobs, downloadFileZip, projectId, packageId]);

  return (
    <div className="flex relative">
      <WorkspaceSidebar>
        <AccordionProvider>
          <CategoryAccordion categories={categories as [string, TreeEntry][]}></CategoryAccordion>
        </AccordionProvider>
        <Button
          rounded
          secondary
          className="w-full font-medium text-sm"
          onClick={handleDownload}
          disabled={isDownloading || isLoading}
          icon={
            isDownloading || isLoading ? () => <Spinner size="xsmall" /> : MdOutlineFileDownload
          }
          iconPosition={Position.RIGHT}
        >
          {t('document-list.download-all')}
        </Button>
        <Button
          rounded
          secondary
          className="w-full font-medium text-sm mt-2"
          onClick={() =>
            navigate(
              `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${packageId}/${Route.UPLOAD}`,
            )
          }
          icon={MdOutlineFileUpload}
          iconPosition={Position.RIGHT}
        >
          {t('document-list.upload-documents')}
        </Button>
        <ExportFamacButton />
      </WorkspaceSidebar>
    </div>
  );
};
