import { useMemo, useState } from 'react';
/**
 * Hook to manage the selected card.
 *
 * @param defaultValue - the default value for the selected card
 * @returns an object with the selected card and a function to set the selected card
 */
export const useSelectedCard = (defaultValue) => {
    const [selectedCard, setSelectedCard] = useState(defaultValue);
    return useMemo(() => ({
        selectedCard,
        setSelectedCard,
    }), [selectedCard, setSelectedCard]);
};
