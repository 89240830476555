import { type FC } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

interface ImageViewerProps {
  readUrl: string;
  fileName: string;
}

export const ImageViewer: FC<ImageViewerProps> = ({ readUrl, fileName }) => {
  return (
    <TransformWrapper wheel={{ smoothStep: 0.01 }} centerOnInit>
      <TransformComponent wrapperStyle={{ height: '100%', width: '100%' }}>
        <img src={readUrl} alt={fileName} />
      </TransformComponent>
    </TransformWrapper>
  );
};
