import { Button, Position, Spinner } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useLazyGetParsedDataByGuidAndContentQuery,
} from '@consigli/hooks';
import { useCallback, useEffect, useState } from 'react';
import { Highlight } from 'react-highlighter-ts';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';

type BlobParsedData = {
  id: number;
  content: string;
};

interface DocumentCardParsedDataProps {
  initialParsedData: BlobParsedData[] | undefined;
  remainingOccurrences: number | undefined;
  documentId: string;
  searchText?: string;
}

export const DocumentCardParsedData = ({
  initialParsedData,
  remainingOccurrences,
  documentId,
  searchText,
}: DocumentCardParsedDataProps) => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const [showAllParsedData, setShowAllParsedData] = useState(false);
  const [parsedData, setParsedData] = useState<BlobParsedData[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchAllParsedData] = useLazyGetParsedDataByGuidAndContentQuery();

  useEffect(() => {
    // whenever search result changes, reset parsed data
    setParsedData(initialParsedData);
  }, [initialParsedData]);

  const handleUpdateParsedData = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsLoading(true);
      event.stopPropagation();
      if (showAllParsedData) {
        setParsedData((prev) => prev?.slice(0, 5));
      } else {
        const data = await fetchAllParsedData({
          projectId: projectId,
          packageId: packageId,
          guid: documentId,
          content: searchText ? searchText : '',
        }).unwrap();
        setParsedData(
          data.map(
            (parsedData) => ({ id: parsedData.id, content: parsedData.content }) as BlobParsedData,
          ),
        );
      }
      setShowAllParsedData((prev) => !prev);
      setIsLoading(false);
    },
    [documentId, fetchAllParsedData, packageId, projectId, searchText, showAllParsedData],
  );

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col mr-2">
          {parsedData &&
            parsedData.map((parsedData) => (
              <div
                key={parsedData.id}
                className="text-xs mt-2 whitespace-normal overflow-ellipsis overflow-hidden"
              >
                {searchText && searchText.length >= 3 ? (
                  // @ts-expect-error - As event handlers which don't exist for React are required
                  <Highlight placeholder="" search={searchText}>
                    {parsedData.content}
                  </Highlight>
                ) : (
                  parsedData.content
                )}
              </div>
            ))}
          {remainingOccurrences && remainingOccurrences > 0 ? (
            <Button
              secondary
              onClick={(event) => {
                handleUpdateParsedData(event);
              }}
              icon={showAllParsedData ? IoIosArrowUp : IoIosArrowDown}
              iconPosition={Position.RIGHT}
              className="w-[220px] mt-2"
              rounded
            >
              {`${remainingOccurrences} ${t('document-list.show-more-occurrences')}`}
            </Button>
          ) : null}
        </div>
      )}
    </>
  );
};
