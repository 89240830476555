import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/components/layouts/workspace-layout/workspace-layout';

import { Findings } from './findings';
import { FindingsProvider } from './findings-context';

export function RiskAssessment() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb label={t('servicetabs.riskassessment')} />
      <WorkspaceLayout>
        <FindingsProvider>
          <Findings />
        </FindingsProvider>
      </WorkspaceLayout>
    </>
  );
}
