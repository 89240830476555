import { ServiceCategory } from '@consigli/types';
import { useParams } from 'react-router-dom';
export const useServiceCategory = () => {
    const params = useParams();
    if (params['packageId']) {
        return ServiceCategory.INSIGHT;
    }
    else if (params['layoutId']) {
        return ServiceCategory.LAYOUT_OPTIMIZATION;
    }
    else if (params['structuralId']) {
        return ServiceCategory.STRUCTURAL_ENGINEERING;
    }
    throw new Error(`useServiceCategory called with no valid ID found in the URL`);
};
