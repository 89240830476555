import { Button, Spinner } from '@consigli/facade';
import { useDownloadFile } from '@consigli/hooks';
import { Document } from '@consigli/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineFileDownload } from 'react-icons/md';

export const DownloadFileButton: FC<{ document: Document }> = ({ document }) => {
  const { t } = useTranslation();

  const { downloadFile, isDownloading } = useDownloadFile(document);

  return (
    <Button
      secondary
      rounded
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        downloadFile();
      }}
      disabled={isDownloading}
      title={t('document-list.download')}
      icon={isDownloading ? () => <Spinner size="xsmall" /> : MdOutlineFileDownload}
      iconSize={23}
    />
  );
};
