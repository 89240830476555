import { Project, ProjectConstructionType } from '@consigli/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ArenaImage from '@/assets/images/construction-types/arena.png';
import BridgeImage from '@/assets/images/construction-types/bridge.png';
import EducationBuildingImage from '@/assets/images/construction-types/eduation-building.png';
import KindergartenImage from '@/assets/images/construction-types/kindergarten.png';
import OfficeBuildingImage from '@/assets/images/construction-types/office-building.png';
import OtherBuildingImage from '@/assets/images/construction-types/other-building.png';
import OtherInfraStructureImage from '@/assets/images/construction-types/other-infrastructure.png';
import ResidentialBuildingImage from '@/assets/images/construction-types/residential-building.png';
import RoadImage from '@/assets/images/construction-types/road.png';
import RoundAboutImage from '@/assets/images/construction-types/roundabout.png';
import { CardGrid } from '@/layouts/card-grid';
import { ProjectCard } from '@/organisms/project-card';

interface ProjectCardsProps {
  projects: Project[];
  searchText: string;
}

export const ProjectCards = (props: ProjectCardsProps) => {
  const { projects, searchText } = props;
  const { t } = useTranslation();

  const getIcon = useCallback((construction_type: ProjectConstructionType[]): string => {
    switch (construction_type[0]) {
      case ProjectConstructionType.OFFICE_BUILDING:
        return OfficeBuildingImage;
      case ProjectConstructionType.RESIDENTIAL_BUILDING:
        return ResidentialBuildingImage;
      case ProjectConstructionType.EDUCATION_BUILDING:
        return EducationBuildingImage;
      case ProjectConstructionType.ROAD:
        return RoadImage;
      case ProjectConstructionType.KINDERGARTEN:
        return KindergartenImage;
      case ProjectConstructionType.ROUNDABOUT:
        return RoundAboutImage;
      case ProjectConstructionType.OTHER_INFRASTRUCTURE:
        return OtherInfraStructureImage;
      case ProjectConstructionType.OTHER_BUILDING:
        return OtherBuildingImage;
      case ProjectConstructionType.BRIDGE:
        return BridgeImage;
      case ProjectConstructionType.ARENA:
        return ArenaImage;
      default:
        return OfficeBuildingImage;
    }
  }, []);

  if (!projects.length && searchText) {
    return (
      <div className="m-8 text-center text-lg">
        {t('project-list.no-projects-match-current-search')}
      </div>
    );
  }
  if (!projects.length) {
    return <div className="m-8 text-center text-lg">{t('project-list.no-projects-found')}</div>;
  }
  return (
    <CardGrid>
      {projects.map((project) => (
        <ProjectCard key={project.id} project={project} icon={getIcon(project.constructionTypes)} />
      ))}
    </CardGrid>
  );
};
