import { useMemo } from 'react';
import { usePackages } from './use-packages';
/**
 * Returns the package from the given projectId, serviceId and packageId.
 * Get the package from the Redux store (and load from API if necessary)
 *
 * @param projectId the project id that the package belongs to.
 * @param serviceId the service id that the package belongs to.
 * @param packageId the package id to fetch.
 *
 * @returns a document package
 */
export const usePackageByServiceIdAndPackageId = (projectId, serviceId, packageId) => {
    const { packages = [], isLoading, isError, error } = usePackages(projectId, serviceId);
    const pkg = useMemo(() => packages.find((p) => p.id === packageId), [packageId, packages]);
    return useMemo(() => ({
        pkg,
        isLoading,
        isError,
        error,
    }), [error, isError, isLoading, pkg]);
};
