import { Button } from '@consigli/facade';
import { Document, DocumentType } from '@consigli/types';
import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BsLayoutSidebarReverse } from 'react-icons/bs';

import { ViewerMode, useViewerContext } from '@/contexts/use-viewer-context';
import { useViewer } from '@/hooks/use-viewer';

import { FindingsController } from './controller';
import { DoublePdfViewer } from '../../double-pdf';
import { SinglePdfViewer } from '../../single-pdf';

export const isDocumentDoubleViewerCapable = (
  document: Document,
): document is Document & { refBlobId: string; refContent: string } =>
  document.type === DocumentType.FINDING &&
  document.refBlobId != null &&
  document.refContent != null;

interface FindingsFullscreenContainerProps {
  itemsOnPage: number;
}

export const FindingsFullscreenContainer: FC<FindingsFullscreenContainerProps> = ({
  itemsOnPage,
}) => {
  const { openDocumentViewer, close } = useViewer();
  const { document } = useViewerContext();
  const isDoubleFinding = document && isDocumentDoubleViewerCapable(document);
  const { t } = useTranslation();

  const PDFComponent = useCallback(() => {
    if (isDoubleFinding) {
      return <DoublePdfViewer document={document} isPreview={false} />;
    } else if (document) {
      return <SinglePdfViewer document={document} isPreview={false} />;
    } else {
      return (
        <div className="text-white text-center pt-[50%]">
          {t('document-viewer.no-finding-selected')}
        </div>
      );
    }
  }, [document, isDoubleFinding, t]);

  const setDocumentViewerModeToPreview = useCallback(() => {
    if (document) {
      openDocumentViewer(document, ViewerMode.ReturnToDocumentPreview);
    } else close();
  }, [close, document, openDocumentViewer]);

  return (
    <div className="flex flex-row z-[50] ml-auto top-14 fixed cursor-default w-screen h-screen bg-day-dark-1 justify-center">
      <div
        className={clsx(
          'flex flex-row justify-center h-[calc(100vh_-_7rem)]',
          isDoubleFinding ? 'w-3/4' : 'w-3/5',
        )}
      >
        <PDFComponent />
      </div>
      <div className={isDoubleFinding ? 'flex flex-col w-1/4' : 'w-2/5'}>
        <div className="flex justify-end text-white h-16">
          <Button
            primary
            className={clsx('mt-5 py-2 px-3 border-none mr-4')}
            onClick={setDocumentViewerModeToPreview}
          >
            {t('riskassessment.buttons.preview')}
            <BsLayoutSidebarReverse />
          </Button>
          <Button
            primary
            className={clsx('mt-5 py-2 px-3 border-none', isDoubleFinding ? 'mr-4' : 'mr-10')}
            onClick={close}
          >
            {t('riskassessment.buttons.close')}
            <div className="fa-solid fa-x"></div>
          </Button>
        </div>
        <div>
          <FindingsController itemsOnPage={itemsOnPage} />
        </div>
      </div>
    </div>
  );
};
