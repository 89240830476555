import {
  useGetLayoutOptimizationsQuery,
  useLayoutId,
  useProjectId,
  useServiceName,
} from '@consigli/hooks';

import { PackageList } from '@/organisms/workspace-tabs/package-list';

import { getLayoutTypeFromServiceName } from '../package-creation/optimization/optimization-wizard';

export const LayoutPackage = () => {
  const projectId = useProjectId();
  const layoutId = useLayoutId();
  const serviceName = useServiceName();

  const { data: layouts = [] } = useGetLayoutOptimizationsQuery({
    projectId,
    type: getLayoutTypeFromServiceName(serviceName),
  });

  return <PackageList packages={layouts} id={layoutId} />;
};
