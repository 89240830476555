import { useState, useCallback, useMemo, useEffect } from 'react';
export const useToggle = (value) => {
    const [expanded, setExpanded] = useState(value);
    const toggle = useCallback(() => {
        setExpanded((prevValue) => !prevValue);
    }, []);
    useEffect(() => {
        setExpanded(value);
    }, [value]);
    const values = useMemo(() => ({
        expanded,
        toggle,
    }), [expanded, toggle]);
    return values;
};
