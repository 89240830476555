import { Checkbox } from '@consigli/facade';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface UploadIndexFilesProps {
  uploadIndexFiles: boolean;
  setUploadIndexFiles: (value: boolean) => void;
}

export const UploadIndexFiles = ({
  uploadIndexFiles,
  setUploadIndexFiles,
}: UploadIndexFilesProps) => {
  const { control } = useFormContext<FieldValues>();
  const { t } = useTranslation();

  return (
    <div className="mt-6">
      <Controller
        name="uploadIndexFiles"
        control={control}
        render={({ field }) => (
          <div className="flex">
            <Checkbox
              id="uploadIndexFiles"
              label={t('package-creation.wish-to-upload-index-files')}
              onChange={(event) => {
                field.onChange(event.target.checked);
                setUploadIndexFiles(event.target.checked);
              }}
              value={uploadIndexFiles?.toString()}
              checked={uploadIndexFiles}
            ></Checkbox>
          </div>
        )}
      />
    </div>
  );
};
