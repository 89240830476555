import { useMemo } from 'react';
import { useGetFindingsQuery } from '../api';
/**
 * Returns an object with a findings response from the API,
 * a loading state, a fetching state, an error state and an error object.
 * @param params the object with the parameters to fetch findings.
 *
 * The param object contains the following required properties:
 * @param object with the parameters to fetch findings.
 * @param project_id the project id that the findings belong to.
 * @param package_id the page to fetch. (pagination)
 * @param page the page to fetch. (pagination)
 *
 * There is also other optional parameters for the param object:
 * @param page_size the number of findings to fetch per page.
 * @param action_type
 * @param conflict_type
 * @param conflict_category
 * @param search
 * @param language
 * @param processor_name
 *
 * @returns an object with findings response from API, loading state, fetching state, error state and error object.
 */
export const useFindings = (params) => {
    const { data: findingsResponse, isLoading, isFetching, isError, error, } = useGetFindingsQuery(params);
    return useMemo(() => {
        const paginatedCount = findingsResponse?.count || 0;
        const findings = findingsResponse?.results || [];
        const next = findingsResponse?.next;
        const previous = findingsResponse?.previous;
        return {
            findings,
            paginatedCount,
            next,
            previous,
            isLoading,
            isFetching,
            isError,
            error,
        };
    }, [findingsResponse, isLoading, isFetching, isError, error]);
};
