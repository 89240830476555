import { jwtDecode } from 'jwt-decode';
import { pkce } from './pkce';
import { AUTH_STATE_CHANGE_EVENT } from './react-context';
const PKCE_KEY = 'auth.pkce';
const OAUTH_SESSION = 'auth.oauth.session';
const PREV_PATH_KEY = 'auth.prevPath';
/** Decode the Azure AD B2C tokens */
export const decodeOAuthData = (json) => {
    return {
        idToken: {
            token: json.id_token,
            expires: Date.now() + Number(json.id_token_expires_in) * 1000,
            payload: jwtDecode(json.id_token),
        },
        refreshToken: {
            token: json.refresh_token,
            expires: Date.now() + Number(json.refresh_token_expires_in) * 1000,
        },
    };
};
/** Store the path before authentication with Azure */
export const savePathBeforeAuth = (path) => {
    sessionStorage.setItem(PREV_PATH_KEY, path);
};
/** Get the path that was saved before authentication with Azure */
export const getSavedPath = () => {
    const path = sessionStorage.getItem(PREV_PATH_KEY);
    if (!path) {
        return null;
    }
    return path;
};
export const getAuthorizationCode = async (config, scopes = ['openid']) => {
    // https://tools.ietf.org/html/rfc7636#appendix-A
    const codePair = await pkce();
    /**
     * Store the code challenge in local storage so we can provide it
     * when we want to request the tokens */
    sessionStorage.setItem(PKCE_KEY, JSON.stringify(codePair));
    const scopeString = encodeURIComponent(scopes.join(','));
    const urlParams = new URLSearchParams(window.location.search);
    const loginHint = urlParams.get('login_hint');
    const path = window.location.pathname;
    savePathBeforeAuth(path);
    const url = `${config.authorizeEndpoint}?redirect_uri=${encodeURIComponent(config.redirectUri)}&response_type=code&client_id=${config.clientId}&scope=${scopeString}&code_challenge=${codePair.challenge}&code_challenge_method=S256${loginHint ? `&login_hint=${loginHint}` : ''}`;
    document.location.assign(url);
};
/** Retreive the JWT tokens from an authorization code received from the OAuth server */
export const getTokenFromCode = async (config, code) => {
    const pkceString = sessionStorage.getItem(PKCE_KEY);
    if (!pkceString) {
        const error = 'PKCE challenge not found in session storage';
        try {
            window.location.replace('/');
        }
        catch (redirectError) {
            console.error('Error redirecting to the root URL:', redirectError);
        }
        throw new Error(error);
    }
    const pk = JSON.parse(pkceString);
    const payload = `code=${code}&grant_type=authorization_code&client_id=${config.clientId}&code_verifier=${pk.verifier}`;
    const result = await fetch(config.tokenEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload,
    });
    const json = await result.json();
    return decodeOAuthData(json);
};
/** Retreive the JWT tokens from an authorization code received from the OAuth server */
export const getTokenFromRefreshToken = async (config, refreshToken) => {
    const payload = `refresh_token=${refreshToken}&grant_type=refresh_token&client_id=${encodeURIComponent(config.clientId)}`;
    const result = await fetch(config.tokenEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload,
    });
    const json = await result.json();
    return decodeOAuthData(json);
};
export const getSessionFromStorage = () => {
    const sessionString = sessionStorage.getItem(OAUTH_SESSION);
    const session = sessionString ? JSON.parse(sessionString) : null;
    return session;
};
export const logout = async (config) => {
    /** Remove JWT from session storage */
    sessionStorage.removeItem(OAUTH_SESSION);
    /*redirect to logout endpoint*/
    document.location.href = `${config.logoutEndpoint}?post_logout_redirect_uri=${config.logoutUri}`;
};
export const setSessionInStorage = (auth) => {
    sessionStorage.setItem(OAUTH_SESSION, JSON.stringify(auth));
    window.dispatchEvent(AUTH_STATE_CHANGE_EVENT);
};
//Method for checking wether an idToken or a refreshToken is expired
const isTokenExpired = (expirationTime) => {
    return Date.now() >= expirationTime;
};
/** Refresh the OAuth session from the refresh token. If the token has expired, redirect to login */
export const refreshOAuthSession = async (config, auth) => {
    if (!auth) {
        return null;
    }
    const refreshToken = auth.refreshToken.token;
    // Refresh token has expired, redirect to login
    if (isTokenExpired(auth.refreshToken.expires)) {
        logout(config);
    }
    const newConfig = await getTokenFromRefreshToken(config, refreshToken);
    if (!newConfig) {
        return null;
    }
    /** Retain the refreshToken from the previous session */
    newConfig.refreshToken = auth.refreshToken;
    setSessionInStorage(newConfig);
    return newConfig;
};
/**
 * Get the session. If refreshExpired is set to true (default), try to refresh
 * expired sessions by using the refreshToken.
 */
export const getSession = async (config) => {
    let auth = getSessionFromStorage();
    if (!auth) {
        await getAuthorizationCode(config);
        return null;
    }
    // Check if the session needs to be refreshed. expires is the time (in milliseconds) that the token expires
    if (isTokenExpired(auth.idToken.expires)) {
        auth = await refreshOAuthSession(config, auth);
    }
    return auth;
};
/** Check if the client has a valid JWT token */
export const hasValidSession = () => {
    const session = getSessionFromStorage();
    if (!session) {
        return false;
    }
    return !isTokenExpired(session.idToken.expires) && !isTokenExpired(session.refreshToken.expires);
};
