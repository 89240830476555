import { CustomSelect, Label, isOptionType, type OptionType } from '@consigli/facade';
import { Country } from '@consigli/types';
import { useMemo, type FC, useCallback } from 'react';
import { type Control, ControllerRenderProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProjectInputs } from '@/util/types';

import type { MultiValue, SingleValue } from 'react-select';

interface ProjectCountryProps {
  control: Control<ProjectInputs>;
}

export const ProjectCountry: FC<ProjectCountryProps> = ({ control }) => {
  const { t } = useTranslation();

  const countryChoices: OptionType[] = useMemo(
    () => [
      {
        label: t('country.norway'),
        value: Country.NORWAY,
      },
      {
        label: t('country.sweden'),
        value: Country.SWEDEN,
      },
      {
        label: t('country.denmark'),
        value: Country.DENMARK,
      },
      {
        label: t('country.iceland'),
        value: Country.ICELAND,
      },
      {
        label: t('country.finland'),
        value: Country.FINLAND,
      },
      {
        label: t('country.japan'),
        value: Country.JAPAN,
      },
      {
        label: t('country.france'),
        value: Country.FRANCE,
      },
      {
        label: t('country.netherlands'),
        value: Country.NETHERLANDS,
      },
      {
        label: t('country.united-kingdom'),
        value: Country.UNITED_KINGDOM,
      },
      {
        label: t('country.united-states'),
        value: Country.UNITED_STATES,
      },
      {
        label: t('country.canada'),
        value: Country.CANADA,
      },
      {
        label: t('country.hungary'),
        value: Country.HUNGARY,
      },
      {
        label: t('country.germany'),
        value: Country.GERMANY,
      },
      {
        label: t('country.vietnam'),
        value: Country.VIETNAM,
      },
    ],
    [t],
  );

  const { field, fieldState } = useController({
    name: 'country',
    control,
    rules: { required: `${t('project-creation.country-required')}*` },
  });

  const onCountryChange = useCallback(
    (
      newValue: SingleValue<OptionType> | MultiValue<OptionType>,
      field: ControllerRenderProps<ProjectInputs, 'country'>,
    ): void => {
      if (newValue !== null && isOptionType(newValue)) {
        field.onChange(newValue && newValue.value);
      } else {
        throw new Error('Country is not number!');
      }
    },
    [],
  );

  return (
    <Label text={t('project-creation.country-label')}>
      <CustomSelect
        onBlur={field.onBlur}
        onChange={(newValue: SingleValue<OptionType> | MultiValue<OptionType>): void => {
          onCountryChange(newValue, field);
        }}
        placeholder={t('project-creation.country-placeholder')}
        options={countryChoices}
        value={countryChoices.find((option: OptionType) => option.value === field.value)}
      />
      {fieldState.invalid && (
        <div className={'text-sm my-1 text-error-dark'}>{fieldState.error?.message}</div>
      )}
    </Label>
  );
};
