import { Button, UseStepContext } from '@consigli/facade';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PACKAGE_TYPE, useCompareContext } from './use-compare-packages';

interface CompareButtonsProps {
  context: UseStepContext;
}

export const CompareButtons = ({ context }: CompareButtonsProps) => {
  const { t } = useTranslation();
  const { previous, hasPrevious } = context();
  const { getValues } = useFormContext<FieldValues>();
  const {
    setSecondaryData,
    secondaryFiles,
    selectedComparisonPackage,
    packageType,
    compareServiceName,
  } = useCompareContext();

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-4 mt-4">
        <Button
          rounded
          primary
          className="w-full"
          onClick={() => {
            setSecondaryData(getValues());
            hasPrevious && previous();
          }}
        >
          {t('package-creation.back')}
        </Button>
        <Button
          rounded
          primary
          type="submit"
          className="w-full"
          disabled={
            !compareServiceName ||
            (packageType === PACKAGE_TYPE.NEW &&
              (!getValues('secondaryPackageName') || secondaryFiles.length == 0)) ||
            (packageType === PACKAGE_TYPE.EXISTING && !selectedComparisonPackage)
          }
        >
          {t('package-creation.compare-now')}
        </Button>
      </div>
    </div>
  );
};
