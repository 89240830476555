import { useMemo } from 'react';
import { useGetPackagesByServiceIdQuery } from '../api';
/**
 * Hook to get all document packages for a specific service id.
 *
 * @param projectId the project id that the packages belong to.
 * @param serviceId the service id that the packages belong to.
 *
 * @returns an object with a list of document packages, loading state, error state and error object.
 */
export const usePackages = (projectId, serviceId) => {
    const { data: packages = [], isLoading, isError, error, } = useGetPackagesByServiceIdQuery({ projectId, serviceId });
    return useMemo(() => ({
        packages,
        isLoading,
        isError,
        error,
    }), [error, isError, isLoading, packages]);
};
