import { Button } from '@consigli/facade';
import { useTranslation } from 'react-i18next';

interface WizardNextButtonProps {
  disabled: boolean;
}

export const WizardNextButton = ({ disabled }: WizardNextButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button rounded primary type="submit" className="w-full" disabled={disabled}>
      {t('package-creation.next')}
    </Button>
  );
};
