export const saveFile = (filename, data) => {
    const a = document.createElement('a');
    a.href = data;
    a.download = filename;
    a.click();
    a.remove();
};
export const MIME_TYPES = {
    json: 'application/json',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
export const saveBlob = (filename, blob) => {
    const url = URL.createObjectURL(blob);
    saveFile(filename, url);
    URL.revokeObjectURL(url);
};
export const saveJson = (filename, json) => saveBlob(filename, new Blob([JSON.stringify(json, null, 4)], { type: MIME_TYPES.json }));
export const saveBlobBase64 = (filename, base64Data, mimeType) => {
    // Convert base64 string to a Blob
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: mimeType });
    saveBlob(filename, blob);
};
