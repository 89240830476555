import { DocumentType, DocumentsType } from '@consigli/types';
import clsx from 'clsx';
import { Highlight } from 'react-highlighter-ts';

import { DownloadFileButton } from '@/components/common/download-file-button';
import { mapExtensionToUppercase } from '@/util/document-check';

import { DocumentCardParsedData } from './document-card-parsed-data';

interface DocumentCardProps {
  document: DocumentsType;
  selected: string;
  searchText?: string;
}

export const DocumentCard = ({ document, selected, searchText }: DocumentCardProps) => {
  return (
    <div className="grid grid-cols-5 gap-x-4 gap-y-2">
      <div className="col-span-4 flex flex-col truncate">
        <div className="flex-1 ">
          <div
            className={clsx(
              'text-day-neutral-subtle',
              selected === document.id && 'text-white',
              'text-xs mb-1',
            )}
          >
            {mapExtensionToUppercase(document.extension)}
          </div>
          <div
            className={clsx(
              selected === document.id ? 'text-white' : 'day-neutral-dark',
              'text-base font-semibold',
            )}
          >
            {/* @ts-expect-error - As event handlers which don't exist for React are required */}
            <Highlight placeholder="" search={searchText}>
              <p>{document.name}</p>
            </Highlight>
          </div>
        </div>
        {document.type === DocumentType.BLOB && (
          <DocumentCardParsedData
            initialParsedData={document.parsedData.data}
            remainingOccurrences={document.parsedData.remainingOccurrences}
            documentId={document.id}
            searchText={searchText}
          />
        )}
      </div>
      <div className="col-span-1 w-fit ml-auto flex items-center">
        <DownloadFileButton document={document} />
      </div>
    </div>
  );
};
