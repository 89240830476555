import { LocalizationContext, LocalizationMap } from '@react-pdf-viewer/core';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import {
  RenderGoToPageProps,
  RenderCurrentPageLabelProps,
  pageNavigationPlugin,
} from '@react-pdf-viewer/page-navigation';
import { RenderShowSearchPopoverProps, searchPlugin } from '@react-pdf-viewer/search';
import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from '@react-pdf-viewer/zoom';
import i18next from 'i18next';
import { useEffect, useMemo, useState } from 'react';

import en from './locales/en/translation.json';
import ja from './locales/ja/translation.json';
import nb from './locales/nb/translation.json';
import nn from './locales/nn/translation.json';
import sv from './locales/sv/translation.json';
import vi from './locales/vi/translation.json';

export const usePDFToolbar = (documentName: string) => {
  const navigation = pageNavigationPlugin();
  const zoom = zoomPlugin();
  const search = searchPlugin();

  const download = getFilePlugin({
    fileNameGenerator: () => documentName,
  });

  const { GoToPreviousPage, GoToNextPage, CurrentPageLabel } = navigation;
  const { ZoomIn, ZoomOut, CurrentScale } = zoom;
  const { Download } = download;
  const { ShowSearchPopover } = search;

  const [l10n, setL10n] = useState(nb as unknown as LocalizationMap);

  const chooseLanguage = () => {
    const currentLanguage = i18next.language;
    switch (currentLanguage) {
      case 'en':
        return setL10n(en as unknown as LocalizationMap);
      case 'sv':
        return setL10n(sv as unknown as LocalizationMap);
      case 'vi':
        return setL10n(vi as unknown as LocalizationMap);
      case 'ja':
        return setL10n(ja as unknown as LocalizationMap);
      case 'nn':
        return setL10n(nn as unknown as LocalizationMap);
      default:
        return setL10n(nb as unknown as LocalizationMap);
    }
  };

  useEffect(() => {
    chooseLanguage();
  }, []);

  const fullscreenToolbar = useMemo(() => {
    const localizationContext = { l10n, setL10n };
    return (
      <LocalizationContext.Provider value={localizationContext}>
        <div className="grid grid-cols-7 p-1.5 bg-slate-600 rounded-t-md">
          <div className="flex flex-row items-center gap-1">
            <div className="pl-2.5">
              <GoToPreviousPage>
                {(props: RenderGoToPageProps) => (
                  <button
                    className="fa-solid fa-chevron-up text-xl text-white"
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  />
                )}
              </GoToPreviousPage>
            </div>
            <div className="text-white">
              <CurrentPageLabel>
                {(props: RenderCurrentPageLabelProps) => (
                  <span>{`${props.currentPage + 1}/${props.numberOfPages}`}</span>
                )}
              </CurrentPageLabel>
            </div>
            <div>
              <GoToNextPage>
                {(props: RenderGoToPageProps) => (
                  <button
                    className="fa-solid fa-chevron-down text-xl text-white"
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  />
                )}
              </GoToNextPage>
            </div>
          </div>
          <div className="items-center ml-12 grid col-span-2">
            <ShowSearchPopover>
              {(props: RenderShowSearchPopoverProps) => (
                <button onClick={props.onClick}>
                  <i className="fa-solid fa-search text-white" />
                </button>
              )}
            </ShowSearchPopover>
          </div>
          <div className="grid grid-flow-col items-center justify-center gap-1">
            <div>
              <ZoomOut>
                {(props: RenderZoomOutProps) => (
                  <button
                    className="fa-solid fa-magnifying-glass-minus text-xl text-white"
                    onClick={props.onClick}
                  />
                )}
              </ZoomOut>
            </div>
            <div>
              <CurrentScale>
                {(props: RenderCurrentScaleProps) => (
                  <span className="text-white">{`${Math.round(props.scale * 100)}%`}</span>
                )}
              </CurrentScale>
            </div>
            <div>
              <ZoomIn>
                {(props: RenderZoomInProps) => (
                  <button
                    className="fa-solid fa-magnifying-glass-plus text-xl text-white"
                    onClick={props.onClick}
                  />
                )}
              </ZoomIn>
            </div>
          </div>
          <div className="grid justify-end col-span-3 pr-3.5">
            <Download>
              {(props: RenderDownloadProps) => (
                <button
                  className="fa-solid fa-download text-xl text-white"
                  onClick={props.onClick}
                />
              )}
            </Download>
          </div>
        </div>
      </LocalizationContext.Provider>
    );
  }, [
    CurrentPageLabel,
    CurrentScale,
    Download,
    GoToNextPage,
    GoToPreviousPage,
    ShowSearchPopover,
    ZoomIn,
    ZoomOut,
    l10n,
  ]);

  const previewToolbar = useMemo(() => {
    const localizationContext = { l10n, setL10n };
    return (
      <LocalizationContext.Provider value={localizationContext}>
        <div className="grid grid-cols-5 p-1.5 bg-slate-600 rounded-t-md">
          <div className="flex flex-row items-center gap-1">
            <div className="pl-2.5">
              <GoToPreviousPage>
                {(props: RenderGoToPageProps) => (
                  <button
                    className="fa-solid fa-chevron-up text-xl text-white"
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  />
                )}
              </GoToPreviousPage>
            </div>
            <div className="text-white">
              <CurrentPageLabel>
                {(props: RenderCurrentPageLabelProps) => (
                  <span>{`${props.currentPage + 1}/${props.numberOfPages}`}</span>
                )}
              </CurrentPageLabel>
            </div>
            <div>
              <GoToNextPage>
                {(props: RenderGoToPageProps) => (
                  <button
                    className="fa-solid fa-chevron-down text-xl text-white"
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                  />
                )}
              </GoToNextPage>
            </div>
          </div>
          <div className="pl-2 flex justify-center">
            <ShowSearchPopover>
              {(props: RenderShowSearchPopoverProps) => (
                <button onClick={props.onClick}>
                  <i className="fa-solid fa-search text-white" />
                </button>
              )}
            </ShowSearchPopover>
          </div>
          <div className="flex items-center m-auto">
            <div className="pr-2">
              <ZoomOut>
                {(props: RenderZoomOutProps) => (
                  <button
                    className="fa-solid fa-magnifying-glass-minus text-lg text-white"
                    onClick={props.onClick}
                  />
                )}
              </ZoomOut>
            </div>
            <div>
              <CurrentScale>
                {(props: RenderCurrentScaleProps) => (
                  <span className="text-white">{`${Math.round(props.scale * 100)}%`}</span>
                )}
              </CurrentScale>
            </div>
            <div className="pl-2">
              <ZoomIn>
                {(props: RenderZoomInProps) => (
                  <button
                    className="fa-solid fa-magnifying-glass-plus text-lg text-white"
                    onClick={props.onClick}
                  />
                )}
              </ZoomIn>
            </div>
          </div>
        </div>
      </LocalizationContext.Provider>
    );
  }, [
    CurrentPageLabel,
    CurrentScale,
    GoToNextPage,
    GoToPreviousPage,
    ShowSearchPopover,
    ZoomIn,
    ZoomOut,
    l10n,
  ]);

  return {
    fullscreenToolbar,
    previewToolbar,
    navigation,
    zoom,
    download,
    search,
  };
};
