import { DragOverlay, UniqueIdentifier } from '@dnd-kit/core';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { FolderDocumentsType } from '@/util/types';

import { FoldersCard } from './folders-card';

interface CreatePortalProps {
  draggingCardId: UniqueIdentifier;
  draggingfile: FolderDocumentsType | undefined;
  handleCheckboxClick: (record: FolderDocumentsType) => void;
  selectedFiles: FolderDocumentsType[] | [];
}

export const CreatePortal = ({
  draggingCardId,
  draggingfile,
  handleCheckboxClick,
  selectedFiles,
}: CreatePortalProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }
  return (
    <>
      {
        createPortal(
          <DragOverlay>
            {draggingCardId && draggingfile && (
              <FoldersCard
                file={draggingfile}
                handleCheckboxClick={handleCheckboxClick}
                selectedFiles={selectedFiles}
                dragOverlay
              />
            )}
          </DragOverlay>,
          document.getElementById('drag-card') as Element,
        ) as React.ReactPortal
      }
    </>
  );
};
