import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { getPaginationResponseSchema } from './pagination';
import { ProjectRole } from './project-properties';
import { ServicePermissionName } from './service';
export const projectAccessSchema = z
    .object({
    id: z.number().int(),
    name: z.string(),
    role: z.nativeEnum(ProjectRole),
    services: z.array(z.nativeEnum(ServicePermissionName)),
})
    .strict()
    .transform(mapToCamelCase);
export const userSchema = z
    .object({
    id: z.string().uuid(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    is_superuser: z.boolean(),
    organization_id: z.string().uuid().nullable(),
    organization_name: z.string().nullable(),
    is_org_admin: z.boolean(),
    project_access: z.array(projectAccessSchema),
})
    .strict()
    .transform(mapToCamelCase);
export const getUserRequestSchema = z
    .object({
    id: z.string().uuid(),
})
    .strict()
    .transform(mapToCamelCase);
export const getUserResponseSchema = userSchema;
export const getUsersResponseSchema = getPaginationResponseSchema(userSchema);
export const getUsersRequestSchema = z
    .object({
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    search: z.string().nullish(),
    org_ids: z.array(z.string().uuid()).nullish(),
    project_ids: z.array(z.number().int()).nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getUserCountResponseSchema = z
    .object({
    count: z.number().int(),
})
    .strict()
    .transform(mapToCamelCase);
