import { Checkbox, CustomSelect, OptionType, Position, Search } from '@consigli/facade';
import { FindingCommenter } from '@consigli/types';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';

import { FindingBlob } from './findings';
import { useFindingsContext } from './findings-context';

interface FindingsFilterProps {
  selectAllChecked: boolean;
  handleSelectAllCheckboxClick: () => void;
  uniqueBlobs: FindingBlob[];
  commenters: FindingCommenter[];
}

export const FindingsFilter: FC<FindingsFilterProps> = ({
  selectAllChecked,
  handleSelectAllCheckboxClick,
  uniqueBlobs,
  commenters,
}) => {
  const { t } = useTranslation();
  const {
    selectedBlobIds,
    setSelectedBlobIds,
    selectedCommenterIds,
    setSelectedCommenterIds,
    searchTerm,
    setSearchTerm,
    setPage,
    showFindingIds,
    setShowFindingIds,
  } = useFindingsContext();

  const filenameOptions = useMemo(
    () =>
      uniqueBlobs?.map((blob: FindingBlob) => {
        if (blob.id === '__projectrelated__') {
          return { label: t('riskassessment.project-related-finding'), value: blob.id };
        }
        return { label: blob.name, value: blob.id };
      }),
    [t, uniqueBlobs],
  );

  const commenterOptions = useMemo(
    () =>
      commenters.map((commenter: FindingCommenter) => {
        return { label: commenter.name, value: commenter.id };
      }),
    [commenters],
  );

  const handleFilterBlobsChange = useCallback(
    (options: SingleValue<OptionType> | MultiValue<OptionType>) => {
      if (options) {
        const selectedValues = Array.isArray(options) ? options : [options];
        setSelectedBlobIds(selectedValues.map((option) => option.value));
      } else {
        setSelectedBlobIds([]);
      }
      setPage(1);
    },
    [setPage, setSelectedBlobIds],
  );

  const handleFilterCommentersChange = useCallback(
    (options: SingleValue<OptionType> | MultiValue<OptionType>) => {
      if (options) {
        const selectedValues = Array.isArray(options) ? options : [options];
        setSelectedCommenterIds(selectedValues.map((option) => option.value));
      } else {
        setSelectedCommenterIds([]);
      }
      setPage(1);
    },
    [setPage, setSelectedCommenterIds],
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    },
    [setPage, setSearchTerm],
  );

  return (
    <div className="flex flex-col sm:flex-row items-center justify-start">
      <div className="pl-1">
        <Checkbox
          checked={selectAllChecked}
          label={t('riskassessment.select-all')}
          value="Select all"
          id="findingSelectAll"
          labelPosition={Position.RIGHT}
          onChange={() => handleSelectAllCheckboxClick()}
        />
      </div>
      <div className="flex-1 z-[11] sm:pr-4 pb-2 sm:pb-0 w-full">
        <CustomSelect
          onBlur={() => {}}
          onChange={(newValue: SingleValue<OptionType> | MultiValue<OptionType>): void => {
            handleFilterBlobsChange(newValue);
          }}
          isMulti
          placeholder={t('riskassessment.filter-on-documents')}
          options={filenameOptions}
          value={
            selectedBlobIds
              ? filenameOptions.filter((option) => selectedBlobIds.includes(option.value))
              : []
          }
          width="w-full"
        />
      </div>
      <div className="flex-1 z-[11] sm:pr-4 pb-2 sm:pb-0 w-full">
        <CustomSelect
          onBlur={() => {}}
          onChange={(newValue: SingleValue<OptionType> | MultiValue<OptionType>): void => {
            handleFilterCommentersChange(newValue);
          }}
          isMulti
          placeholder={t('riskassessment.filter-on-commenters')}
          options={commenterOptions}
          value={
            selectedCommenterIds
              ? commenterOptions.filter((option) => selectedCommenterIds.includes(option.value))
              : []
          }
          width="w-full"
        />
      </div>
      <div className="flex-1 w-full">
        <Search
          text={searchTerm}
          onChange={handleSearch}
          placeholder={t('riskassessment.search-findings')}
          className="w-full"
        />
      </div>
      <Checkbox
        checked={showFindingIds}
        className="ml-4"
        label={t('riskassessment.show-finding-ids')}
        id="findingShowIds"
        labelPosition={Position.RIGHT}
        onChange={() => setShowFindingIds((i) => !i)}
      />
    </div>
  );
};
