import { TextInput, Button, Position, TextArea } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useCurrentLanguage,
  useProject,
  useSendFindingsMailMutation,
  useUpdateBatchFindingsMutation,
  useCreateFindingCommentMutation,
  formatApiError,
} from '@consigli/hooks';
import { ActionStatus, FindingCommentType } from '@consigli/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { HiOutlineXMark } from 'react-icons/hi2';
import { SlPaperPlane } from 'react-icons/sl';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { z } from 'zod';

import { createFindingsExcelExport } from '@/util/excel-export';
import { CheckableFinding } from '@/util/types';

import { useFindingsContext } from './findings-context';

const ForwardFindingsFormSchema = z.object({
  message: z.string(),
});
type ForwardFindingsForm = z.infer<typeof ForwardFindingsFormSchema>;

type RecipientCardProps = {
  email: string;
  setRecipients: Dispatch<SetStateAction<string[]>>;
  setEmailInput: Dispatch<SetStateAction<string>>;
  setEmailInputError: Dispatch<SetStateAction<boolean>>;
};

const RecipientCard: FC<RecipientCardProps> = ({
  email,
  setRecipients,
  setEmailInput,
  setEmailInputError,
}) => {
  const handleButtonClick = () => {
    setRecipients((recipients) => recipients.filter((recipient) => recipient !== email));
    setEmailInput('');
    setEmailInputError(false);
  };
  return (
    <div className="max-w-fit rounded flex bg-day-light-1 py-[2px] px-2">
      <p className="text-day-neutral-dark text-sm font-bold mr-1">{email}</p>
      <Button tertiary icon={HiOutlineXMark} className="p-0" onClick={handleButtonClick} />
    </div>
  );
};

type ProcessingPopupProps = {
  findings: CheckableFinding[];
  count: number;
  itemsOnPage: number;
  onClose: () => void;
  onComplete?: () => void;
};

export const ProcessingPopup: FC<ProcessingPopupProps> = ({
  findings,
  count,
  itemsOnPage,
  onClose,
  onComplete,
}) => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const currentLanguage = useCurrentLanguage();

  const [emailInput, setEmailInput] = useState('');
  const [emailInputError, setEmailInputError] = useState(false);
  const [recipients, setRecipients] = useState<string[]>([]);

  const { project, isLoading } = useProject(projectId);
  const { page, setPage } = useFindingsContext();
  const [sendMail] = useSendFindingsMailMutation();
  const [updateBatchFindings, updateBatchFindingsResult] = useUpdateBatchFindingsMutation();
  const [createFindingComment] = useCreateFindingCommentMutation();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ForwardFindingsForm>({
    resolver: zodResolver(ForwardFindingsFormSchema),
  });

  const addRecipient = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (emailInput !== '' && emailRegex.test(email) && !recipients.includes(email)) {
      setEmailInput('');
      setEmailInputError(false);

      setRecipients([...recipients, email]);
    } else {
      setEmailInputError(true);
    }
  };

  const onSubmit = async (data: ForwardFindingsForm) => {
    if (isLoading || !project || recipients.length == 0) {
      return;
    }
    try {
      const findingsMail = {
        message: data.message,
        recipients: { to: recipients.map((recipient) => ({ address: recipient })) },
        excelExport: createFindingsExcelExport(findings, t, project.name),
        language: currentLanguage,
      };
      await sendMail({
        projectId,
        packageId,
        findingsMail,
      });
      toast.success(t('toast.success-sent-mail'));
    } catch (err) {
      toast.error(t('toast.error-failed-sending-mail'));
    }
    try {
      if (itemsOnPage === count && page > 1) {
        setPage((prevPage) => prevPage - 1);
      }
      const findingsToUpdate = findings.map((finding: CheckableFinding) => ({
        id: finding.id,
        action: ActionStatus.WAITING,
      }));

      await updateBatchFindings({
        projectId,
        packageId,
        data: findingsToUpdate,
      });

      // Create a system comment for each finding
      await Promise.all(
        findingsToUpdate.map((finding) =>
          createFindingComment({
            projectId,
            packageId,
            findingId: finding.id,
            text: `${recipients.join(', ')}`,
            type: FindingCommentType.SYSTEM_COMMENT_FORWARDED,
          }),
        ),
      );

      onComplete && onComplete();
      toast.success(t('toast.success-updated-finding-status-to-waiting'));
    } catch (err) {
      toast.error(
        `${t('toast.error-failed-to-update-finding_status')}: ${formatApiError(
          updateBatchFindingsResult.error,
        )}`,
      );
    }
    onClose();
  };

  const handleChangeStatus = useCallback(
    async (findings: CheckableFinding[]) => {
      try {
        await updateBatchFindings({
          projectId,
          packageId,
          data: findings.map((finding) => ({
            id: finding.id,
            action: ActionStatus.WAITING,
          })),
        });
        toast.success(t('toast.file-update-success-no-status'));
      } catch (error) {
        toast.error(t('toast.file-update-failure'));
      }
      onClose();
    },
    [onClose, packageId, projectId, t, updateBatchFindings],
  );

  return (
    <Popup
      overlayStyle={{ background: 'rgba(100, 100, 100, 0.35)' }}
      contentStyle={{
        backgroundColor: '#fbfcfd',
        borderRadius: '10px',
        width: '80%',
        maxWidth: '500px',
      }}
      modal
      open
      onClose={onClose}
    >
      <div className="flex flex-col flex-nowrap gap-y-4 p-4">
        <div className="flex flex-row justify-between">
          <h2 className="font-bold text-2xl text-day-neutral-dark">
            {t('processing-popup.title')}
          </h2>
          <Button className="p-0" tertiary icon={HiOutlineXMark} onClick={onClose} iconSize={25} />
        </div>
        <div className="flex flex-col">
          <p className="text-day-neutral-dark font-bold mb-3">
            {t('processing-popup.change-status')}
          </p>
          <div className="flex-shrink-0">
            <Button
              secondary
              rounded
              className="text-xs p-1.5 border border-1"
              icon={SlPaperPlane}
              onClick={() => handleChangeStatus(findings)}
            >
              {t('processing-popup.button-processing')}
            </Button>
          </div>
        </div>
        <div>
          <p className="text-day-neutral-dark font-bold mb-3">{t('processing-popup.send-email')}</p>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
            <div className="flex w-full mb-2">
              <div className="flex-1 mr-3">
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmailInput(e.target.value)
                  }
                  value={emailInput}
                  className="bg-white h-[42px] w-full"
                  placeholder={t('processing-popup.enter-mail')}
                />
              </div>
              <Button
                primary
                rounded
                className="border border-1"
                onClick={() => addRecipient(emailInput)}
                icon={FiPlus}
                iconPosition={Position.RIGHT}
                iconColor="white"
              >
                {t('processing-popup.button-add')}
              </Button>
            </div>
            {emailInputError && (
              <p className="text-sm mb-2 text-error-dark">{t('processing-popup.invalid-email')}</p>
            )}
            <div className="flex flex-wrap gap-2 mb-4">
              {recipients?.map((email) => (
                <RecipientCard
                  key={email}
                  email={email}
                  setRecipients={setRecipients}
                  setEmailInput={setEmailInput}
                  setEmailInputError={setEmailInputError}
                />
              ))}
            </div>
            <TextArea
              {...register('message')}
              placeholder={t('processing-popup.add-message')}
              className="w-full mb-2"
            />
            <Button
              primary
              rounded
              type="submit"
              disabled={recipients.length === 0 || isSubmitting}
              icon={SlPaperPlane}
              iconPosition={Position.RIGHT}
              iconSize={20}
              iconColor="white"
            >
              {t('processing-popup.send')}
            </Button>
          </form>
        </div>
      </div>
    </Popup>
  );
};
