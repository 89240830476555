import type { FC, ReactNode } from 'react';

interface FluidContentProps {
  children: ReactNode;
}

export const FluidLayout: FC<FluidContentProps> = ({ children }) => (
  <div className="w-full flex justify-center bg-day-light-4 flex-1">
    <div className="flex flex-col w-full">{children}</div>
  </div>
);
