import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart as ReactPieChart } from 'react-minimal-pie-chart';

import { PieChartProps } from '@/util/interfaces';

import { PieChartLegend } from './pie-chart-legend';

const lineWidth = 40;

export const PieChart: FC<PieChartProps> = ({ data }) => {
  const { t } = useTranslation();
  const filteredArray = useMemo(() => data.filter((item) => !!item.value), [data]);

  const hasExactlyOneTruthyValue = useMemo(
    () => filteredArray.length === 1,
    [filteredArray.length],
  );
  const noTruthyValue = useMemo(() => filteredArray.length === 0, [filteredArray.length]);
  return (
    !noTruthyValue && (
      <div className="flex flex-col items-center justify-center sm:flex-row gap-4 p-6 sm:justify-around bg-day-light-3 rounded-md">
        <PieChartLegend data={data} />
        <div className="sm:ml-6 min-w-[50px] max-w-[200px]">
          <ReactPieChart
            data={data}
            lineWidth={lineWidth}
            animate
            animationDuration={800}
            label={() => t('dashboard.pie-findings')}
            labelStyle={{
              fontSize: '12px',
              fontFamily: 'inter',
              fill: '#334155',
            }}
            labelPosition={0}
            paddingAngle={hasExactlyOneTruthyValue ? 0 : 2}
          />
        </div>
      </div>
    )
  );
};
