import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { Language } from './language';
import { OrganizationRole } from './organization';
import { getPaginationResponseSchema } from './pagination';
import { ProjectRole } from './project-properties';
import { ServicePermissionName } from './service';
export var InviteStatus;
(function (InviteStatus) {
    InviteStatus["PENDING"] = "PENDING";
    InviteStatus["ACCEPTED"] = "ACCEPTED";
    InviteStatus["REJECTED"] = "REJECTED";
})(InviteStatus || (InviteStatus = {}));
const inviteOrganizationSchema = z
    .object({
    id: z.string().uuid(),
    role: z.nativeEnum(OrganizationRole),
})
    .strict()
    .transform(mapToCamelCase);
const inviteProjectSchema = z
    .object({
    id: z.number().int(),
    name: z.string().nullish(),
    role: z.nativeEnum(ProjectRole),
    permissions: z.array(z.nativeEnum(ServicePermissionName)),
})
    .strict()
    .transform(mapToCamelCase);
export const inviteSchema = z
    .object({
    id: z.string().uuid(),
    invited_by: z.string().uuid(),
    invited_by_email: z.string().email(),
    recipient: z.string().email(),
    status: z.nativeEnum(InviteStatus),
    language: z.nativeEnum(Language),
    organization: inviteOrganizationSchema.nullish(),
    projects: z.array(inviteProjectSchema).nullish(),
    expires_at: z.string().datetime(),
})
    .strict()
    .transform(mapToCamelCase);
export const getInvitesRequestSchema = z
    .object({
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    status: z.nativeEnum(InviteStatus).nullish(),
    search: z.string().nullish(),
    recipient: z.string().email().nullish(),
    invited_by_email: z.string().email().nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getInvitesResponseSchema = getPaginationResponseSchema(inviteSchema);
const sendInviteRequestSchema = inviteSchema
    .innerType()
    .pick({
    recipient: true,
    language: true,
    organization: true,
    projects: true,
})
    .strict()
    .transform(mapToCamelCase);
export const getInviteCountRequestSchema = z
    .object({
    invited_by_email: z.string().email().nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getInviteCountResponseSchema = z
    .object({
    count: z.number().int(),
})
    .strict()
    .transform(mapToCamelCase);
