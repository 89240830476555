import { Spinner, Button } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useCreateExportMutation,
  usePackageByServiceIdAndPackageId,
} from '@consigli/hooks';
import { ExportStatus, ExportType } from '@consigli/types';
import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const ExportFamacButton: FC = () => {
  const projectId = useProjectId();
  const packageId = usePackageId();
  const [startExportMutation, { isLoading: startingExport }] = useCreateExportMutation();
  const { pkg } = usePackageByServiceIdAndPackageId(projectId, 6, packageId);
  const { t } = useTranslation();

  const handleExport = useCallback(async () => {
    try {
      await startExportMutation({
        projectId,
        packageId,
        exports: {
          type: ExportType.FAMAC,
          status: ExportStatus.INITIALIZED,
        },
      }).unwrap();
      toast.success(t('toast.famac-export-success'), { autoClose: 5000 });
    } catch {
      toast.error(t('toast.famac-export-error'));
    }
  }, [projectId, packageId, t, startExportMutation]);

  return pkg && pkg.externalFamacBuildingId ? (
    <Button
      rounded
      secondary
      onClick={handleExport}
      type="submit"
      className="w-full font-medium text-sm mt-2"
      disabled={startingExport}
      icon={startingExport ? () => <Spinner size="xsmall" /> : undefined}
    >
      {t('export.famac-button')}
    </Button>
  ) : null;
};
