import { useAccordionContext } from '@/contexts/use-accordion-context';

import { AccordionContent } from './accordion-content';
import { AccordionHeader } from './accordion-header';

interface AccordionProps {
  index: number;
  onClick: () => void;
  header: () => JSX.Element;
  children: React.ReactNode;
  disabled?: boolean;
  classNames?: string;
}

export const SingleOpenAccordion = ({
  index,
  onClick,
  header,
  children,
  disabled = false,
  classNames,
}: AccordionProps) => {
  const { open, toggle } = useAccordionContext();
  const handleClick = (selected: number) => {
    if (!disabled) {
      toggle(selected);
    }
    onClick();
  };

  return (
    <div className="shadow mb-4 rounded-md">
      <AccordionHeader
        open={open === index}
        onClick={() => handleClick(index)}
        header={header}
        disabled={disabled}
        classNames={classNames}
      ></AccordionHeader>
      <AccordionContent open={open === index}>{children}</AccordionContent>
    </div>
  );
};
