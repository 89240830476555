import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown, FaAngleRight, FaRegFolder } from 'react-icons/fa6';

import { useFileTreeContext } from '@/contexts/use-file-tree-context';
import { useViewerContext, ViewerMode } from '@/contexts/use-viewer-context';

import { TreeEntry, TreeFolder } from '../util/document-list-to-tree';

interface Category {
  title: string;
  entry: TreeEntry;
}

export const Category = (props: Category) => {
  const { title, entry: folderEntry } = props;
  const entry = folderEntry as TreeFolder;
  const { setNodeRef, isOver } = useDroppable({
    id: entry.id,
  });
  const { setMode } = useViewerContext();
  const [open, setOpen] = useState(false);
  const { activeFolderId, setActiveFolderId, summaryCount, setPage } = useFileTreeContext();
  const classes = clsx(
    {
      'bg-slate-300': isOver,
      'bg-day-light-3': activeFolderId === entry.id,
    },
    'flex items-center justify-between cursor-pointer rounded-lg hover:rounded-lg',
    'hover:bg-day-light-3',
  );
  const { setSelectedCard } = useFileTreeContext();
  const { t } = useTranslation();

  const renderSubCategories = (subCategories: TreeFolder[]) => {
    return subCategories.map((child: TreeFolder) => (
      <div className="py-0.5 pl-2" key={child.id}>
        <Category title={child.toDisplayString(t)} entry={child}></Category>
      </div>
    ));
  };
  function getSummaryCount(nsId: string): number {
    let value = 0;
    if (typeof summaryCount === 'object' && summaryCount !== null) {
      Object.keys(summaryCount).map((key: string) => {
        if (key.startsWith(nsId)) {
          value += summaryCount[key];
        }
      });
    }
    return value;
  }

  const findDocumentCount = (entry: TreeFolder) => {
    const regex = /_\d{1,2}$/;
    if (regex.test(entry.id)) {
      return getSummaryCount(entry.id);
    } else {
      return summaryCount?.[entry.id] || 0;
    }
  };
  const accordionElement = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (accordionElement.current) {
      setContentHeight(open ? accordionElement.current.scrollHeight : 0);
    }
  }, [open]);
  const subCategories = useMemo(
    () => [...entry.children.values()],
    [entry.children],
  ) as TreeFolder[];

  return (
    <>
      <div className={classes} ref={setNodeRef}>
        <span
          className="flex flex-1 items-center"
          onClick={() => {
            setActiveFolderId(entry.id);
            setMode(ViewerMode.Closed);
            setSelectedCard('');
            setPage(1);
          }}
          aria-hidden
        >
          <div className="m-3 w-[14px] h-[14px] flex items-center justify-center">
            <FaRegFolder />
          </div>
          <span
            className={clsx('my-3 text-sm flex-1', { 'font-bold': activeFolderId === entry.id })}
          >
            {title} ({findDocumentCount(entry)})
          </span>
        </span>
        {subCategories.length ? (
          <span
            className="p-3 h-full flex items-center"
            aria-hidden
            onClick={() => {
              setOpen((prev) => {
                return !prev;
              });
            }}
          >
            {open ? <FaAngleDown /> : <FaAngleRight />}
          </span>
        ) : null}
      </div>
      <div
        ref={accordionElement}
        className="overflow-auto bg-day-light-5 transition-h duration-300 ease-out px-2"
        style={{
          height: open ? `${contentHeight}px` : '0px',
        }}
      >
        {open && renderSubCategories(subCategories)}
      </div>
    </>
  );
};
