import { Button, Spinner } from '@consigli/facade';
import { useAcceptInviteMutation } from '@consigli/hooks';
import { OrganizationRole, InviteProject, InviteOrganization, ProjectRole } from '@consigli/types';
import { format } from 'date-fns';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';

interface userInviteCardProps {
  id: string;
  expiresAt: string;
  organization?: InviteOrganization | null;
  projects?: InviteProject[] | null;
}

export const UserProfileInviteCard: FC<userInviteCardProps> = ({
  id,
  expiresAt,
  organization,
  projects,
}) => {
  const { t } = useTranslation();
  const [acceptInviteMutation, { isLoading: acceptingInvite }] = useAcceptInviteMutation();

  const handleAcceptInvite = useCallback(
    async (inviteId: string) => {
      try {
        await acceptInviteMutation(inviteId).unwrap();
        toast.info(t('profile.accepted-success'));
      } catch (error) {
        toast.error(t('profile.accepted-failure'));
      }
    },
    [acceptInviteMutation, t],
  );

  const formatTimeStamp = useCallback((timestamp: string) => {
    return format(new Date(timestamp), 'dd/MM/yyyy');
  }, []);

  if (acceptingInvite) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col p-4 mt-2 rounded border border-day-secondary bg-day-light-3">
      <div className="flex justify-between">
        <div>
          <div className="flex">
            <p className="text-sm text-day-neutral-subtle">{t('profile.expires-at')}</p>
            <p className="ml-1 text-sm">{formatTimeStamp(expiresAt)}</p>
          </div>
          <div className="flex">
            <p className="mr-1 text-sm text-day-neutral-subtle">{t('profile.role') + ':'}</p>
            <b className="text-sm">
              {organization && organization.role == OrganizationRole.ADMIN
                ? t('profile.admin')
                : t('profile.user')}
            </b>
          </div>
        </div>
        <Button primary rounded onClick={() => handleAcceptInvite(id)}>
          {t('profile.accept')}
        </Button>
      </div>
      <p className="text-sm">
        {organization && organization.role != OrganizationRole.ADMIN && (
          <b>{t('profile.can-create-projects')}</b>
        )}
      </p>
      <div>
        {projects && projects.length > 0 && (
          <p className="text-day-neutral-subtle mt-2">{t('profile.projects')}</p>
        )}
        {projects &&
          projects.length > 0 &&
          projects.map((project) => (
            <div
              key={project.id}
              className="flex flex-col p-4 mt-2 rounded border border-day-secondary bg-day-light-2"
            >
              <b className="mb-2">{project.name}</b>
              <div>
                {project.role == ProjectRole.ADMIN && (
                  <div className="flex items-center mb-2">
                    <IoShieldCheckmarkOutline
                      className=""
                      title={t('profile.user-is-project-admin')}
                    />
                    <b className="ml-2 text-xs">{t('profile.project-admin')}</b>
                  </div>
                )}
              </div>
              <div className="flex">
                <p className="text-xs text-day-neutral-subtle whitespace-nowrap">
                  {t('profile.service-access')}
                </p>
                <b className="text-xs ml-1">
                  {project.permissions.length > 0
                    ? project.permissions
                        .map((permission) => t(`profile.services.${permission}`))
                        .join(', ')
                    : t('profile.all-services')}
                </b>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
