import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { answerSchema } from './answer';
export const packageQuerySchema = answerSchema;
export const packageStatusSchema = z
    .object({
    ready: z.boolean(),
})
    .strict()
    .transform(mapToCamelCase);
