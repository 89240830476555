import { saveBlob } from '@consigli/utils';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
export const useDownloadFile = (doc, translation = true) => {
    const { t } = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false);
    const downloadFile = useCallback(async () => {
        setIsDownloading(true);
        toast.info(translation ? t('document-list.download-begin-single') : 'Downloading file...');
        try {
            if (!doc.viewerUrl) {
                throw new Error('Viewer URL is null');
            }
            const response = await fetch(doc.viewerUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            saveBlob(doc.name, blob);
        }
        catch (error) {
            toast.error(translation
                ? t('document-list.download-failed')
                : `Failed to download file. Error: ${error}`);
        }
        finally {
            setIsDownloading(false);
        }
    }, [doc.name, doc.viewerUrl, t, translation]);
    return { isDownloading, downloadFile };
};
