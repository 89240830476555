import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { documentPackageSchema } from './document-package';
import { projectSchema } from './project';
export const parsedDataSchema = z
    .object({
    id: z.number().int(),
    project_id: projectSchema.innerType().shape.id,
    guid: z.string().nullable(),
    file_name: z.string().nullable(),
    el_number: z.string().nullable(),
    content_type: z.string().nullable(),
    paragraph_id: z.string().nullable(),
    content: z.string().nullable(),
    header_number: z.string().nullable(),
    header_level: z.number().int().nullable(),
    document_package: documentPackageSchema.innerType().shape.id,
    page_number: z.number().int().nullable(),
    x0: z.number().nullable(),
    y0: z.number().nullable(),
    x1: z.number().nullable(),
    y1: z.number().nullable(),
})
    .strict()
    .transform(mapToCamelCase);
// GET /projects/:projectId/parsed-data/?document_package=:packageId&content=:searchTerm
export const parsedDataResponseSchema = z.array(parsedDataSchema).transform(mapToCamelCase);
