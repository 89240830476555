import { CustomSelect, LoadingArea, OptionType, Search } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useDebouncedSearch,
  useGetBlobFilterCountQuery,
  useBlobs,
  useLazyGetBlobsQuery,
  useQuery,
  useGetBlobNameListQuery,
  useServiceName,
} from '@consigli/hooks';
import { BlobParsedStatus, convertBlobToDocument, ServiceName } from '@consigli/types';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/components/layouts/workspace-layout/workspace-layout';
import { Documents } from '@/organisms/document-list/documents';
import { BlobFilter } from '@/util/types';

import { DocumentTabs } from './document-tabs';

export const DocumentListing: FC = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const queryParams = useQuery();
  const querySearchTerm = queryParams.get('q');
  const serviceName = useServiceName();
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState<BlobFilter>(BlobFilter.PARSED);
  const [searchContent, setSearchContent] = useState(querySearchTerm || '');
  const [searchFileName, setSearchFileName] = useState('');
  const debouncedSearchContent = useDebouncedSearch(searchContent, 500);
  const debouncedSearchFileName = useDebouncedSearch(searchFileName, 500);
  const [selectedBlobIds, setSelectedBlobIds] = useState<string[]>([]);

  const { data: blobStatusCount = {} } = useGetBlobFilterCountQuery({
    projectId,
    packageId,
    blobIds: selectedBlobIds,
    searchParsedData: debouncedSearchContent.length >= 3 ? debouncedSearchContent : '',
    searchName: debouncedSearchFileName.length >= 3 ? debouncedSearchFileName : '',
  });

  const convertBlobFilterToBlobParsedStatus = useCallback((tab: BlobFilter): BlobParsedStatus => {
    switch (tab) {
      case BlobFilter.UNPARSED:
        return BlobParsedStatus.UNPARSED;
      case BlobFilter.PARSED:
        return BlobParsedStatus.PARSED;
      case BlobFilter.DUPLICATE:
        return BlobParsedStatus.DUPLICATE;
      default:
        return BlobParsedStatus.PARSED;
    }
  }, []);

  const mapSelectedTabToFilter = useCallback(
    (tab: BlobFilter) => {
      if (tab === BlobFilter.SUGGESTION) {
        return { suggestion: true };
      } else {
        // Assuming there's a way to map BlobFilter values to BlobParsedStatus values
        // This might require a mapping function or a direct conversion, depending on how your types are set up
        const status: BlobParsedStatus[] = [convertBlobFilterToBlobParsedStatus(tab)];
        return { status };
      }
    },
    [convertBlobFilterToBlobParsedStatus],
  );

  const totalCount = useMemo(() => {
    return Object.entries(blobStatusCount as Record<string, number>)
      .filter(([key]) => key.toUpperCase() !== BlobFilter.SUGGESTION)
      .reduce((total, [, count]) => total + count, 0);
  }, [blobStatusCount]);

  const keys = useMemo(() => {
    return Object.entries(blobStatusCount as Record<string, number>)
      .filter(([, count]) => count > 0)
      .filter(([key]) => key.toUpperCase() !== 'SUGGESTION') // Exclude 'SUGGESTION' as a tab in documents
      .map(([key]) => BlobFilter[key.toUpperCase() as keyof typeof BlobFilter]);
  }, [blobStatusCount]);

  useEffect(() => {
    if (!keys.includes(selectedTab) && keys.length > 0) {
      setSelectedTab(keys[0]);
    }
  }, [keys, selectedTab]);

  const { blobs, paginatedCount, isLoading, isFetching } = useBlobs({
    projectId,
    packageId,
    page: page,
    searchParsedData: debouncedSearchContent.length >= 3 ? debouncedSearchContent : '',
    searchName: debouncedSearchFileName.length >= 3 ? debouncedSearchFileName : '',
    blobIds: selectedBlobIds,
    ...mapSelectedTabToFilter(selectedTab),
  });

  const { data: blobNameList } = useGetBlobNameListQuery({
    projectId,
    packageId,
    searchParsedData: debouncedSearchContent.length >= 3 ? debouncedSearchContent : '',
    ...mapSelectedTabToFilter(selectedTab),
  });

  const options: OptionType[] = useMemo(() => {
    return (
      blobNameList?.map((spec) => ({
        label: spec.name,
        value: spec.id,
      })) || []
    );
  }, [blobNameList]);

  const [fetchAllBlobs, { isFetching: isFetchingAllBlobs }] = useLazyGetBlobsQuery();

  const handleFetchAllFiles = useCallback(async () => {
    const blobsResponse = await fetchAllBlobs({
      projectId,
      packageId,
      page: 'all',
      ...mapSelectedTabToFilter(selectedTab),
    }).unwrap();
    return blobsResponse.results.map((blob) => convertBlobToDocument(blob));
  }, [fetchAllBlobs, mapSelectedTabToFilter, packageId, projectId, selectedTab]);

  const handleTabClick = useCallback(
    (tab: BlobFilter) => {
      setSelectedTab(tab);
      setPage(1);
    },
    [setSelectedTab, setPage],
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchContent && setSearchContent(event.target.value);
      setPage(1);
    },
    [setPage, setSearchContent],
  );

  const handleFileNameSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchFileName && setSearchFileName(event.target.value);
      setPage(1);
    },
    [setPage, setSearchFileName],
  );

  const handleFilterBlobNamesChange = useCallback(
    (options: SingleValue<OptionType> | MultiValue<OptionType>) => {
      if (options) {
        const selectedValues = Array.isArray(options) ? options : [options];
        setSelectedBlobIds(selectedValues.map((option) => option.value));
      } else {
        setSelectedBlobIds([]);
      }
      setPage(1);
    },
    [setPage, setSelectedBlobIds],
  );

  return (
    <WorkspaceLayout>
      <Breadcrumb label={t('servicetabs.documents')} />
      {isLoading ? (
        <LoadingArea title={t('document-list.loading')} />
      ) : (
        <Documents
          files={blobs.map((file) => convertBlobToDocument(file))}
          headerTitle={t('document-list.total_documents')}
          paginatedCount={paginatedCount}
          totalCount={totalCount}
          page={page}
          setPage={setPage}
          searchText={searchContent}
          isFetching={isFetching}
          fetchAllFiles={handleFetchAllFiles}
          isFetchingAllFiles={isFetchingAllBlobs}
          selectedTab={selectedTab}
          packageId={packageId}
          tabsComponent={({ onClick }: { onClick: () => void }) => (
            <DocumentTabs
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
              keys={keys}
              onClick={onClick}
              blobStatusCount={blobStatusCount}
            />
          )}
        >
          <div className="flex w-full gap-x-6">
            <div className="z-20 w-1/2">
              {serviceName === ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE ? (
                <Search
                  text={searchFileName}
                  onChange={handleFileNameSearch}
                  placeholder={t('riskassessment.search-document-names')}
                  className="w-full"
                />
              ) : (
                <CustomSelect
                  onBlur={() => {}}
                  onChange={(newValue: SingleValue<OptionType> | MultiValue<OptionType>): void => {
                    handleFilterBlobNamesChange(newValue);
                  }}
                  isMulti
                  placeholder={t('riskassessment.search-document-names')}
                  options={options}
                  value={
                    selectedBlobIds
                      ? options.filter((option) => selectedBlobIds.includes(option.value as string))
                      : []
                  }
                />
              )}
            </div>
            <div className="w-1/2">
              <Search
                className="w-full"
                text={searchContent}
                onChange={handleSearch}
                placeholder={t('riskassessment.search_content')}
              />
            </div>
          </div>
        </Documents>
      )}
    </WorkspaceLayout>
  );
};
