import { useGetStructuralEngineeringsQuery, useStructuralId, useProjectId } from '@consigli/hooks';
import { StructuralType } from '@consigli/types';

import { PackageList } from '@/organisms/workspace-tabs/package-list';

export const StructuralPackage = () => {
  const projectId = useProjectId();
  const structuralId = useStructuralId();

  const { data: structural = [] } = useGetStructuralEngineeringsQuery({
    projectId,
    type: StructuralType.STRUCTURAL,
  });

  return <PackageList packages={structural} id={structuralId} />;
};
