import { ServiceId } from '@consigli/types';

import { SecondaryPackageUpload } from './secondary-package-upload';

interface NewPackageProps {
  compareServiceId: ServiceId;
}

export const NewPackage = ({ compareServiceId }: NewPackageProps) => {
  return <SecondaryPackageUpload serviceId={compareServiceId}></SecondaryPackageUpload>;
};
