import { StructuralResultType } from '@consigli/types';
import { useParams } from 'react-router-dom';
export const useStructuralResultType = () => {
    const { resultType } = useParams();
    const mapToStructuralType = (resultType) => {
        switch (resultType) {
            case 'cast-in-place':
                return StructuralResultType.CAST_IN_PLACE;
            case 'steel-frames':
                return StructuralResultType.STEEL_FRAME_WITH_HOLLOW_CORE_SLABS;
            case 'concrete-columns':
                return StructuralResultType.CONCRETE_COLUMNS_WITH_HOLLOW_CORE_SLABS;
            default:
                throw new Error(`useStructuralResultType called with invalid :structuralResultType (${resultType})`);
        }
    };
    if (resultType == undefined) {
        throw new Error(`useStructuralResultType called with invalid :structuralResultType (${resultType})`);
    }
    return mapToStructuralType(resultType);
};
