import type { FC, ReactNode } from 'react';

interface CardGridProps {
  children: ReactNode[];
}

export const CardGrid: FC<CardGridProps> = ({ children }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-between gap-8 bg-opacity-[.36] rounded">
    {children}
  </div>
);
