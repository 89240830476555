import { Button, Spinner } from '@consigli/facade';
import { useProjectId, usePackageId, useProject, useExportToExcelMutation } from '@consigli/hooks';
import { Finding } from '@consigli/types';
import { MIME_TYPES, saveBlobBase64 } from '@consigli/utils';
import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createFindingsExcelExport } from '@/util/excel-export';

type ExportButtonProps = {
  classNames?: string;
  isFilterNeeded?: boolean;
  downloadFindings: (isFilterNeeded: boolean) => Promise<Finding[]>;
  isDownloadingFindings: boolean;
};

export const ExportButton: FC<ExportButtonProps> = (props) => {
  const { classNames, isFilterNeeded, downloadFindings, isDownloadingFindings } = props;
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const { project } = useProject(projectId);
  const [exportToExcel, { isLoading: isExportingFindingsExcel }] = useExportToExcelMutation();

  const isLoading = isDownloadingFindings || isExportingFindingsExcel;

  const onExportClick = useCallback(async () => {
    if (project == null) {
      throw new Error('Cannot export project when project is undefined!');
    }
    try {
      const downloadedFindings = await downloadFindings(isFilterNeeded ?? false);
      const excelExport = createFindingsExcelExport(downloadedFindings, t, project.name);
      const { base64Data } = await exportToExcel({
        projectId,
        packageId,
        excelExport,
      }).unwrap();
      saveBlobBase64(`${project.name}_riskassessment.xlsx`, base64Data, MIME_TYPES.xlsx);
      toast.success(t('riskassessment.export.success'));
    } catch (error) {
      toast.error(t('riskassessment.export.error'));
    }
  }, [project, downloadFindings, isFilterNeeded, t, exportToExcel, projectId, packageId]);

  return (
    <Button
      rounded
      secondary
      disabled={isLoading}
      onClick={onExportClick}
      icon={isLoading ? () => <Spinner size="xsmall" /> : undefined}
      className={clsx('text-sm font-bold', classNames)}
    >
      {isFilterNeeded ? t('riskassessment.export_filtered') : t('riskassessment.exportlist')}
    </Button>
  );
};
