export var FoldersDocumentType;
(function (FoldersDocumentType) {
    FoldersDocumentType["DRAWING"] = "DRAWING";
    FoldersDocumentType["FUNCTIONAL_DESCRIPTION"] = "FUNCTIONAL_DESCRIPTION";
    FoldersDocumentType["FLOORPLAN"] = "FLOORPLAN";
    FoldersDocumentType["SCHEMATIC_DRAWING"] = "SCHEMATIC_DRAWING";
    FoldersDocumentType["PRODUCT_DATA_SHEET"] = "PRODUCT_DATA_SHEET";
    FoldersDocumentType["PRODUCT_CATALOGUE"] = "PRODUCT_CATALOGUE";
    FoldersDocumentType["BUILDING_INFORMATION"] = "BUILDING_INFORMATION";
    FoldersDocumentType["CALCULATIONS_CAPACITY"] = "CALCULATIONS_CAPACITY";
    FoldersDocumentType["DESIGN_BASIS"] = "DESIGN_BASIS";
    FoldersDocumentType["CERTIFICATES"] = "CERTIFICATES";
    FoldersDocumentType["DECLARATION_OF_CONFORMITY"] = "DECLARATION_OF_CONFORMITY";
    FoldersDocumentType["CONTROL_DOCUMENT"] = "CONTROL_DOCUMENT";
    FoldersDocumentType["FIRE_DOCUMENTATION"] = "FIRE_DOCUMENTATION";
    FoldersDocumentType["NO_CATEGORY"] = "NO_CATEGORY";
    FoldersDocumentType["ADDRESS_AND_TELEPHONE_LIST"] = "ADDRESS_AND_TELEPHONE_LIST";
    FoldersDocumentType["DESCRIPTION"] = "DESCRIPTION";
    FoldersDocumentType["IMAGE"] = "IMAGE";
    FoldersDocumentType["USER_MANUAL"] = "USER_MANUAL";
    FoldersDocumentType["PRELIMINARY_INSPECTION"] = "PRELIMINARY_INSPECTION";
    FoldersDocumentType["MANAGEMENT_DOCUMENT"] = "MANAGEMENT_DOCUMENT";
    FoldersDocumentType["REQUIREMENT_ACHIEVEMENT"] = "REQUIREMENT_ACHIEVEMENT";
    FoldersDocumentType["OVERVIEWS_AND_LISTS"] = "OVERVIEWS_AND_LISTS";
    FoldersDocumentType["TAKEOVER_PROTOCOL"] = "TAKEOVER_PROTOCOL";
    FoldersDocumentType["PRODUCT_INFORMATION"] = "PRODUCT_INFORMATION";
    FoldersDocumentType["PROTOCOL"] = "PROTOCOL";
    FoldersDocumentType["REPORT"] = "REPORT";
    FoldersDocumentType["ADDITIONAL_ORDER"] = "ADDITIONAL_ORDER";
    FoldersDocumentType["COMPLETED_CHECKLISTS"] = "COMPLETED_CHECKLISTS";
    FoldersDocumentType["MAINTENANCE"] = "MAINTENANCE";
    FoldersDocumentType["NOTE"] = "NOTE";
    FoldersDocumentType["SCHEMA"] = "SCHEMA";
    FoldersDocumentType["CALCULATION"] = "CALCULATION";
    FoldersDocumentType["BUILDING_PRODUCT_DECLARATION"] = "BUILDING_PRODUCT_DECLARATION";
    FoldersDocumentType["DISASSEMBLY_ASSEMBLY_INSTRUCTION"] = "DISASSEMBLY_ASSEMBLY_INSTRUCTION";
    FoldersDocumentType["GUARANTEE"] = "GUARANTEE";
    FoldersDocumentType["SDS_DATASHEET"] = "SDS_DATASHEET";
    FoldersDocumentType["ELECTRICAL_CIRCUIT_DIRECTORY"] = "ELECTRICAL_CIRCUIT_DIRECTORY";
    FoldersDocumentType["LIGHT_FIXTURES"] = "LIGHT_FIXTURES";
    FoldersDocumentType["LOCKS_AND_FITTINGS"] = "LOCKS_AND_FITTINGS";
    FoldersDocumentType["LIST_OF_MATERIALS"] = "LIST_OF_MATERIALS";
    FoldersDocumentType["ENVIRONMENTAL_DECLARATION"] = "ENVIRONMENTAL_DECLARATION";
    FoldersDocumentType["ORDER_CONFIRMATION"] = "ORDER_CONFIRMATION";
    FoldersDocumentType["CHECKLIST"] = "CHECKLIST";
    FoldersDocumentType["ELECTRICAL_PANEL_DOCUMENTATION"] = "ELECTRICAL_PANEL_DOCUMENTATION";
    FoldersDocumentType["GUIDANCE"] = "GUIDANCE";
    FoldersDocumentType["PERFORMANCE_DECLARATION"] = "PERFORMANCE_DECLARATION";
    FoldersDocumentType["COMPLIANCES"] = "COMPLIANCES";
    FoldersDocumentType["COMPONENT_AND_INSTALLATION_DOCUMENTATION"] = "COMPONENT_AND_INSTALLATION_DOCUMENTATION";
})(FoldersDocumentType || (FoldersDocumentType = {}));
