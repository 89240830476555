import { FC } from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';
import { HiOutlineSparkles } from 'react-icons/hi';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { RiDeleteBinLine } from 'react-icons/ri';

import { Tab } from '@/organisms/tabs/tab';
import { BlobFilter } from '@/util/types';

export enum DOCUMENT_STATUS {
  ANALYZED = 'ANALYZED',
  NOT_ANALYZED = 'NOT_ANALYZED',
  DUPLICATE = 'DUPLICATE',
  SUGGESTION = 'SUGGESTION',
}

export const mapStatusToText = (value: BlobFilter) => {
  switch (value) {
    case BlobFilter.PARSED:
      return {
        icon: FaRegCheckCircle,
        text: DOCUMENT_STATUS.ANALYZED,
      };
    case BlobFilter.DUPLICATE:
      return {
        icon: HiOutlineDocumentDuplicate,
        text: DOCUMENT_STATUS.DUPLICATE,
      };
    case BlobFilter.UNPARSED:
      return {
        icon: RiDeleteBinLine,
        text: DOCUMENT_STATUS.NOT_ANALYZED,
      };
    case BlobFilter.SUGGESTION:
      return {
        icon: HiOutlineSparkles,
        text: DOCUMENT_STATUS.SUGGESTION,
      };
  }
};

interface DocumentTabsProps {
  onClick?: () => void;
  keys: BlobFilter[];
  selectedTab: BlobFilter;
  handleTabClick: (tab: BlobFilter) => void;
  blobStatusCount: Record<string, number>;
}

export const DocumentTabs: FC<DocumentTabsProps> = ({
  onClick,
  keys,
  selectedTab,
  handleTabClick,
  blobStatusCount,
}) => {
  return (
    <>
      {keys.map((tab: BlobFilter) => (
        <Tab
          key={tab}
          label={`document-list.${mapStatusToText(tab)?.text?.toLowerCase()}`}
          selected={selectedTab === tab}
          onClick={() => {
            onClick?.();
            handleTabClick(tab);
          }}
          count={blobStatusCount[tab?.toLowerCase()]}
          icon={mapStatusToText(tab)?.icon}
        />
      ))}
    </>
  );
};
