import {
  useProjectId,
  useServiceName,
  usePackageId,
  useGetPackagesByServiceIdQuery,
} from '@consigli/hooks';

import { PackageList, convertServiceNameToId } from './package-list';

export const DocumentPackage = () => {
  const projectId = useProjectId();
  const serviceName = useServiceName();
  const packageId = usePackageId();
  const { data: packages = [] } = useGetPackagesByServiceIdQuery({
    projectId,
    serviceId: convertServiceNameToId(serviceName),
  });

  return <PackageList packages={packages} id={packageId} />;
};
