import { t } from 'i18next';

export const SupportedFileFormats = () => {
  return (
    <div>
      <strong>{t('file-upload.supported-file-types')}</strong>
      <ul className="list-disc list-inside">
        <li>{t('file-upload.image-formats')}</li>
        <li>{t('file-upload.ifc-formats')}</li>
        <li>{t('file-upload.document-formats')}</li>
      </ul>
    </div>
  );
};
