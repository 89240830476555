import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
interface FindingsAccordionListItemProps {
  conflictType: string;
  conflictTypeCount: number;
  onItemClick: () => void;
  isActive: boolean;
}

export const FindingsAccordionListItem = ({
  conflictType,
  conflictTypeCount,
  onItemClick,
  isActive,
}: FindingsAccordionListItemProps) => {
  const { t } = useTranslation();
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => void 0}
      className={clsx(
        'text-sm cursor-pointer p-2 m-2 text-day-neutral-subtle rounded-lg hover:rounded-lg',
        {
          'font-bold bg-day-light-3': isActive,
        },
        'hover:bg-day-light-3',
      )}
      onClick={onItemClick}
    >
      <span className="px-2 inline-block">
        {t(`dashboard.conflict-title.${conflictType}`)} ({conflictTypeCount})
      </span>
    </div>
  );
};
