import { documentPackageSchema, projectSchema } from '@consigli/types';
import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
export const getDashboardDataResponseSchema = z
    .object({
    files_count: z.object({
        all: z.number(),
        processed: z.number(),
        duplicates: z.number(),
    }),
    actions_pie_chart: z.record(z.string(), z.number()),
    conflict_types_count: z.record(z.string(), z.array(z.record(z.number()))),
})
    .strict()
    .transform(mapToCamelCase);
export const getDashboardDataRequestSchema = z
    .object({
    project_id: projectSchema.innerType().shape.id,
    package_id: documentPackageSchema.innerType().shape.id,
})
    .strict()
    .transform(mapToCamelCase);
