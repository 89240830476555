import { Spinner } from '@consigli/facade';
import { useInvites, useMyUser } from '@consigli/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UserProfileInviteCard } from './user-profile-invite-card';

export const UserProfileInviteList: FC = () => {
  const { t } = useTranslation();
  const { user } = useMyUser();
  const { invites, paginatedCount, isLoading } = useInvites({ page: 'all', recipient: user.email });

  if (isLoading) {
    return <Spinner />;
  }

  if (paginatedCount === 0) {
    return null;
  }

  return (
    <div className="flex flex-col mb-4">
      <div className="mb-4 text-lg text-day-neutral-subtle">{t('profile.pending-invites')}</div>
      {invites.map((invite) => (
        <UserProfileInviteCard
          key={invite.id}
          id={invite.id}
          expiresAt={invite.expiresAt}
          organization={invite.organization}
          projects={invite.projects}
        />
      ))}
    </div>
  );
};
