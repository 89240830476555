import { useMyUser } from '@consigli/hooks';
import {
  type KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
  type FC,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ViewerMode, useViewerContext } from '@/contexts/use-viewer-context';
import { ChatMessages } from '@/molecules/chat-messages';
import { type Message } from '@/util/interfaces';

import { DocumentFullscreenContainer } from './document-viewer/fullscreen/document/container';

interface ChatProps {
  loading: boolean;
  loadingMessage: boolean;
  disabled: boolean;
  messages: Message[];
  onMessage: (message: Message) => void;
}

export const Chat: FC<ChatProps> = ({ loading, loadingMessage, disabled, messages, onMessage }) => {
  const { t } = useTranslation();
  const { user } = useMyUser();
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { mode } = useViewerContext();

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages]);

  const sendMessage = useCallback(() => {
    if (message === '') return;

    onMessage({
      id: crypto.randomUUID(),
      body: message,
      authorId: user.id,
      createdAt: new Date(),
    });
    setMessage('');
  }, [user, message, onMessage]);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    ({ key, metaKey, ctrlKey, altKey, shiftKey }) => {
      if (key === 'Enter' && !metaKey && !ctrlKey && !altKey && !shiftKey) {
        sendMessage();
      }
    },
    [sendMessage],
  );

  return (
    <div className="flex flex-col grow w-full bg-white shadow-xl overflow-hidden">
      <div className="flex flex-col grow min-h-[200px] max-h-full h-0 p-4 overflow-auto text-lg">
        <ChatMessages loading={loading} disabled={disabled} messages={messages} />
        <div ref={messagesEndRef} />
      </div>

      <div className="bg-day-light-3 p-4">
        <input
          className="flex items-center h-10 w-full rounded px-3 text-sm bg-day-light-4 text-day-neutral-dark"
          type="text"
          value={message}
          onChange={(evt) => setMessage(evt.target.value)}
          onKeyDown={onKeyDown}
          disabled={disabled || loading || loadingMessage}
          placeholder={t('prop-chat.enter-message')}
        />
      </div>
      {mode === ViewerMode.DocumentFullscreen && <DocumentFullscreenContainer />}
    </div>
  );
};
