import { useState } from 'react';

import { AccordionContent } from './accordion-content';
import { AccordionHeader } from './accordion-header';

interface AccordionProps {
  index: number;
  onClick?: () => void;
  header: () => JSX.Element;
  children: React.ReactNode;
  disabled?: boolean;
  classNames?: string;
}

export const MultiOpenAccordion = ({
  onClick,
  header,
  children,
  disabled = false,
  classNames,
}: AccordionProps) => {
  const [opened, setOpened] = useState(false);
  const handleClick = () => {
    if (!disabled) {
      setOpened((prev) => !prev);
    }
    onClick && onClick();
  };

  return (
    <div className="shadow mb-4 rounded-md">
      <AccordionHeader
        open={opened}
        onClick={handleClick}
        header={header}
        disabled={disabled}
        classNames={classNames}
      ></AccordionHeader>
      <AccordionContent open={opened}>{children}</AccordionContent>
    </div>
  );
};
