import {
  Blob,
  Finding,
  Country,
  ProjectConstructionType,
  ProjectInvoiceTo,
  ProjectSpecification,
  ServiceName,
  Project,
  ProjectRole,
  ServicePermissionName,
} from '@consigli/types';

export enum BlobFilter {
  UNPARSED = 'UNPARSED',
  PARSED = 'PARSED',
  DUPLICATE = 'DUPLICATE',
  SUGGESTION = 'SUGGESTION',
}

export type ProjectInputs = {
  name: string;
  address: string;
  country: Country;
  projectType: number;
  specifications: ProjectSpecification[];
  constructionTypes: ProjectConstructionType[];
  orgNumber: number;
  billingInformation: string;
  billingAddress: string;
  invoiceTo: ProjectInvoiceTo;
};

export type PopupLayoutChildProps = { close: () => void };

export type TabConfig = {
  href: (serviceName: ServiceName, projectId: Project['id'], serviceId: string) => string;
  name: string;
};

export type InviteType = {
  role: ProjectRole;
  permissions: ServicePermissionName[];
  id: number;
  name: string | undefined;
};

export type FolderDocumentsType = Blob & { checked: boolean };

export type CheckableFinding = Finding & { checked: boolean };

export type PieChartDataType = {
  title: string;
  value: number;
  color: string;
};
