import { useMemo } from 'react';
import { useGetUsersQuery } from '../api';
export const useUsers = (params) => {
    const { data: usersResponse, isLoading, isFetching, isError, error } = useGetUsersQuery(params);
    return useMemo(() => {
        const paginatedCount = usersResponse?.count || 0;
        const users = usersResponse?.results || [];
        const next = usersResponse?.next;
        const previous = usersResponse?.previous;
        return {
            users,
            paginatedCount,
            next,
            previous,
            isLoading,
            isFetching,
            isError,
            error,
        };
    }, [usersResponse, isLoading, isFetching, isError, error]);
};
