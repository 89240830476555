import { packageQuerySchema, packageStatusSchema, questionSchema } from '@consigli/types';
import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
const packageChatRequestSchema = z
    .object({
    project_id: z.number().int(),
    package_id: z.string().uuid(),
})
    .strict()
    .transform(mapToCamelCase);
export const packageChatStatusResponseSchema = packageStatusSchema;
export const packageChatStatusRequestSchema = packageChatRequestSchema;
export const packageChatQueryResponseSchema = packageQuerySchema;
export const packageChatQueryRequestSchema = questionSchema;
