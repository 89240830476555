import { useMemo } from 'react';
import { useGetBlobsQuery } from '../api';
/**
 * Returns the blobs from the given projectId and packageId, that also fits the other params.
 * Our most flexible hook for fetching multiple blobs.
 * @param params an object with the parameters to fetch blobs.
 *
 * The param object contains the following properties:
 * @param project_id the project id that the blobs belong to.
 * @param package_id the package id within the project, that the blobs belong to.
 * @param page the page to fetch. (pagination)
 * @param page_size the number of blobs to fetch per page.
 * @param search
 * @param search_parsed_data
 * @param status
 * @param suggestion
 * @param standard
 * @param document_type
 *
 * @returns an array with blob, loading state, fetching state, error state and error object.
 */
export const useBlobs = (params) => {
    const { data: blobsResponse, isLoading, isFetching, isError, error } = useGetBlobsQuery(params);
    return useMemo(() => {
        const paginatedCount = blobsResponse?.count || 0;
        const blobs = blobsResponse?.results || [];
        const next = blobsResponse?.next;
        const previous = blobsResponse?.previous;
        return {
            blobs,
            paginatedCount,
            next,
            previous,
            isLoading,
            isFetching,
            isError,
            error,
        };
    }, [blobsResponse, isLoading, isFetching, isError, error]);
};
