import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { Route } from '@/routes';

import { InviteUserForm } from './invite-user-form';

export const InviteUser = () => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb label={t('invite-user.invite-user')} to={`/${Route.INVITE_USER}`} />
      <div className="flex flex-col w-full max-w-[600px] justify-center align-center p-12 mx-auto">
        <InviteUserForm />
      </div>
    </>
  );
};
