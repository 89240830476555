import { LoadError } from '@react-pdf-viewer/core';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

type PDFViewerErrorProps = {
  error: LoadError;
};

export const PDFViewerError: FC<PDFViewerErrorProps> = ({ error: { name } }) => {
  const { t } = useTranslation();

  let errorMsg: string;
  switch (name) {
    case 'InvalidPDFException':
      errorMsg = t('document-viewer.invalid-pdf');
      break;
    case 'MissingPDFException':
      errorMsg = t('document-viewer.missing-pdf');
      break;
    default:
      errorMsg = t('document-viewer.not-available-pdf');
      break;
  }

  return <div className="flex flex-col justify-center items-center h-full text-lg">{errorMsg}</div>;
};
