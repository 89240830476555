import { Button, Spinner } from '@consigli/facade';
import { useTranslation } from 'react-i18next';
import { MdOutlineFileDownload } from 'react-icons/md';

interface DownloadSelectedProps {
  onClickDownloadSelected: () => void;
  isLoading: boolean;
}

export const DownloadSelected = ({ onClickDownloadSelected, isLoading }: DownloadSelectedProps) => {
  const { t } = useTranslation();
  return (
    <Button
      secondary
      rounded
      className="font-semibold"
      disabled={isLoading}
      onClick={onClickDownloadSelected}
      icon={isLoading ? () => <Spinner size="xsmall" /> : () => <MdOutlineFileDownload size={18} />}
    >
      {t('document-list.download-selected')}
    </Button>
  );
};
