import { ProjectAccess, ProjectRole } from '@consigli/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';

export const UserProfileProjectAccessCard: FC<ProjectAccess> = ({ name, role, services }) => {
  const { t } = useTranslation();

  const translatedServices = services.map((service, index) => (
    <span key={service}>
      {t(`profile.services.${service}`)}
      {index < services.length - 1 ? ', ' : ''}
    </span>
  ));

  return (
    <div className="flex flex-col p-4 mt-2 rounded border border-day-secondary bg-day-light-3">
      <b className="mb-2">{name}</b>
      <div>
        {role == ProjectRole.ADMIN && (
          <div className="flex items-center mb-2">
            <IoShieldCheckmarkOutline title="AdminIcon" />
            <b className="ml-2 text-xs">{t('profile.project-admin')}</b>
          </div>
        )}
      </div>
      <div className="flex">
        <p className="text-xs text-day-neutral-subtle whitespace-nowrap">
          {t('profile.service-access')}
        </p>
        <b className="ml-1 text-xs">
          {services.length > 0 ? translatedServices : t('profile.all-services')}
        </b>
      </div>
    </div>
  );
};
