import { DocumentsType } from '@consigli/types';
import clsx from 'clsx';
import { Highlight } from 'react-highlighter-ts';
import { useTranslation } from 'react-i18next';

import { AcceptSuggestionAction } from './action-accept-suggestion';
interface DocumentCardProps {
  document: DocumentsType;
  selected: string;
  searchText?: string;
}

export const DocumentCardSuggestion = ({ document, selected, searchText }: DocumentCardProps) => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-3 gap-x-4 gap-y-2">
      <div className="col-span-1">
        <div className="flex flex-col">
          <div
            className={clsx(
              'text-day-neutral-subtle',
              selected === document.id && 'text-white',
              'text-xs mb-1',
            )}
          >
            {t('document-list.current-name')}
          </div>
          <div
            className={clsx(
              selected === document.id ? 'text-white' : 'day-neutral-dark',
              'text-base font-semibold truncate',
            )}
          >
            {/* @ts-expect-error - As event handlers which don't exist for React are required */}
            <Highlight
              placeholder=""
              search={searchText}
              className={clsx(
                'line-through text-day-neutral-subtle',
                selected === document.id && 'text-white',
              )}
            >
              {document.name}
            </Highlight>
          </div>
        </div>
      </div>
      <div className="col-span-1 flex flex-col truncate">
        <div
          className={clsx(
            selected === document.id ? 'text-white' : 'text-day-neutral-subtle',
            'text-xs mb-1',
          )}
        >
          {t('document-list.suggested-name')}
        </div>
        <div
          className={clsx(
            selected === document.id ? 'text-white' : 'text-day-neutral-dark',
            'text-base font-semibold truncate',
          )}
        >
          {document.nameSuggestion}
        </div>
      </div>
      <div className="col-span-1 w-fit ml-auto">
        {document.nameSuggestion && (
          <AcceptSuggestionAction blobId={document.id} nameSuggestion={document.nameSuggestion} />
        )}
      </div>
    </div>
  );
};
