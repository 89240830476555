import { useCurrentLanguage } from './use-current-language';
import { usePackageId } from './use-package-id';
import { useProjectId } from './use-project-id';
import { useGetPackageQuery } from '../api';
export const useMessageContext = (finding) => {
    const currentLanguage = useCurrentLanguage();
    const projectId = useProjectId();
    const packageId = usePackageId();
    const { data: pkg } = useGetPackageQuery({
        projectId,
        packageId,
    });
    const packageLanguage = pkg?.language;
    if (!finding) {
        return {};
    }
    const messageContext = currentLanguage === packageLanguage || Object.keys(finding.messageContextEn).length === 0
        ? finding.messageContext
        : finding.messageContextEn;
    return messageContext;
};
