import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect, } from 'react';
import { getSessionFromStorage } from './oauth';
export const AUTH_STATE_CHANGE_EVENT = new Event('auth-state-change', {
    cancelable: false,
    bubbles: false,
});
const AuthContext = createContext(null);
export const useAuthContext = () => {
    const ctx = useContext(AuthContext);
    if (!ctx) {
        throw new Error('Missing AuthProvider in tree');
    }
    return ctx;
};
export const AuthProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    // Whenever this event is triggered, we should refresh the auth state. This is set up so
    // non-react objects such as our logout() methods can cause triggers in the React app.
    useEffect(() => {
        const onAuthorizationStorageStateChange = () => {
            const session = getSessionFromStorage();
            setSession(session);
        };
        window.addEventListener(AUTH_STATE_CHANGE_EVENT.type, onAuthorizationStorageStateChange);
        return () => window.removeEventListener(AUTH_STATE_CHANGE_EVENT.type, onAuthorizationStorageStateChange);
    }, []);
    const ctx = {
        session,
        setSession,
    };
    return _jsx(AuthContext.Provider, { value: ctx, children: children });
};
