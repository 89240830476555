import { useServiceNameOrNull } from './use-service-name-or-null';
/**
 * @returns the serviceName from the current route (url)
 */
export const useServiceName = () => {
    const serviceName = useServiceNameOrNull();
    if (serviceName === null) {
        throw new Error(`useServiceName called on route without :serviceName`);
    }
    return serviceName;
};
