import { useMemo } from 'react';
import { useGetBlobsNSCountQuery } from '../api';
export const useBlobsNSCount = (params) => {
    const { data, isLoading, isFetching, isError, error } = useGetBlobsNSCountQuery(params);
    return useMemo(() => {
        return {
            data,
            isLoading,
            isFetching,
            isError,
            error,
        };
    }, [data, isLoading, isFetching, isError, error]);
};
