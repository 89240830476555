import { useMemo } from 'react';
import { useGetLayoutOptimizationResultsQuery } from '../api';
/**
 * Hook to get the layout optimization results for a specific layout within a project.
 */
export const useLayoutResults = (params) => {
    const { data: resultsResponse, isLoading, isFetching, isError, error, } = useGetLayoutOptimizationResultsQuery(params);
    return useMemo(() => {
        const totalCount = resultsResponse?.count || 0;
        const results = resultsResponse?.results || [];
        const next = resultsResponse?.next;
        const previous = resultsResponse?.previous;
        return {
            results,
            totalCount,
            next,
            previous,
            isLoading,
            isFetching,
            isError,
            error,
        };
    }, [resultsResponse, isLoading, isFetching, isError, error]);
};
