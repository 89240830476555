import { Button, Spinner } from '@consigli/facade';
import { useProjectId, useUpdateBatchBlobsMutation } from '@consigli/hooks';
import { DocumentsType } from '@consigli/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa6';
import { toast } from 'react-toastify';

interface AcceptSuggestionsProps {
  files: DocumentsType[];
  isLoading: boolean;
  packageId: string;
}

export const AcceptSuggestions = ({ files, isLoading, packageId }: AcceptSuggestionsProps) => {
  const { t } = useTranslation();
  const [updateBatchBlobs] = useUpdateBatchBlobsMutation();
  const projectId = useProjectId();
  const updateNames = useCallback(
    async (files: DocumentsType[]) => {
      try {
        await updateBatchBlobs({
          projectId,
          packageId,
          data: files.map((file) => ({
            id: file.id,
            name: file.nameSuggestion,
            name_suggestion: null,
          })),
        }).unwrap();
        toast.success(t('document-list.success-multiple'));
      } catch (e) {
        toast.error(t('document-list.failed-multiple'));
      }
    },
    [packageId, projectId, updateBatchBlobs, t],
  );
  return (
    <Button
      secondary
      rounded
      className="font-semibold"
      disabled={isLoading}
      onClick={() => updateNames(files)}
      icon={isLoading ? () => <Spinner size="xsmall" /> : () => <FaCheck size={18} />}
    >
      {t('document-list.accept-suggestions')}
    </Button>
  );
};
