import clsx from 'clsx';
import { FC, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

interface TabProps {
  label: string;
  selected: boolean;
  onClick: () => void;
  count?: number;
  icon?: FC<SVGProps<SVGSVGElement>> | undefined;
}
export const Tab = (props: TabProps) => {
  const { label, selected, onClick, count, icon } = props;
  const { t } = useTranslation();
  const IconComponent = icon ?? (() => <></>);

  return (
    <span
      className={clsx(
        'flex items-center mt-4 pb-4 px-4 text-sm border-b-2 cursor-pointer font-medium first-letter:capitalize',
        selected ? ' font-semibold border-b-2 border-day-primary' : 'border-day-secondary',
        'transition-colors duration-300 ease-in-out hover:border-b-2 text-day-neutral-dark',
      )}
      onClick={onClick}
      aria-hidden
    >
      <div className="mr-2" title={t(label)}>
        <IconComponent fontSize={20}></IconComponent>
      </div>
      <span>{t(label)}</span>
      <span>{count ? ` (${count}) ` : ''}</span>
    </span>
  );
};
