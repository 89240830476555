import { useMemo } from 'react';
import { useGetProjectsQuery } from '../api';
/**
 * Hook to get all projects.
 *
 * @param params the parameters to fetch projects.
 * @param params.page the page number to fetch.
 * @param params.pageSize the page size to fetch.
 * @param params.search
 *
 * @returns an object with a list of projects, loading state, error state and error object.
 */
export const useProjects = (params) => {
    const { data: projectsResponse, isLoading, isFetching, } = useGetProjectsQuery(params, { refetchOnMountOrArgChange: true });
    return useMemo(() => {
        const paginatedCount = projectsResponse?.count || 0;
        const projects = projectsResponse?.results || [];
        const next = projectsResponse?.next;
        const previous = projectsResponse?.previous;
        return {
            projects,
            paginatedCount,
            next,
            previous,
            isLoading,
            isFetching,
        };
    }, [projectsResponse, isLoading, isFetching]);
};
