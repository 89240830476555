import { Button } from '@consigli/facade';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineEdit } from 'react-icons/md';

import { FolderDocumentsType } from '@/util/types';

import { EditOperationsFilesPopup } from './edit-files-popup';

interface EditOperationsFilesButtonProps {
  documents: FolderDocumentsType[];
  setShowDownloadPopup?: Dispatch<SetStateAction<boolean>>;
}

export const EditOperationsFilesButton: FC<EditOperationsFilesButtonProps> = ({
  documents,
  setShowDownloadPopup,
}) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const isMultiple = documents.length > 1;
  const { t } = useTranslation();

  return (
    <>
      <Button
        secondary
        rounded
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          setShowEditPopup(true);
        }}
        title={t('folders.edit-selected')}
        icon={MdOutlineEdit}
        iconSize={isMultiple ? 16 : 23}
      >
        {isMultiple ? t('folders.edit-selected') : ''}
      </Button>
      {showEditPopup && (
        <EditOperationsFilesPopup
          onClose={() => {
            setShowEditPopup(false);
            setShowDownloadPopup && setShowDownloadPopup(false);
          }}
          documents={documents}
          isMultiple={isMultiple}
        />
      )}
    </>
  );
};
