import { CustomSelect, Label, type OptionType, isOptionType } from '@consigli/facade';
import { useMemo, type FC, useCallback } from 'react';
import { Control, ControllerRenderProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProjectInputs } from '@/util/types';

import type { MultiValue, SingleValue } from 'react-select';

interface ProjectTypeProps {
  control: Control<ProjectInputs>;
}

export const ProjectType: FC<ProjectTypeProps> = ({ control }) => {
  const { t } = useTranslation();

  const projectTypeChoices: OptionType[] = useMemo(
    () => [
      {
        value: 1,
        label: t('project-type.design-and-build'),
      },
      {
        value: 2,
        label: t('project-type.partnering-alliancing'),
      },
      {
        value: 3,
        label: t('project-type.traditional-procurement'),
      },
      {
        value: 4,
        label: t('project-type.main-contractor'),
      },
      {
        value: 5,
        label: t('project-type.subcontracting-firms'),
      },
      {
        value: 6,
        label: t('project-type.construction-management'),
      },
      {
        value: 8,
        label: t('project-type.other'),
      },
    ],
    [t],
  );

  const { field, fieldState } = useController({
    name: 'projectType',
    control,
    rules: { required: `${t('project-creation.project-type-required')}*` },
  });

  const onProjectTypeChange = useCallback(
    (
      newValue: SingleValue<OptionType> | MultiValue<OptionType>,
      field: ControllerRenderProps<ProjectInputs, 'projectType'>,
    ): void => {
      if (newValue !== null && isOptionType(newValue)) {
        field.onChange(newValue && newValue.value);
      } else {
        throw new Error('Project type is not number!');
      }
    },
    [],
  );

  return (
    <Label text={t('project-creation.project-type-label')}>
      <CustomSelect
        onBlur={field.onBlur}
        onChange={(newValue: SingleValue<OptionType> | MultiValue<OptionType>): void => {
          onProjectTypeChange(newValue, field);
        }}
        placeholder={t('project-creation.project-type-placeholder')}
        options={projectTypeChoices}
        value={projectTypeChoices.find(
          (projectType: OptionType) => projectType.value === field.value,
        )}
      />
      {fieldState.invalid && (
        <div className={'text-sm my-1 text-error-dark'}>{fieldState.error?.message}</div>
      )}
    </Label>
  );
};
