// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const never = (_) => {
    throw new Error('Invalid param passed to never-function. This function should never be called.');
};
export const notNull = (value, debugName) => {
    if (value == null) {
        throw new Error(`Nullish value passed to nonNull check! ${debugName}`);
    }
    return value;
};
const isOneOf = (value, legalValues) => {
    for (const legalValue of legalValues) {
        if (value === legalValue) {
            return true;
        }
    }
    return false;
};
export const oneOf = (value, legalValues) => {
    if (!isOneOf(value, legalValues)) {
        throw new Error(`Invalid value '${value} is not one of ${legalValues}'`);
    }
    return value;
};
