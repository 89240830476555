import clsx from 'clsx';
import { FC, ReactNode } from 'react';

interface MultipleSelectionDialogProps {
  children: ReactNode;
  className?: string;
}

export const MultipleSelectionDialog: FC<MultipleSelectionDialogProps> = ({
  children,
  className,
}) => {
  if (!children) {
    return null;
  }
  return (
    <div
      className={clsx(
        'absolute p-4 bg-day-light-4 text-day-neutral-dark shadow-md flex flex-col rounded left-1/2 top-3/4 transform -translate-x-1/2 -translate-y-1/2 z-10',
        className,
      )}
    >
      {children}
    </div>
  );
};
