/**
 * Support for Search regexp'es that ignore whitespaces
 * @see https://consigli.atlassian.net/browse/PM-692 for motivation
 */

/** Make a regexp from a string */
const matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;
const escapeStringRegExp = (str: string) => str.replace(matchOperatorsRe, '\\$&');

/** Control characters are matched against anything .? */
/** @todo this should be improved. Also be aware that we escapeStringRegExp BEFORE calling this, so take care not to strip the above */
export const ignoreNonAlphanumeric = (str: string) => str.replace(/[-,¨]/g, '.*');

export const regexpIgnoreWhitespaces = (query: string) => {
  const regexp = new RegExp(
    ignoreNonAlphanumeric(escapeStringRegExp(query)).replace(/\s+/g, '\\s*'),
    'gi',
  );
  return regexp;
};
