import clsx from 'clsx';
import { ReactNode } from 'react';

interface CardProps {
  selected: boolean;
  onClick: () => void;
  children: ReactNode;
}

export const Card = (props: CardProps) => {
  const { selected, onClick, children } = props;

  return (
    <div
      className={clsx(
        'p-4 mb-2 rounded-lg shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_3px_0_rgba(0,0,0,0.10)] flex flex-col justify-between cursor-pointer relative z-[10]',
        selected ? 'bg-day-dark-1 text-neutral-on-brand' : 'bg-day-light-5 text-day-neutral-dark',
      )}
      role="button"
      tabIndex={0}
      onKeyDown={() => void 0}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
