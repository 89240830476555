import { useState, useEffect } from 'react';
/**
 * Hook that returns a boolean that is true if the window width is greater than or equal to the given breakpoint.
 * can be used for responsive design purposes.
 *
 * @param breakpoint a number that represents the width of the window.
 *
 * @returns a boolean
 * */
export const useBreakpoint = (breakpoint) => {
    const [breakpointThreshold, setBreakpointThreshold] = useState(window.innerWidth >= breakpoint);
    useEffect(() => {
        const handleWindowResize = () => {
            setBreakpointThreshold(window.innerWidth >= breakpoint);
        };
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [breakpoint]);
    return breakpointThreshold;
};
