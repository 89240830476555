import { mapToCamelCase, mapToSnakeCase } from '@consigli/utils';
import { z } from 'zod';
import { fileSchema } from './file';
import { getPaginationResponseSchema } from './pagination';
import { projectSchema } from './project';
export var LayoutType;
(function (LayoutType) {
    LayoutType["FLOOR_PLAN"] = "FLOOR_PLAN";
    LayoutType["CEILING_PLAN"] = "CEILING_PLAN";
    LayoutType["PLANT_ROOM"] = "PLANT_ROOM";
    LayoutType["SPACE_ANALYSIS"] = "SPACE_ANALYSIS";
    LayoutType["PARKING"] = "PARKING";
    LayoutType["REPORTS"] = "REPORTS";
})(LayoutType || (LayoutType = {}));
export const layoutOptimizationSchema = z
    .object({
    id: z.string().uuid(),
    project_id: projectSchema.innerType().shape.id,
    name: z.string().min(1),
    type: z.nativeEnum(LayoutType),
    data: z.any(),
    updated_at: z.string(),
    created_at: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const getLayoutOptimizationsResponseSchema = z
    .array(layoutOptimizationSchema)
    .transform(mapToCamelCase);
export const getLayoutOptimizationsRequestSchema = z
    .object({
    project_id: z.number().int(),
    type: z.nativeEnum(LayoutType).optional(),
})
    .strict()
    .transform(mapToCamelCase);
export const getLayoutOptimizationFilesRequestSchema = z
    .object({
    project_id: z.number().int(),
    layout_id: z.string().uuid(),
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    search: z.string().nullish(),
})
    .strict()
    .transform(mapToCamelCase);
export const getLayoutOptimizationFilesResponseSchema = getPaginationResponseSchema(fileSchema);
export const createLayoutOptimizationResponseSchema = layoutOptimizationSchema;
export const createLayoutOptimizationRequestSchema = layoutOptimizationSchema
    .innerType()
    .pick({
    name: true,
    data: true,
    type: true,
})
    .merge(z.object({ file_ids: z.array(fileSchema.innerType().shape.id) }))
    .strict()
    .transform(mapToSnakeCase);
