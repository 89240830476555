import { LoadingArea, Spinner } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useCurrentLanguage,
  useFindings,
  useLazyGetFindingsQuery,
  useGetFindingsUniqueBlobsQuery,
  useGetFindingsActionCountQuery,
  useGetFindingsConflictTypeCountsQuery,
  useGetFindingsCommentersQuery,
} from '@consigli/hooks';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useViewerContext, ViewerMode } from '@/contexts/use-viewer-context';
import { CommentSectionWrapper } from '@/organisms/comment-section/comment-section-wrapper';
import { DocumentPreviewContainer } from '@/organisms/document-viewer/document-preview';
import { FindingsFullscreenContainer } from '@/organisms/document-viewer/fullscreen/finding/container';
import { WorkspaceSidebar } from '@/organisms/sidebar/workspace-sidebar';

import { FindingsContent } from './findings-content';
import { useFindingsContext } from './findings-context';
import { FindingsSidebar } from './findings-sidebar';

export interface FindingBlob {
  id: string;
  name: string;
}

export const Findings: FC = () => {
  const projectId = useProjectId();
  const packageId = usePackageId();
  const currentLanguage = useCurrentLanguage();
  const { t } = useTranslation();
  const { mode, setMode } = useViewerContext();
  const {
    page,
    pageSize,
    selectedActionTab,
    setSelectedActionTab,
    selectedConflictCategory,
    selectedConflictType,
    selectedBlobIds,
    debouncedSearchText,
    selectedCommenterIds,
  } = useFindingsContext();

  const {
    findings,
    paginatedCount,
    isLoading,
    isFetching: isFetchingFindings,
  } = useFindings({
    projectId,
    packageId,
    page: page,
    pageSize,
    actionType: Number(selectedActionTab),
    conflictCategory: selectedConflictCategory,
    conflictType: selectedConflictType ? Number(selectedConflictType) : undefined,
    blobIds: selectedBlobIds,
    search: debouncedSearchText,
    language: currentLanguage,
    commenters: selectedCommenterIds,
  });

  const itemsOnPage = useMemo(() => {
    return page * pageSize > paginatedCount ? paginatedCount % pageSize : pageSize;
  }, [page, pageSize, paginatedCount]);

  const [getFindingsForExport, { isLoading: isDownloadingFindingsForExport }] =
    useLazyGetFindingsQuery();

  const downloadFindingsForExport = useCallback(
    async (isFilterNeeded: boolean) => {
      const findingsResponse = await getFindingsForExport({
        projectId,
        packageId,
        page: 'all',
        conflictCategory: isFilterNeeded ? selectedConflictCategory : undefined,
        conflictType:
          isFilterNeeded && selectedConflictType ? Number(selectedConflictType) : undefined,
        blobIds: isFilterNeeded ? selectedBlobIds : undefined,
      }).unwrap();
      return findingsResponse.results;
    },
    [
      getFindingsForExport,
      projectId,
      packageId,
      selectedConflictCategory,
      selectedConflictType,
      selectedBlobIds,
    ],
  );

  const { data: commenters } = useGetFindingsCommentersQuery({
    projectId,
    packageId,
    actionType: Number(selectedActionTab),
    conflictCategory: selectedConflictCategory,
    conflictType: selectedConflictType ? Number(selectedConflictType) : undefined,
    blobIds: selectedBlobIds,
  });

  const { data: uniqueBlobs } = useGetFindingsUniqueBlobsQuery({
    projectId,
    packageId,
    actionType: Number(selectedActionTab),
    conflictCategory: selectedConflictCategory,
    conflictType: selectedConflictType ? Number(selectedConflictType) : undefined,
    commenters: selectedCommenterIds,
  });

  const { data: actionCount, isFetching: isFetchingActionCount } = useGetFindingsActionCountQuery({
    projectId,
    packageId,
    conflictCategory: selectedConflictCategory,
    conflictType: selectedConflictType ? Number(selectedConflictType) : undefined,
    blobIds: selectedBlobIds,
    commenters: selectedCommenterIds,
  });

  const { data: conflictTypeCounts, isFetching: isFetchingConflictTypeCount } =
    useGetFindingsConflictTypeCountsQuery({
      projectId,
      packageId,
      blobIds: selectedBlobIds,
      commenters: selectedCommenterIds,
    });

  const actionTabs = useMemo(() => {
    const preferredTabOrder = ['2', '4', '1', '3'];
    if (actionCount) {
      return Object.keys(actionCount).sort((a, b) => {
        return preferredTabOrder.indexOf(a) - preferredTabOrder.indexOf(b);
      });
    }
    return [];
  }, [actionCount]);

  useEffect(() => {
    if (actionTabs.length > 0 && !actionTabs.includes(selectedActionTab)) {
      setSelectedActionTab(actionTabs[0]);
    }
  }, [actionTabs, selectedActionTab, setSelectedActionTab]);

  useEffect(() => {
    return () => {
      setMode(ViewerMode.Closed);
    };
  }, [setMode]);

  if (findings.length === 0 && actionTabs.length === 0)
    return (
      <div className="bg-day-light-3 w-full h-full flex flex-col items-center justify-center">
        <div className="text-day-neutral-subtle text-xl font-medium py-4">
          {t('riskassessment.no-findings')}
        </div>
      </div>
    );
  return (
    <>
      {isLoading ? (
        <LoadingArea title="Loading findings" />
      ) : (
        <div className="flex relative">
          <WorkspaceSidebar sidebarExpanded={false}>
            {isFetchingConflictTypeCount ? (
              <Spinner />
            ) : conflictTypeCounts ? (
              <FindingsSidebar
                conflictTypeCounts={conflictTypeCounts}
                downloadFindingsForExport={downloadFindingsForExport}
                isDownloadingFindingsForExport={isDownloadingFindingsForExport}
              />
            ) : null}
          </WorkspaceSidebar>
          {actionCount && uniqueBlobs && commenters && (
            <FindingsContent
              findings={findings}
              findingsCount={paginatedCount}
              uniqueBlobs={uniqueBlobs}
              commenters={commenters}
              actionTabs={actionTabs}
              actionCount={actionCount}
              itemsOnPage={itemsOnPage}
              isFetchingFindings={isFetchingFindings}
              isFetchingActionCount={isFetchingActionCount}
              downloadFindingsForExport={downloadFindingsForExport}
              isDownloadingFindingsForExport={isDownloadingFindingsForExport}
            />
          )}
          {mode === ViewerMode.DocumentPreview && <DocumentPreviewContainer />}
          {mode === ViewerMode.ReturnToDocumentPreview && <DocumentPreviewContainer />}
          {mode === ViewerMode.DocumentFullscreen && (
            <FindingsFullscreenContainer itemsOnPage={itemsOnPage} />
          )}
          {mode === ViewerMode.CommentPreview && <CommentSectionWrapper />}
        </div>
      )}
    </>
  );
};
