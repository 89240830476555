export const toCamelCase = (value) => {
    const bits = value.split('_');
    return bits.reduce((acc, curr, index) => {
        if (index === 0) {
            return curr;
        }
        const head = curr.substring(0, 1);
        const tail = curr.substring(1, curr.length);
        // don't need to worry about locale here because we're using it on object keys from our api
        // they are all ascii
        if (head === head.toUpperCase()) {
            return acc + '_' + head + tail;
        }
        return acc + head.toUpperCase() + tail;
    });
};
export const toSnakeCase = (value) => {
    let result = '';
    for (let i = 0; i < value.length; i++) {
        const ch = value[i];
        // don't worry about locale here either, it's all ascii
        const codePoint = ch.codePointAt(0);
        if (codePoint == null) {
            throw new Error('Cannot convert to snake case when string is empty!');
        }
        // ASCII uppercase and lowercase codepoints, this works because utf-16 low characters are ascii compatible
        const isTextualCharacter = (codePoint > 64 && codePoint < 92) || (codePoint > 96 && codePoint < 123);
        if (isTextualCharacter && ch === ch.toUpperCase()) {
            result += `_${ch.toLowerCase()}`;
        }
        else {
            result += ch;
        }
    }
    return result;
};
export const mapToCamelCase = (value) => {
    if (Array.isArray(value)) {
        return value.map(mapToCamelCase);
    }
    if (typeof value === 'object' && value !== null && value.constructor === Object) {
        const entries = Object.entries(value).map(([key, value]) => [
            toCamelCase(key),
            mapToCamelCase(value),
        ]);
        return Object.fromEntries(entries);
    }
    return value;
};
export const mapToSnakeCase = (value) => {
    if (Array.isArray(value)) {
        return value.map(mapToSnakeCase);
    }
    if (typeof value === 'object' && value !== null && value.constructor === Object) {
        const entries = Object.entries(value).map(([key, value]) => [
            toSnakeCase(key),
            mapToSnakeCase(value),
        ]);
        return Object.fromEntries(entries);
    }
    return value;
};
