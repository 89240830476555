import { LoadingArea } from '@consigli/facade';
import { useGetMyUserQuery } from '@consigli/hooks';
import { type FC, type PropsWithChildren } from 'react';

/** Auth will be the callback from OAuth. So where we'll retrieve the token and store it */
export const UserGuard: FC<PropsWithChildren> = ({ children }) => {
  const { data: user } = useGetMyUserQuery();

  return user ? (
    <>{children}</>
  ) : (
    <LoadingArea title="Checking authorization" className="mt-[40vh] mx-auto" />
  );
};
