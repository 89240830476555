import { mapToCamelCase, mapToSnakeCase } from '@consigli/utils';
import { z } from 'zod';
export var ExportStatus;
(function (ExportStatus) {
    ExportStatus["INITIALIZED"] = "INITIALIZED";
    ExportStatus["DONE"] = "DONE";
    ExportStatus["ERROR"] = "ERROR";
})(ExportStatus || (ExportStatus = {}));
export var ExportType;
(function (ExportType) {
    ExportType["FAMAC"] = "FAMAC";
})(ExportType || (ExportType = {}));
export const exportStatusSchema = z.nativeEnum(ExportStatus);
export const exportTypeSchema = z.nativeEnum(ExportType);
export const exportSchema = z
    .object({
    id: z.number().int(),
    document_package_id: z.string().uuid(),
    type: exportTypeSchema,
    status: exportStatusSchema,
    status_message: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const createExportSchema = exportSchema
    .innerType()
    .omit({
    id: true,
    document_package_id: true,
    status_message: true,
})
    .strict()
    .transform(mapToSnakeCase);
export const createExportResponseSchema = exportSchema.transform(mapToCamelCase);
export const getExportResponseSchema = z.array(exportSchema).transform(mapToCamelCase);
export const updateExportResponseSchema = exportSchema;
