import { OptionType } from '@consigli/facade';
import { Blob } from '@consigli/types';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { TreeNode } from '@/pages/folders/util/document-list-to-tree';

export const FileTreeContext = createContext<{
  activeFolderFiles: Blob[];
  setActiveFolderFiles: Dispatch<SetStateAction<Blob[]>>;
  tree: {
    children: Map<string, TreeNode>;
  };
  setTree: Dispatch<
    SetStateAction<{
      children: Map<string, TreeNode>;
    }>
  >;
  activeFolderId: string;
  setActiveFolderId: Dispatch<SetStateAction<string>>;
  summaryCount: Record<string, number> | undefined;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  selectedDocumentTypes: OptionType[];
  setSelectedDocumentTypes: Dispatch<SetStateAction<OptionType[]>>;
  selectedBlobIds: OptionType[];
  setSelectedBlobIds: Dispatch<SetStateAction<OptionType[]>>;
  searchContent: string;
  setSearchContent: Dispatch<SetStateAction<string>>;
  searchFileName: string;
  setSearchFileName: Dispatch<SetStateAction<string>>;
  selectedCard: string;
  setSelectedCard: Dispatch<SetStateAction<string>>;
} | null>(null);

export const useFileTreeContext = () => {
  const ctx = useContext(FileTreeContext);
  if (ctx === null) {
    throw new Error(`Called useFileTreeContext without Provider in tree`);
  }
  return ctx;
};
