import { ExcelExport, Finding } from '@consigli/types';
import { TFunction } from 'i18next';

export const createFindingsExcelExport = (
  findings: Finding[],
  t: TFunction,
  projectName: string,
): ExcelExport => {
  const title = projectName;
  const headers = [
    'ID',
    t('riskassessment.export.status'),
    t('riskassessment.export.conflict-type'),
    t('riskassessment.export.document'),
    t('riskassessment.export.comment'),
    t('riskassessment.export.page-number'),
    t('riskassessment.export.content'),
    t('riskassessment.export.ref-content'),
    t('riskassessment.export.ref-document'),
  ];
  const sortedFindings = [...findings].sort((a, b) => a.id - b.id);
  const data = sortedFindings.map((finding) => {
    return [
      finding.id,
      t(`riskassessment.status.${finding.action}`),
      t(`dashboard.conflict-title.${finding.typeOfConflict}`),
      finding.fileName ? finding.fileName : t('riskassessment.project-related-finding'),
      t(finding.messageTemplate, {
        ...finding.messageContext,
      }),
      finding.pageNumber ? finding.pageNumber : '',
      finding.content ? finding.content : '',
      finding.refContent ? finding.refContent : '',
      finding.refFileName ? finding.refFileName : '',
    ];
  });
  return { title, headers, data };
};
