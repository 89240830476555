import { useDraggable } from '@dnd-kit/core';
import clsx from 'clsx';
import { useMemo } from 'react';
import { Highlight } from 'react-highlighter-ts';
import { useTranslation } from 'react-i18next';
import { MdDragIndicator } from 'react-icons/md';

import { DownloadFileButton } from '@/components/common/download-file-button';
import { useFileTreeContext } from '@/contexts/use-file-tree-context';
import { DocumentCardParsedData } from '@/organisms/document-list/document-card-parsed-data';
import { convertFolderDocumentsTypeToDocumentsType } from '@/util/convert-folder-documents-type-to-documents-type';
import { mapExtensionToUppercase } from '@/util/document-check';
import { FolderDocumentsType } from '@/util/types';

import { EditOperationsFilesButton } from './edit-files-button';

interface FoldersCardProps {
  file: FolderDocumentsType;
  handleCheckboxClick: (record: FolderDocumentsType) => void;
  onClick?: () => void;
  selected?: boolean;
  dragOverlay?: boolean;
  selectedFiles?: FolderDocumentsType[];
}
export const FoldersCard = ({
  file,
  onClick,
  selected,
  dragOverlay,
  selectedFiles,
}: FoldersCardProps) => {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: file?.id,
  });
  const { searchContent } = useFileTreeContext();

  const count = useMemo(() => {
    const selectedIds = selectedFiles?.map((item: FolderDocumentsType) => item.id);
    return selectedIds?.includes(file.id) ? selectedFiles?.length : 0;
  }, [selectedFiles, file.id]);

  return (
    <div
      className={clsx(
        'p-4 my-2 rounded-lg shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_3px_0_rgba(0,0,0,0.10)] flex flex-col justify-between cursor-pointer relative',
        selected ? 'bg-day-dark-1' : 'bg-day-light-5',
      )}
      ref={setNodeRef}
      aria-hidden
      onClick={onClick}
    >
      <div className="flex flex-col md:flex-row justify-between relative">
        <div
          className="cursor-grab active:cursor-grabbing flex flex-row items-center mb-2 mr-4 md:mb-0 justify-between"
          {...listeners}
          {...attributes}
        >
          <MdDragIndicator size={28} color="#CBD5E1" title={t('folders.move-file')} />
        </div>
        <div className="flex flex-col md:flex-row flex-1">
          <div className="flex-1">
            <div
              className={clsx(
                'text-xs uppercase mb-1',
                selected ? 'text-white' : ' text-slate-500',
              )}
            >
              {mapExtensionToUppercase(file.extension)}
            </div>
            <div className="flex items-center gap-2">
              <div
                className={clsx(
                  'text-base font-semibold',
                  selected ? 'text-day-neutral-light' : 'text-day-neutral-dark',
                )}
              >
                {/* @ts-expect-error - As event handlers which don't exist for React are required */}
                <Highlight placeholder="" search={searchContent}>
                  <p>{file.name}</p>
                </Highlight>
              </div>
            </div>
            <DocumentCardParsedData
              initialParsedData={file.parsedData.data}
              remainingOccurrences={file.parsedData.remainingOccurrences}
              documentId={file.id}
              searchText={searchContent}
            />
          </div>
        </div>
        <div className="flex flex-row items-center md:mt-0 space-x-2">
          <EditOperationsFilesButton documents={[file]} />
          <DownloadFileButton document={convertFolderDocumentsTypeToDocumentsType(file)} />
        </div>
      </div>
      {dragOverlay && count ? (
        <div className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-day-dark-1 text-white text-sm font-semibold left-[-8px] top-[-8px]">
          {count}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
