import { GetDashboardDataResponse } from '@consigli/api-client';
import { LoadingArea } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useMyUser,
  useGetDashboardDataQuery,
  useServiceName,
  usePackageByServiceIdAndPackageId,
} from '@consigli/hooks';
import { ConflictCategory, ServiceId } from '@consigli/types';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkspaceLayout } from '@/components/layouts/workspace-layout/workspace-layout';
import { PieChart } from '@/organisms/pie-chart';
import { ServiceIdIndexValue } from '@/routes';
import { PieChartDataType } from '@/util/types';

import { AdminBanner } from './admin-banner';
import { DashboardCardNav } from './card-nav';
import { DashboardSearch } from './dashboard-search';
import { WaitingForAnalysis } from './waiting-for-analysis';

type ConflictData = [
  keyof GetDashboardDataResponse['conflictTypesCount'],
  GetDashboardDataResponse['conflictTypesCount'][keyof GetDashboardDataResponse['conflictTypesCount']],
];

export const conflictCategoryToSortIndex = (
  conflictCategory: keyof GetDashboardDataResponse['conflictTypesCount'],
): number => {
  switch (conflictCategory) {
    case ConflictCategory.MISSING_DOCUMENTATION:
      return 1;
    case ConflictCategory.RISK:
      return 2;
    case ConflictCategory.INSIGHT:
      return 3;
    case ConflictCategory.RENTAL_AGREEMENT:
      return 4;
    case ConflictCategory.COMPANY:
      return 5;
    case ConflictCategory.PROPERTY:
      return 6;
    default:
      return 99;
  }
};

export const Dashboard: FC = () => {
  const projectId = useProjectId();
  const packageId = usePackageId();
  const serviceName = useServiceName();
  const { t } = useTranslation();
  const { user } = useMyUser();

  const { pkg } = usePackageByServiceIdAndPackageId(
    projectId,
    ServiceIdIndexValue[serviceName] as ServiceId,
    packageId,
  );
  const isPublished = pkg ? pkg.published : false;
  const { data: rawData } = useGetDashboardDataQuery({ projectId, packageId });

  const [dashboardData, setDashboardData] = useState<GetDashboardDataResponse>();
  const [piechartData, setPieChartData] = useState<PieChartDataType[]>();

  const conflictData = useMemo<ConflictData[]>(() => {
    if (dashboardData?.conflictTypesCount == null) return [];
    const sortedKeys = Object.keys(dashboardData.conflictTypesCount).sort(
      (a, b) =>
        conflictCategoryToSortIndex(a as keyof GetDashboardDataResponse['conflictTypesCount']) -
        conflictCategoryToSortIndex(b as keyof GetDashboardDataResponse['conflictTypesCount']),
    );
    return sortedKeys.map((conflictCategory) => {
      const typeOfConflictList =
        dashboardData.conflictTypesCount[
          conflictCategory as keyof GetDashboardDataResponse['conflictTypesCount']
        ];
      return [conflictCategory, typeOfConflictList];
    });
  }, [dashboardData]);

  const navToRiskAssessmentFiltered = useCallback(
    (conflictCategory: string | number, conflictType?: string) => {
      if (!conflictType) {
        return `../findings?conflictCategory=${conflictCategory}`;
      }
      return `../findings?conflictCategory=${conflictCategory}&conflictType=${conflictType}`;
    },
    [],
  );

  const generatePiechartData = useCallback(
    (data: Record<string, number>) => {
      const active = data?.aktiv ?? 0;
      const waiting = data?.['under behandling'] ?? 0;
      const handled = data?.behandlet ?? 0;
      const notrelevant = data?.['ikke relevant'] ?? 0;
      const dataArray: PieChartDataType[] = [
        {
          title: t('dashboard.notrelevant'),
          value: notrelevant,
          color: '#CBD5E1',
        },
        {
          title: t('dashboard.active'),
          value: active,
          color: '#475569',
        },
        {
          title: t('dashboard.waiting'),
          value: waiting,
          color: '#7588A3',
        },
        {
          title: t('dashboard.processed'),
          value: handled,
          color: '#ffffff',
        },
      ];
      return dataArray;
    },
    [t],
  );

  useEffect(() => {
    if (rawData) {
      setDashboardData(rawData);
      setPieChartData(generatePiechartData(rawData.actionsPieChart));
    }
  }, [rawData, generatePiechartData]);

  const waitingForAnalysis = useMemo(() => {
    if (user.isSuperuser) {
      return false;
    }
    if (isPublished) {
      return false;
    }
    return true;
  }, [isPublished, user.isSuperuser]);

  return (
    <WorkspaceLayout hideTabs={waitingForAnalysis}>
      {waitingForAnalysis ? (
        <WaitingForAnalysis />
      ) : (
        <>
          {dashboardData && piechartData ? (
            <div className="px-8">
              <div className="flex flex-col">
                <AdminBanner user={user} published={isPublished} />
                <div className="flex flex-col lg:flex-row justify-between mt-4 gap-4">
                  <div className="flex flex-col basis-7/12 w-full">
                    <div className="text-day-neutral-subtle text-3xl font-semibold pb-4">
                      {pkg?.name}
                    </div>
                    <div className="flex flex-col md:flex-row gap-8">
                      <DashboardCardNav
                        title={t('dashboard.analyzed-documents')}
                        body={`${dashboardData?.filesCount.processed}/${dashboardData?.filesCount.all}`}
                        navigateTo={'../documents'}
                      />
                      <DashboardCardNav
                        title={t('dashboard.duplicated-documents')}
                        body={dashboardData?.filesCount.duplicates}
                        navigateTo={'../documents'}
                      />
                    </div>
                    <DashboardSearch />
                  </div>
                  <PieChart data={piechartData} />
                </div>
                <div className="flex gap-3 flex-col my-6">
                  {conflictData.map(([conflictCategory, typeOfConflictList]) => (
                    <div className="w-full" key={conflictCategory}>
                      <div className="w-full">
                        <h3 className="text-2xl font-semibold text-day-neutral-subtle py-4">
                          {t(`dashboard.conflict-category.${conflictCategory}`)}
                        </h3>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-between gap-8">
                        {typeOfConflictList.map((conflictType) => {
                          const typeId = Object.keys(conflictType)[0];
                          const count = conflictType[typeId];
                          return (
                            <div key={typeId}>
                              <DashboardCardNav
                                title={t([
                                  `dashboard.conflict-title.${typeId}`,
                                  'dashboard.conflict-title.__fallback',
                                ])}
                                body={count}
                                key={typeId}
                                navigateTo={navToRiskAssessmentFiltered(conflictCategory, typeId)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <LoadingArea />
          )}
        </>
      )}
    </WorkspaceLayout>
  );
};
