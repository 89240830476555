import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import StructuralResultIcon from '@/assets/images/structural-result-icon.png';

import { StructuralDashboardCard } from './structural-dashboard-card';

interface StructuralResultProps {
  title: string;
  concreteVolume: number;
  steelWeight: number;
  navText: string;
}

export const StructuralCard: FC<StructuralResultProps> = ({
  title,
  concreteVolume,
  steelWeight,
  navText,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      navigate(`${location.pathname}/${navText}/`);
    }
  };

  const handleClick = () => {
    navigate(`${location.pathname}/${navText}/`);
  };

  return (
    <div
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      className="border-4 border-day-secondary rounded-2xl h-[400px] bg-day-light-3 flex flex-col cursor-pointer transform transition-transform duration-300 hover:scale-105"
    >
      <img
        src={StructuralResultIcon}
        alt="structural-result-icon"
        className="w-full h-auto rounded-t-2xl"
      />
      <div className="flex flex-col p-4 text-center flex-grow">
        <h2 className="text-l font-bold mb-2 text-day-neutral-dark pt-4">
          {t(`structural-engineering.result-title.${title}`)}
        </h2>
        <div className="flex-grow"></div>
        <div className="flex space-x-2 pb-4">
          <StructuralDashboardCard
            title={t('structural-engineering.concrete-volume')}
            body={`${concreteVolume ?? 0} m3`}
          />
          <StructuralDashboardCard
            title={t('structural-engineering.steel-weight')}
            body={`${steelWeight ?? 0} ${t('structural-engineering.tons')}`}
          />
        </div>
      </div>
    </div>
  );
};
