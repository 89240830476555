export var ProjectSpecification;
(function (ProjectSpecification) {
    ProjectSpecification["BREEAM"] = "BREEAM";
    ProjectSpecification["SKOK"] = "SKOK";
    ProjectSpecification["CANTEEN"] = "CANTEEN";
    ProjectSpecification["JCT"] = "JCT";
    ProjectSpecification["FIDIC_SILVER_BOOK"] = "FIDIC_SILVER_BOOK";
    ProjectSpecification["FIDIC_PINK_BOOK"] = "FIDIC_PINK_BOOK";
    ProjectSpecification["FIDIC_RED_BOOK"] = "FIDIC_RED_BOOK";
    ProjectSpecification["FIDIC_YELLOW_BOOK_1ST_EDITION_1999"] = "FIDIC_YELLOW_BOOK_1ST_EDITION_1999";
    ProjectSpecification["FIDIC_YELLOW_BOOK_2017"] = "FIDIC_YELLOW_BOOK_2017";
})(ProjectSpecification || (ProjectSpecification = {}));
export var ProjectConstructionType;
(function (ProjectConstructionType) {
    ProjectConstructionType["OFFICE_BUILDING"] = "OFFICE_BUILDING";
    ProjectConstructionType["EDUCATION_BUILDING"] = "EDUCATION_BUILDING";
    ProjectConstructionType["KINDERGARTEN"] = "KINDERGARTEN";
    ProjectConstructionType["RESIDENTIAL_BUILDING"] = "RESIDENTIAL_BUILDING";
    ProjectConstructionType["OTHER_BUILDING"] = "OTHER_BUILDING";
    ProjectConstructionType["BRIDGE"] = "BRIDGE";
    ProjectConstructionType["ROAD"] = "ROAD";
    ProjectConstructionType["ROUNDABOUT"] = "ROUNDABOUT";
    ProjectConstructionType["OTHER_INFRASTRUCTURE"] = "OTHER_INFRASTRUCTURE";
    ProjectConstructionType["ARENA"] = "ARENA";
})(ProjectConstructionType || (ProjectConstructionType = {}));
export var ProjectInvoiceTo;
(function (ProjectInvoiceTo) {
    ProjectInvoiceTo["ORGANIZATION"] = "ORGANIZATION";
    ProjectInvoiceTo["PROJECT"] = "PROJECT";
})(ProjectInvoiceTo || (ProjectInvoiceTo = {}));
export var ProjectRole;
(function (ProjectRole) {
    ProjectRole["ADMIN"] = "ADMIN";
    ProjectRole["USER"] = "USER";
})(ProjectRole || (ProjectRole = {}));
