import { renderLoader } from '@consigli/utils';
import { DocumentLoadEvent, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import {
  HighlightArea,
  RenderHighlightsProps,
  Trigger,
  highlightPlugin,
} from '@react-pdf-viewer/highlight';
import { OnHighlightKeyword, searchPlugin } from '@react-pdf-viewer/search';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { useCallback, useEffect, useMemo, useRef, useState, type FC } from 'react';

import { usePDFToolbar } from './custom-toolbar';
import { PDFViewerError } from './pdf-viewer-error';
import { regexpIgnoreWhitespaces } from './search-regexp';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

type PDFViewerProps = {
  initialPageNumber?: number;
  findingCoordinates?: { x0: number; x1: number; y0: number; y1: number; pageIndex: number };
  searchTerm?: string;
  searchFallbackPage?: number;
  fileUrl: string;
  isPreview: boolean;
  documentName: string;
  id?: number;
};

export const PDFViewer: FC<PDFViewerProps> = ({
  /**
   * Initial page number to jump to upon opening the PDF.
   * First page is index 0
   *
   * If not specified, the PDF will open on the first page.
   */
  initialPageNumber = 0,
  /**
   * Coordinates for the finding, which will be highlighted, if this is not set, it will fallback to searchTerm
   */
  findingCoordinates,
  /**
   * Search term to highlight
   */
  searchTerm,
  /**
   * Fallback page number if search fails to match in text (0-indexed)
   */
  searchFallbackPage = 0,
  /**
   * Path to the PDF document to render
   */
  fileUrl,
  /**
   * To remove toolbar when in preview mode
   */
  isPreview = true,
  /**
   * To get the document name
   */
  documentName,
  /**
   * Unique ID for the PDF viewer. Needed to fix scroll issue for the second viewer.
   */
  id = 1,
}) => {
  const [pdfSize, setPdfSize] = useState<{
    pdfWidth: number;
    pdfHeight: number;
    pageCount: number;
  } | null>(null);

  const keyword = useMemo(
    () => (searchTerm ? regexpIgnoreWhitespaces(searchTerm.replace('|', '')) : undefined),
    [searchTerm],
  );

  const highlightArea: HighlightArea | undefined = useMemo(() => {
    if (!findingCoordinates || !pdfSize || pdfSize.pdfHeight === 0 || pdfSize.pdfWidth === 0) {
      return undefined;
    }

    const { x0, x1, y0, y1, pageIndex } = findingCoordinates;
    const { pdfHeight, pdfWidth } = pdfSize;

    const height = (Math.abs(y0 - y1) * 100) / pdfHeight;
    const width = (Math.abs(x0 - x1) * 100) / pdfWidth;
    const top = (Math.min(y0, y1) * 100) / pdfHeight;
    const left = (Math.min(x0, x1) * 100) / pdfWidth;

    return { height, left, top, width, pageIndex };
  }, [findingCoordinates, pdfSize]);

  const { fullscreenToolbar, previewToolbar, navigation, zoom, download, search } =
    usePDFToolbar(documentName);

  const renderHighlights = (props: RenderHighlightsProps) =>
    highlightArea !== undefined && highlightArea.pageIndex === props.pageIndex ? (
      <div
        style={{
          background: 'yellow',
          opacity: 0.4,
          ...props.getCssProperties(highlightArea, props.rotation),
        }}
      />
    ) : (
      <></>
    );

  const highlight = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  const toolbar = toolbarPlugin({
    searchPlugin: {
      enableShortcuts: highlightArea == null,
      keyword,
    },
  });

  const searchPluginInstance = searchPlugin({
    onHighlightKeyword: (props: OnHighlightKeyword) => {
      if (!highlightArea) {
        props.highlightEle.style.backgroundColor = 'yellow';
        props.highlightEle.style.opacity = '0.4';
      }
    },
  });

  const initialized = useRef({
    findingScrolled: false,
    searchScrolled: false,
    documentLoaded: false,
    initialLoadHandled: false,
  });
  const PADDING = 25;

  const scrollToHighlightArea = (
    pageContainer: Element,
    page: Element,
    findingCoordinates: { pageIndex: number },
    highlightArea: HighlightArea,
  ) => {
    const scrollHeight = page.getBoundingClientRect().height;
    const offsetPageIndex = findingCoordinates.pageIndex * scrollHeight;
    const offsetHighlightOnPage = (highlightArea.top / 100) * scrollHeight;

    const scrollTop = offsetPageIndex + offsetHighlightOnPage - PADDING;

    // Ensure scrollTop is within bounds
    const maxScrollTop = pageContainer.scrollHeight - pageContainer.clientHeight;
    const finalScrollTop = Math.min(Math.max(scrollTop, 0), maxScrollTop);

    requestAnimationFrame(() =>
      pageContainer.scrollTo({
        top: finalScrollTop,
        behavior: 'smooth',
      }),
    );
  };

  const clearHighlightsAndSetTargetPages = useCallback(() => {
    toolbar.searchPluginInstance.clearHighlights();
    if (searchFallbackPage !== 0) {
      toolbar.searchPluginInstance.setTargetPages(
        (targetPage) => targetPage.pageIndex >= searchFallbackPage - 2,
      );
    } else {
      toolbar.searchPluginInstance.setTargetPages(() => true);
    }
  }, [searchFallbackPage, toolbar.searchPluginInstance]);

  const handlePageChange = useCallback(async () => {
    if (
      initialized.current.findingScrolled ||
      initialized.current.searchScrolled ||
      !keyword ||
      !highlightArea
    ) {
      return;
    }

    if (findingCoordinates && highlightArea) {
      initialized.current.findingScrolled = true;
      clearHighlightsAndSetTargetPages();

      const pageContainer = document.querySelector(`[data-key="${id}"] .rpv-core__inner-pages`);
      const page = document.querySelector(`[data-key="${id}"] .rpv-core__inner-page`);

      if (pageContainer && page) {
        pageContainer.classList.add('scroll-smooth');
        scrollToHighlightArea(pageContainer, page, findingCoordinates, highlightArea);
        initialized.current.searchScrolled = true;
      } else {
        requestAnimationFrame(() => navigation.jumpToPage(findingCoordinates.pageIndex));
        initialized.current.searchScrolled = true;
      }
      return;
    }

    clearHighlightsAndSetTargetPages();

    try {
      const match = await searchPluginInstance.highlight(keyword);
      if (match.length == 0) {
        navigation.jumpToPage(searchFallbackPage);
      }
    } catch (error) {
      console.error(error);
    }
    initialized.current.searchScrolled = true;
  }, [
    keyword,
    findingCoordinates,
    highlightArea,
    clearHighlightsAndSetTargetPages,
    id,
    navigation,
    searchFallbackPage,
    searchPluginInstance,
  ]);

  const onDocumentLoadSuccess = useCallback(
    (loadEvent: DocumentLoadEvent) => {
      if (!initialized.current.documentLoaded) {
        loadEvent.doc.getPage(1).then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          setPdfSize({
            pdfWidth: viewport.width,
            pdfHeight: viewport.height,
            pageCount: loadEvent.doc.numPages,
          });

          handlePageChange();
          initialized.current.documentLoaded = true;
        });
      }
    },
    [handlePageChange],
  );

  useEffect(() => {
    if (highlightArea || keyword) {
      handlePageChange();
    }

    if (!initialized.current.initialLoadHandled) {
      initialized.current.initialLoadHandled = true;
    }

    const handleResize = () => {
      zoom.zoomTo(SpecialZoomLevel.PageWidth);
    };
    window.addEventListener('resize', handleResize);

    const initializedRef = initialized.current;

    return () => {
      initializedRef.documentLoaded = false;
      window.removeEventListener('resize', handleResize);
    };
  }, [handlePageChange, highlightArea, keyword, zoom]);

  return (
    <>
      {isPreview ? <>{previewToolbar}</> : <>{fullscreenToolbar}</>}
      <Worker workerUrl="/libs/pdfjs-dist@3.4.120.min.js">
        <Viewer
          key={id}
          fileUrl={fileUrl}
          initialPage={initialPageNumber}
          renderError={(error) => <PDFViewerError error={error} />}
          onPageChange={handlePageChange}
          onDocumentLoad={onDocumentLoadSuccess}
          plugins={[toolbar, highlight, navigation, zoom, download, search, searchPluginInstance]}
          renderLoader={renderLoader}
        />
      </Worker>
    </>
  );
};
