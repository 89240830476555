import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

type BreadcrumbContext = [Element | null, (el: Element | null) => void];

const BreadcrumbContext = createContext<BreadcrumbContext | null>(null);

export const useBreadcrumbContext = () => {
  const ctx = useContext(BreadcrumbContext);

  if (!ctx) {
    throw new Error('Missing BreadcrumbProvider in tree');
  }

  return ctx;
};

export const BreadcrumbProvider: FC<PropsWithChildren> = ({ children }) => {
  const portalNodeState = useState<Element | null>(null);
  return (
    <BreadcrumbContext.Provider value={portalNodeState}>{children}</BreadcrumbContext.Provider>
  );
};
