import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
export const projectTypeSchema = z
    .object({
    id: z.number().int(),
    name: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const getProjectTypesResponseSchema = z.array(projectTypeSchema).transform(mapToCamelCase);
