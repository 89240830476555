import { Button } from '@consigli/facade';
import { useUpdateFindingMutation, useProjectId, usePackageId } from '@consigli/hooks';
import { ActionStatus, Finding } from '@consigli/types';
import clsx from 'clsx';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuSend } from 'react-icons/lu';
import { toast } from 'react-toastify';

import { useViewer } from '@/hooks/use-viewer';
import { mapActionNameToStates } from '@/modules/pages/risk-assessment/findings-content';
import { useFindingsContext } from '@/modules/pages/risk-assessment/findings-context';
import { processing } from '@/modules/pages/risk-assessment/findings-wrapper';
import { ProcessingPopup } from '@/modules/pages/risk-assessment/processing-pop-up';

type UpdateFindingButtonsProps = {
  finding: Finding;
  itemsOnPage: number;
  className?: string;
};

export const UpdateFindingButtons: FC<UpdateFindingButtonsProps> = ({
  finding,
  itemsOnPage,
  className,
}) => {
  const { t } = useTranslation();
  const [updateFinding] = useUpdateFindingMutation();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const { close } = useViewer();
  const [isForwardFindingOpen, setForwardFindingOpen] = useState(false);
  const { page, setPage } = useFindingsContext();

  const filteredButtonKeys = useMemo(() => {
    const preferredButtonKeysOrder = ['2', '4', '1', '3'];
    const buttonKeys = Object.keys(mapActionNameToStates).filter(
      (item: string) => item !== finding.action.toString() && item !== processing,
    );
    return buttonKeys.sort((a, b) => {
      return preferredButtonKeysOrder.indexOf(a) - preferredButtonKeysOrder.indexOf(b);
    });
  }, [finding.action]);

  const updateIndividualFinding = useCallback(
    async (finding: Finding, action: ActionStatus) => {
      try {
        if (itemsOnPage === 1 && page > 1) {
          setPage((prevPage) => prevPage - 1);
        }
        await updateFinding({
          projectId,
          packageId,
          findingId: finding.id,
          data: {
            action,
          },
        }).unwrap();
        toast.info(t(`toast.file-update-success-no-status`));
      } catch (e) {
        toast.info(t(`toast.file-update-failure`));
      }
    },
    [updateFinding, projectId, packageId, itemsOnPage, page, t, setPage],
  );

  return (
    <>
      <div className={clsx('flex flex-wrap gap-2', className)}>
        {filteredButtonKeys.map((key: string) => (
          <Button
            rounded
            iconOnly
            secondary
            key={key}
            title={t(mapActionNameToStates[key].title)}
            icon={mapActionNameToStates[key].icon}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
              finding && updateIndividualFinding(finding, mapActionNameToStates[key].action);
              close();
            }}
          />
        ))}
        {finding.action.toString() !== processing && (
          <Button
            secondary
            rounded
            iconOnly
            title={t(mapActionNameToStates[processing].title)}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
              setForwardFindingOpen(true);
            }}
            icon={LuSend}
          />
        )}
      </div>
      {isForwardFindingOpen && finding && (
        <ProcessingPopup
          onClose={() => setForwardFindingOpen(false)}
          findings={[{ ...finding, checked: true }]}
          count={1}
          itemsOnPage={itemsOnPage}
        />
      )}
    </>
  );
};
