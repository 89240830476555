import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { Route } from '@/routes';

export const ProjectListWrapperPage: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb label={t('navbar.breadcrumbs.home')} to={`/${Route.PROJECTS}`} />
      <Outlet />
    </>
  );
};
