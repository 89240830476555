import { excelExportSchema, Language } from '@consigli/types';
import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
export const sendFindingsMailResponseSchema = z
    .object({
    status: z.number(),
    response: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const sendFindingsMailRequestSchema = z
    .object({
    message: z.string().optional(),
    recipients: z.object({
        to: z.array(z.object({
            address: z.string(),
        })),
    }),
    excel_export: excelExportSchema,
    language: z.nativeEnum(Language),
})
    .strict()
    .transform(mapToCamelCase);
