import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
export const sourceSchema = z
    .object({
    blob_id: z.string().uuid(),
    content: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const answerSchema = z
    .object({
    answer: z.string(),
    sources: z.array(sourceSchema),
})
    .strict()
    .transform(mapToCamelCase);
