import { FC } from 'react';

import { PieChartProps } from '@/util/interfaces';

export const PieChartLegend: FC<PieChartProps> = ({ data }) => (
  <div className="flex flex-col justify-center sm:ml-5">
    {data.map((item) => (
      <div key={item.title} className="flex flex-row items-center gap-1 justify-center pt-3">
        <div
          className="w-4 h-4 rounded-sm"
          style={{
            backgroundColor: item.color,
          }}
        />
        <div className="w-full text-base text-day-neutral-dark pl-2">
          {item.title}
          <span className="pl-1">
            {'('}
            {item.value}
            {'%)'}
          </span>
        </div>
      </div>
    ))}
  </div>
);
