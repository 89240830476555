export const formatApiError = (error) => {
    if (!error) {
        return 'OK';
    }
    if ('message' in error) {
        return error.message;
    }
    if ('status' in error) {
        return `${error.status} ${JSON.stringify(error.data)}`;
    }
    return 'Unknown Error';
};
