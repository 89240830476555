import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { WorkspaceTabs } from '@/components/layouts/workspace-layout/workspace-tabs';
import { useViewerContext, ViewerMode } from '@/contexts/use-viewer-context';

type WorkspaceLayoutProps = {
  children: ReactNode;
  hideTabs?: boolean;
};

export const WorkspaceLayout: FC<WorkspaceLayoutProps> = ({ children, hideTabs }) => {
  const { mode } = useViewerContext();

  return (
    <main className="flex flex-row w-full flex-grow max-h-[calc(100vh-theme(space.14))] overflow-y-scroll bg-day-light-4">
      <div
        className={clsx(
          'flex flex-col grow max-w-screen-2xl',
          mode !== ViewerMode.DocumentFullscreen && 'mx-auto',
        )}
      >
        <div className="flex flex-col md:flex-row justify-start items-center border-b border-day-secondary w-full">
          <WorkspaceTabs hideTabs={hideTabs} />
        </div>
        {children}
      </div>
    </main>
  );
};
