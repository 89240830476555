import { useMyUser } from '@consigli/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io';

import { UserProfileProjectAccessCard } from './user-profile-project-access-card';

export const UserProfileProjectAccessList: FC = () => {
  const { user } = useMyUser();
  const { t } = useTranslation();

  if (user.isSuperuser) {
    return (
      <div className="flex flex-col">
        <div className="mb-4 text-lg text-day-neutral-subtle">{t('profile.permissions')}</div>
        <p>{t('profile.superuser')}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="mb-4 text-lg text-day-neutral-subtle">{t('profile.permissions')}</div>
      <div>
        <div className="flex flex-col">
          <p className="text-sm text-day-neutral-subtle">
            {t('profile.can-create-projects') + '?'}
          </p>
          <div className="flex items-center">
            {user.organizationId ? (
              <IoIosCheckmarkCircleOutline size={18} className="text-success-dark" />
            ) : (
              <IoIosCloseCircleOutline size={18} className="text-error-dark" />
            )}
            <b className="ml-1">{user.organizationId ? t('profile.yes') : t('profile.no')}</b>
          </div>
        </div>
      </div>
      {user.projectAccess.length > 0 && (
        <div className="mt-4 text-sm text-day-neutral-subtle">{t('profile.project-access')}</div>
      )}
      {user.projectAccess.map((project) => (
        <UserProfileProjectAccessCard
          key={project.id}
          id={project.id}
          name={project.name}
          role={project.role}
          services={project.services}
        />
      ))}
    </div>
  );
};
