import { Button } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useQueryPropChat,
  useLocalState,
  useGetPackagePropChatStatusQuery,
} from '@consigli/hooks';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'reactjs-popup';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/components/layouts/workspace-layout/workspace-layout';
import { Chat } from '@/organisms/chat';
import { type Message } from '@/util/interfaces';

export const PropChat = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const { queryPackagePropChat } = useQueryPropChat();
  const [propChatDisclaimerShown, setPropChatDisclaimerShown] = useLocalState<boolean>(
    'prop-chat-disclaimer-shown',
    false,
  );

  const { data: status, isLoading: loadingStatus } = useGetPackagePropChatStatusQuery({
    projectId,
    packageId,
  });

  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [messages, setMessages] = useState<Message[]>(() => {
    const storedMessages = sessionStorage.getItem(`propchat-${packageId}`);
    return storedMessages
      ? JSON.parse(storedMessages)
      : [
          {
            id: crypto.randomUUID(),
            body: t('prop-chat.welcome-message'),
            authorId: crypto.randomUUID(),
            createdAt: new Date(),
          },
        ];
  });

  const onMessage = useCallback(
    async (message: Message) => {
      setMessages((messages) => {
        const newMessages = [...messages, message];
        sessionStorage.setItem(`propchat-${packageId}`, JSON.stringify(newMessages));
        return newMessages;
      });
      setLoadingAnswer(true);
      try {
        const { answer, sources } = await queryPackagePropChat({
          projectId,
          packageId,
          question: message.body,
        });
        setMessages((messages) => {
          const newMessages = [
            ...messages,
            {
              id: crypto.randomUUID(),
              authorId: crypto.randomUUID(),
              body: answer,
              sources,
              createdAt: new Date(),
            },
          ];
          sessionStorage.setItem(`propchat-${packageId}`, JSON.stringify(newMessages));
          return newMessages;
        });
      } catch (e) {
        setMessages((messages) => {
          const newMessages = [
            ...messages,
            {
              id: crypto.randomUUID(),
              body: t('prop-chat.query-failed'),
              authorId: crypto.randomUUID(),
              createdAt: new Date(),
            },
          ];
          sessionStorage.setItem(`propchat-${packageId}`, JSON.stringify(newMessages));
          return newMessages;
        });
      } finally {
        setLoadingAnswer(false);
      }
    },
    [t, setMessages, projectId, packageId, queryPackagePropChat],
  );

  return (
    <WorkspaceLayout>
      <Breadcrumb label={t('servicetabs.propchat')} />
      <Popup
        open={!propChatDisclaimerShown}
        onClose={() => setPropChatDisclaimerShown(true)}
        closeOnEscape={false}
        closeOnDocumentClick={false}
        contentStyle={{ maxWidth: 500 }}
      >
        <div className="p-4">
          <h1 className="text-xl pb-2 font-semibold">{t('prop-chat.disclaimer-header')}</h1>
          <p>{t('prop-chat.disclaimer-1')}</p>
          <br />
          <p>{t('prop-chat.disclaimer-2')}</p>
          <div className="flex flex-row justify-end pt-4">
            <Button primary onClick={() => setPropChatDisclaimerShown(true)}>
              {t('prop-chat.disclaimer-confirm')}
            </Button>
          </div>
        </div>
      </Popup>
      <Chat
        messages={messages}
        onMessage={onMessage}
        loading={loadingStatus}
        loadingMessage={loadingAnswer}
        disabled={status?.ready === false}
      />
    </WorkspaceLayout>
  );
};
