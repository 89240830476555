import SparkMD5 from 'spark-md5';
export const getMD5Hash = async (file) => {
    const readFileData = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
    const fileData = readFileData(file);
    const spark = new SparkMD5.ArrayBuffer();
    spark.append(await fileData);
    const md5 = spark.end();
    return md5;
};
