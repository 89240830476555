import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Category } from './category';
import { TreeEntry, isTreeFolder } from '../util/document-list-to-tree';

export const CategoryContent = ({
  content,
  onClick,
}: {
  content: TreeEntry;
  onClick: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const sortedItems = useMemo(() => {
    if (!isTreeFolder(content)) {
      return [];
    }
    const items = [...content.children.entries()];
    return items.sort(([, x], [, y]) => x.toDisplayString(t).localeCompare(y.toDisplayString(t)));
  }, [content, t]);

  if (sortedItems.length === 0) {
    return null;
  }
  return (
    <>
      {sortedItems.map(([, entry]) => (
        <div
          key={entry.id}
          className="my-4"
          onClick={() => onClick(entry.id)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              onClick(entry.id);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <Category title={entry.toDisplayString(t)} entry={entry} />
        </div>
      ))}
    </>
  );
};
