import { Button, Position } from '@consigli/facade';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';

interface WizardAnalyzeButtonProps {
  localFiles: File[];
  onAnalyze?: () => void;
  type?: 'button' | 'submit' | undefined;
}

export const WizardAnalyzeButton = ({ localFiles, onAnalyze, type }: WizardAnalyzeButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      rounded
      primary
      className={clsx('w-full fc-px-3 fc-py-2 mt-2', {
        'text-sm': localFiles.length > 999,
      })}
      onClick={onAnalyze}
      icon={BsArrowRight}
      iconPosition={Position.RIGHT}
      disabled={localFiles?.length === 0}
      iconColor="white"
      type={type || 'button'}
    >
      {t('package-creation.process-documents', {
        documentsCount: localFiles.length || '',
      })}
    </Button>
  );
};
