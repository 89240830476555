import { useEffect } from 'react';
const modifiersMatch = (evt, modifiers) => {
    if (modifiers.altKey && !evt.altKey)
        return false;
    if (modifiers.ctrlKey && !evt.ctrlKey)
        return false;
    if (modifiers.metaKey && !evt.metaKey)
        return false;
    if (modifiers.shiftKey && !evt.shiftKey)
        return false;
    return true;
};
/**
 * Hook for listening to a key press event.
 *
 * @param targetKey the key to listen for.
 * @param callback the function to call when the key is pressed.
 * @param modifiers an object that specifies the modifiers that must be pressed for the callback to be called.
 */
export const useKeyListener = (targetKey, callback, modifiers = {}) => {
    useEffect(() => {
        const keyListener = (evt) => {
            if (evt.key === targetKey && modifiersMatch(evt, modifiers)) {
                evt.preventDefault();
                callback();
            }
        };
        window.addEventListener('keydown', keyListener);
        return () => {
            window.removeEventListener('keydown', keyListener);
        };
    }, [targetKey, callback, modifiers]);
};
