import { useMemo } from 'react';
import { useGetStructuralEngineeringFilesQuery } from '../api';
/**
 * Returns the structural engineering files for a given layout within a project.
 *
 * @param params the parameters to fetch the structural engineering files.
 *
 * params contains the following properties:
 * @param projectId the project id that the layout optimization files belong to.
 * @param structuralId the layout id within the project, that the layout optimization files belong to.
 * @param page the page to fetch. (pagination)
 *
 * it also contains the following optional properties:
 * @param page_size the number of layout optimization files to fetch per page.
 * @param search
 *
 * @returns an object with layout optimization files, loading state, fetching state, error state and error object.
 */
export const useStructuralFiles = (params) => {
    const { data: filesResponse, isLoading, isFetching, isError, error, } = useGetStructuralEngineeringFilesQuery(params);
    return useMemo(() => {
        const totalCount = filesResponse?.count || 0;
        const files = filesResponse?.results || [];
        const next = filesResponse?.next;
        const previous = filesResponse?.previous;
        return {
            files,
            totalCount,
            next,
            previous,
            isLoading,
            isFetching,
            isError,
            error,
        };
    }, [filesResponse, isLoading, isFetching, isError, error]);
};
