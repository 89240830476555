import { Spinner } from '@consigli/facade';
import { AuthProvider } from '@consigli/oauth';
import { FC, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';

import 'reactjs-popup/dist/index.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-toastify/dist/ReactToastify.css';

export const App: FC = () => (
  <Suspense fallback={<Spinner />}>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </Suspense>
);
