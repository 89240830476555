import { useServiceName, useProjectId } from '@consigli/hooks';
import { Language, LayoutType, ServiceId, ServiceName, StructuralType } from '@consigli/types';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GoPlus } from 'react-icons/go';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineKeyboardDoubleArrowUp,
  MdOutlineKeyboardDoubleArrowDown,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Route } from '@/routes';

export function convertServiceNameToId(serviceName: ServiceName): ServiceId {
  switch (serviceName) {
    case ServiceName.DUE_DILIGENCE:
      return ServiceId.DUE_DILIGENCE;
    case ServiceName.TENANT_REQUIREMENTS:
      return ServiceId.TENANT_REQUIREMENTS;
    case ServiceName.TENDER_DOCUMENTS:
      return ServiceId.TENDER_DOCUMENTS;
    case ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE:
      return ServiceId.MANAGEMENT_OPERATIONS_MAINTENANCE;
    case ServiceName.TENANT_SPECIFICATION:
      return ServiceId.TENANT_SPECIFICATION;
    case ServiceName.OFFER:
      return ServiceId.OFFER;
    default:
      throw new Error(`Unknown ServiceName: ${serviceName}`);
  }
}

export type PackageType = {
  id: string;
  name: string;
  project: number;
  projectPhase: number;
  externalFamacBuildingId: string | null;
  createdBy: string | null;
  language?: Language | undefined;
};

export type OptimizationType = {
  type: LayoutType;
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  projectId: number;
  data?: { ceilingHeight: string; comments: string; floorHeight: string };
};

export type StructuralEngineeringType = {
  type: StructuralType;
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  projectId: number;
  data?: { description: string };
};

type PackageListProps = {
  packages: PackageType[] | OptimizationType[] | StructuralEngineeringType[];
  id: string;
};
export const PackageList = ({ packages, id }: PackageListProps) => {
  const navigate = useNavigate();
  const serviceName = useServiceName();

  const projectId = useProjectId();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(true);

  const [selectedValue, setSelectedValue] = useState<
    OptimizationType | PackageType | StructuralEngineeringType | undefined
  >(undefined);
  useEffect(() => {
    if (id) {
      setSelectedValue(packages.find((item) => item.id === id));
    }
  }, [id, selectedValue, packages]);

  const onOptionChange = useCallback(
    (newValue: OptimizationType | PackageType | StructuralEngineeringType): void => {
      if (newValue) {
        setSelectedValue(newValue);
        navigate(
          `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${newValue.id}/${Route.DASHBOARD}`,
        );
      }
    },
    [navigate, projectId, serviceName],
  );

  return (
    <div className="relative">
      <div className="mx-1 py-1 px-1 absolute cursor-pointer border-l border-r border-b border-day-secondary rounded-bl rounded-br right-0">
        {isVisible ? (
          <MdOutlineKeyboardDoubleArrowUp size={20} onClick={() => setIsVisible(false)} />
        ) : (
          <MdOutlineKeyboardDoubleArrowDown size={20} onClick={() => setIsVisible(true)} />
        )}
      </div>
      <div
        className={`flex items-center border-b border-day-secondary justify-center m-auto w-full overflow-hidden transition-all duration-500 ease-in-out ${isVisible ? 'h-auto py-2 border-b' : 'h-0'}`}
      >
        <MdKeyboardArrowLeft
          className="flex items-center justify-center w-[40px] h-[40px] border border-day-secondary p-2 rounded cursor-pointer hover:bg-day-light-1 transition-colors duration-200 ease-in-out mx-1"
          onClick={() => scrollRef.current?.scrollBy({ left: -200, behavior: 'smooth' })}
        />
        <div
          ref={scrollRef}
          className="flex items-center justify-center overflow-auto p-1 mx-2 max-w-[250px] md:max-w-[500px] lg:max-w-[700px] 2xl:max-w-[1100px] no-scrollbar text-day-neutral-dark"
        >
          <div className="flex w-full">
            {packages.map(
              (pack: PackageType | OptimizationType | StructuralEngineeringType, index: number) => (
                <div
                  key={pack.id}
                  className={clsx(
                    'p-2 shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_3px_0_rgba(0,0,0,0.10)] rounded-lg cursor-pointer transition-colors duration-200 ease-in-out flex-shrink-0',
                    {
                      'bg-day-light-1': pack.id === selectedValue?.id,
                      'hover:bg-day-light-1': pack.id !== selectedValue?.id,
                    },
                    index === packages.length - 1 ? 'mr-0' : 'mr-4',
                  )}
                  onClick={() => onOptionChange(pack)}
                  aria-hidden
                >
                  {pack.name}
                </div>
              ),
            )}
          </div>
        </div>
        <GoPlus
          className="flex items-center justify-center w-[40px] h-[40px] border border-day-secondary p-2 rounded cursor-pointer hover:bg-day-light-1 transition-colors duration-200 ease-in-out mx-1"
          onClick={() =>
            navigate(
              `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.CREATE}`,
            )
          }
        />
        <MdKeyboardArrowRight
          className="flex items-center justify-center w-[40px] h-[40px] border border-day-secondary p-2  rounded cursor-pointer hover:bg-day-light-1 transition-colors duration-200 ease-in-out mx-1"
          onClick={() => scrollRef.current?.scrollBy({ left: 200, behavior: 'smooth' })}
        />
      </div>
    </div>
  );
};
