import { LoadingArea } from '@consigli/facade';
import { useReadUrl } from '@consigli/hooks';
import { Document } from '@consigli/types';
import { FC } from 'react';

import { IfcViewer } from './ifc-viewer';

interface IfcContainerProps {
  document: Document;
}

export const IfcContainer: FC<IfcContainerProps> = ({ document }) => {
  const { readUrl } = useReadUrl(document);

  return (
    <div className="text-center w-11/12">
      <p className="text-white">{document.name}</p>
      {!readUrl && <LoadingArea />}
      {readUrl && <IfcViewer fileUrl={readUrl} />}
    </div>
  );
};
