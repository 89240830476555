import { useState } from 'react';
/**
 * Hook that returns a stateful value and a function to update it.
 * The value is stored in the local storage.
 *
 * @param key a string that represents the key of the value in the local storage.
 * @param initialValue the initial value of the state.
 *
 * @returns a tuple containing the stateful value and a function to update it.
 */
export const useLocalState = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        }
        catch (error) {
            return initialValue;
        }
    });
    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    return [storedValue, setValue];
};
