import { useMemo } from 'react';
import { useGetMyUserQuery } from '../api';
/**
 * Hook to get the current user.
 */
export const useMyUser = () => {
    const { data: user, isLoading, isError, error } = useGetMyUserQuery();
    if (user == null) {
        throw new Error(`useUser called on route without user loaded`);
    }
    return useMemo(() => ({
        user,
        isLoading,
        isError,
        error,
    }), [user, error, isError, isLoading]);
};
