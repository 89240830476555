import { useMemo } from 'react';
import { useGetStructuralEngineeringResultsQuery } from '../api';
export const useStructuralResults = (params) => {
    const { data: resultsResponse, isLoading, isFetching, isError, error, } = useGetStructuralEngineeringResultsQuery(params);
    return useMemo(() => {
        const totalCount = resultsResponse?.count || 0;
        const results = resultsResponse?.results || [];
        const next = resultsResponse?.next;
        const previous = resultsResponse?.previous;
        return {
            results,
            totalCount,
            next,
            previous,
            isLoading,
            isFetching,
            isError,
            error,
        };
    }, [resultsResponse, isLoading, isFetching, isError, error]);
};
