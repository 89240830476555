import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { languageSchema } from '../core-api/language';
export const questionSchema = z
    .object({
    question: z.string(),
    language: languageSchema,
})
    .strict()
    .transform(mapToCamelCase);
