import { useParams } from 'react-router-dom';
/**
 * @returns the packageId from the current route (url)
 */
export const usePackageId = () => {
    const { packageId } = useParams();
    if (packageId == null) {
        throw new Error(`usePackageId called on route without :packageId`);
    }
    return packageId;
};
