import { LoadingArea } from '@consigli/facade';
import {
  Components as OBC,
  SimpleScene,
  PostproductionRenderer,
  OrthoPerspectiveCamera,
  SimpleRaycaster,
  FragmentIfcLoader,
} from 'openbim-components';
import { useEffect, useRef, FC, useState } from 'react';
import { Color } from 'three';

type IfcViewerProps = {
  fileUrl: string;
};

export const IfcViewer: FC<IfcViewerProps> = ({ fileUrl }) => {
  const viewerContainerRef = useRef<HTMLDivElement>(null);
  const [viewer, setViewer] = useState<OBC>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (viewerContainerRef.current) {
      const viewer = new OBC();

      const sceneComponent = new SimpleScene(viewer);
      sceneComponent.setup();
      viewer.scene = sceneComponent;

      viewer.scene.get().background = new Color(0xffffff);

      const viewerContainer = viewerContainerRef.current;

      const rendererComponent = new PostproductionRenderer(viewer, viewerContainer);
      viewer.renderer = rendererComponent;
      const postproduction = rendererComponent.postproduction;

      const cameraComponent = new OrthoPerspectiveCamera(viewer);
      viewer.camera = cameraComponent;

      const raycasterComponent = new SimpleRaycaster(viewer);
      viewer.raycaster = raycasterComponent;

      viewer.init();
      cameraComponent.updateAspect();
      postproduction.enabled = true;

      setViewer(viewer);

      return () => {
        viewer.dispose();
      };
    }
  }, []);

  useEffect(() => {
    async function loadIfcAsFragments(viewer: OBC, fileUrl: string) {
      const ifcLoader = new FragmentIfcLoader(viewer);

      ifcLoader.settings.wasm = { path: '/wasm/', absolute: true };

      const file = await fetch(fileUrl);
      const data = await file.arrayBuffer();
      const buffer = new Uint8Array(data);
      const model = await ifcLoader.load(buffer, 'ifc');

      const scene = viewer.scene.get();
      scene.add(model);

      setIsLoading(false);
    }
    if (viewer) {
      loadIfcAsFragments(viewer, fileUrl);
    }
  }, [viewer, fileUrl]);

  return (
    <div className="w-full h-full pt-2">
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-70 z-10 flex justify-center items-center">
          <LoadingArea />
        </div>
      )}
      <div className="w-full h-full" ref={viewerContainerRef}></div>
    </div>
  );
};
