import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FaRegFolder } from 'react-icons/fa6';

import { useFileTreeContext } from '@/contexts/use-file-tree-context';
import { useViewerContext, ViewerMode } from '@/contexts/use-viewer-context';

export const AllDocuments = () => {
  const { t } = useTranslation();
  const { setMode } = useViewerContext();
  const { setSelectedCard } = useFileTreeContext();
  const { setActiveFolderId, activeFolderId, summaryCount } = useFileTreeContext();
  const count = summaryCount ? summaryCount['__all_documents__'] : 0;

  return (
    <div
      className={clsx(
        'shadow mb-4 rounded-md text-day-neutral-subtle cursor-pointer hover:bg-day-light-3',
        !activeFolderId ? 'bg-day-light-3' : '',
      )}
      onClick={() => {
        setActiveFolderId('');
        setMode(ViewerMode.Closed);
        setSelectedCard('');
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          setActiveFolderId('');
          setMode(ViewerMode.Closed);
          setSelectedCard('');
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="flex items-center justify-center p-2 cursor-pointer rounded-lg hover:rounded-lg">
        <span className="w-[14px] h-[14px] mr-4 ml-1">
          <FaRegFolder />
        </span>
        <span className={clsx('text-sm flex-1', { 'font-bold': !activeFolderId })}>
          {t('folders.all-documents')} ({count})
        </span>
      </div>
    </div>
  );
};
