import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { fileSchema } from './file';
import { getPaginationResponseSchema } from './pagination';
import { projectSchema } from './project';
export var StructuralType;
(function (StructuralType) {
    StructuralType["STRUCTURAL"] = "STRUCTURAL";
})(StructuralType || (StructuralType = {}));
export const structuralEngineeringSchema = z
    .object({
    id: z.string().uuid(),
    project_id: projectSchema.innerType().shape.id,
    name: z.string().min(1),
    type: z.nativeEnum(StructuralType),
    data: z.any(),
    updated_at: z.string(),
    created_at: z.string(),
})
    .strict()
    .transform(mapToCamelCase);
export const getStructuralEngineeringResponseSchema = z
    .array(structuralEngineeringSchema)
    .transform(mapToCamelCase);
export const getStructuralEngineeringRequestSchema = z
    .object({
    project_id: z.number().int(),
    type: z.nativeEnum(StructuralType).optional(),
})
    .strict()
    .transform(mapToCamelCase);
export const createStructuralEngineeringRequestSchema = z
    .object({
    name: z.string().min(1),
    type: z.nativeEnum(StructuralType),
    data: z.any(),
    fileIds: z.array(z.string().uuid()).optional(),
})
    .strict()
    .transform(mapToCamelCase);
export const createStructuralEngineeringResponseSchema = structuralEngineeringSchema;
export const getStructuralEngineeringFilesRequestSchema = z
    .object({
    structural_id: z.string().uuid(),
    projectId: z.number().int(),
    page: z.union([z.number().int(), z.literal('all')]),
    pageSize: z.number().int().optional(),
    search: z.string().optional(),
})
    .strict()
    .transform(mapToCamelCase);
export const GetStructuralEngineeringFilesResponseSchema = getPaginationResponseSchema(fileSchema);
