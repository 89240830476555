import { FileUpload, TextInput } from '@consigli/facade';
import { useGetPackagesByServiceIdQuery, useProjectId } from '@consigli/hooks';
import { ServiceId } from '@consigli/types';
import { useCallback } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ServiceIdNameIndex } from '@/routes';

import { useCompareContext } from '../compare/use-compare-packages';

interface SecondaryPackageUploadProps {
  serviceId: ServiceId;
}

export const SecondaryPackageUpload = ({ serviceId }: SecondaryPackageUploadProps) => {
  const { t } = useTranslation();
  const {
    register,
    resetField,
    formState: { errors },
  } = useFormContext<FieldValues>();
  const projectId = useProjectId();
  const { data: packages = [] } = useGetPackagesByServiceIdQuery({
    projectId,
    serviceId,
  });
  const { secondaryFiles, setSecondaryFiles } = useCompareContext();

  const compareServiceName = ServiceIdNameIndex[serviceId];

  const validateName = useCallback(
    (value: string) => {
      if (packages.find((pkg) => pkg.name === value))
        return t('package-creation.package-name-taken');
      return Boolean(value && !packages.find((pkg) => pkg.name === value));
    },
    [t, packages],
  );

  return (
    <div className="text-base font-semibold text-day-neutral-subtle ">
      <div className="mb-0 mt-4">
        {t('package-creation.compare-service', {
          compareServiceName: t(`services.${compareServiceName}`),
        })}
      </div>
      <TextInput
        label=""
        className="border rounded font-normal text-sm my-0"
        {...register('secondaryPackageName', {
          required: `${t('package-creation.invalid-package-name-empty')}*`,
          validate: validateName,
        })}
        onReset={() => {
          resetField('secondaryPackageName');
        }}
        placeholder={t('package-creation.enter-name', {
          compareServiceName: t(`services.${compareServiceName}`),
        })}
      />
      {errors.secondaryPackageName && (
        <div className="text-red-700 text-sm">{errors.secondaryPackageName?.message as string}</div>
      )}
      <FileUpload
        files={secondaryFiles}
        setFiles={setSecondaryFiles}
        dropAreaLabel={t('document-upload.drag-n-drop-here')}
        dropAreaClassName="mt-4 h-48 w-full"
        fileButtonLabel={t('document-upload.browse-files')}
        folderButtonLabel={t('document-upload.browse-folders')}
        heading={t('package-creation.upload-files')}
        uploadListLabel={t('document-upload.files')}
      />
    </div>
  );
};
