import { DocumentPackage, Language, ServiceName } from '@consigli/types';
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';

type CompareServiceNameType = {
  label: string;
  value: ServiceName;
};
type ComparePackageType = {
  id: string;
  name: string;
  project: number;
  projectPhase: number;
  externalFamacBuildingId: string | null;
  createdBy: string | null;
  language?: Language | undefined;
  published: boolean;
}[];

export enum PACKAGE_TYPE {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

type CompareContext = {
  primaryData: FieldValues;
  setPrimaryData: Dispatch<SetStateAction<FieldValues>>;
  secondaryData: FieldValues;
  setSecondaryData: Dispatch<SetStateAction<FieldValues>>;
  primaryFiles: File[];
  setPrimaryFiles: Dispatch<SetStateAction<File[]>>;
  secondaryFiles: File[];
  setSecondaryFiles: Dispatch<SetStateAction<File[]>>;
  selectedComparisonPackage: DocumentPackage | undefined;
  setSelectedComparisonPackage: (pkg: DocumentPackage | undefined) => void;
  packageType: PACKAGE_TYPE;
  setPackageType: Dispatch<SetStateAction<PACKAGE_TYPE>>;
  compareServiceName: CompareServiceNameType | undefined;
  setCompareServiceName: Dispatch<SetStateAction<CompareServiceNameType | undefined>>;
  comparePackages: ComparePackageType | undefined;
  setComparePackages: Dispatch<SetStateAction<ComparePackageType | undefined>>;
};

export const CompareContext = createContext<CompareContext | null>(null);

export const useCompareContext = () => {
  const ctx = useContext(CompareContext);

  if (!ctx) {
    throw new Error('Missing Compare Provider in tree');
  }

  return ctx;
};

export const CompareProvider: FC<PropsWithChildren> = ({ children }) => {
  const [primaryData, setPrimaryData] = useState<FieldValues>({
    iterationName: '',
    language: '',
  });
  const [secondaryData, setSecondaryData] = useState<FieldValues>({
    secondaryPackageName: '',
  });
  const [primaryFiles, setPrimaryFiles] = useState<File[]>([]);
  const [secondaryFiles, setSecondaryFiles] = useState<File[]>([]);
  const [selectedComparisonPackage, setSelectedComparisonPackage] = useState<
    DocumentPackage | undefined
  >();
  const [packageType, setPackageType] = useState<PACKAGE_TYPE>(PACKAGE_TYPE.EXISTING);
  const [compareServiceName, setCompareServiceName] = useState<{
    label: string;
    value: ServiceName;
  }>();

  const [comparePackages, setComparePackages] = useState<ComparePackageType>();

  const value = {
    primaryData,
    setPrimaryData,
    secondaryData,
    setSecondaryData,
    primaryFiles,
    setPrimaryFiles,
    secondaryFiles,
    setSecondaryFiles,
    selectedComparisonPackage,
    setSelectedComparisonPackage,
    packageType,
    setPackageType,
    compareServiceName,
    setCompareServiceName,
    comparePackages,
    setComparePackages,
  };

  return <CompareContext.Provider value={value}>{children}</CompareContext.Provider>;
};
