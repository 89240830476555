import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { LoadingArea } from '@consigli/facade';
import { useEffect } from 'react';
import { getAuthorizationCode, getSession, setSessionInStorage } from './oauth';
import { useAuthContext } from './react-context';
/** Auth will be the callback from OAuth. So where we'll retrieve the token and store it */
export const AuthGuard = ({ children, authConfig }) => {
    const { session, setSession } = useAuthContext();
    useEffect(() => {
        const fetchSession = async () => {
            const sess = await getSession(authConfig);
            if (!sess) {
                /** Will be redirected to the login page in the IDP */
                await getAuthorizationCode(authConfig);
                return;
            }
            const payload = sess.idToken.payload;
            /** Assert that we have all the required fields */
            if (payload && !(payload.sub && payload.name)) {
                throw new Error(`Missing required fields in session: ${JSON.stringify(sess)}`);
            }
            setSession(sess);
            setSessionInStorage(sess);
        };
        if (session == null)
            fetchSession();
    }, [authConfig, session, setSession]);
    return session ? (_jsx(_Fragment, { children: children })) : (_jsx(LoadingArea, { title: "Checking authorization", className: "cs-my-[40vh]" }));
};
