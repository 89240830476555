export function isImageDocumentType(filename: string): boolean {
  return ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].some((ext) =>
    filename.toLowerCase().endsWith(ext),
  );
}

export function isIFCDocumentType(filename: string): boolean {
  return filename.toLowerCase().endsWith('.ifc');
}

export function isPDFDocumentType(filename: string): boolean {
  return ['pdf', 'docx', 'doc', 'xls', 'xlsx', 'xlsm', '.ppt', '.pptx'].some((ext) =>
    filename.toLowerCase().endsWith(ext),
  );
}

export function isMsWordType(filename: string): boolean {
  return ['doc', 'docx'].some((ext) => filename.toLowerCase().endsWith(ext));
}

export function isValidDocumentType(filename: string): boolean {
  return (
    isImageDocumentType(filename) || isIFCDocumentType(filename) || isPDFDocumentType(filename)
  );
}

export const mapExtensionToUppercase = (extension: string) => {
  if (extension) {
    return extension.replace('.', '').toUpperCase();
  } else return 'UNKNOWN';
};
